var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"ci-text",class:[
		("ci-text--" + _vm.theme),
		("ci-text--" + _vm.type),
		{
			'is-dark': _vm.dark,
			'is-bold': _vm.bold
		}
	]},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }