<template>
  <ci-info class="ci-success"
    :title="contact.success_title"
    :content="contact.success_text"
    action="Voltar para o site"
    @callback="$router.push({ name: 'home' })"
  />
</template>

<script>
  import CIInfo from '@/components/templates/CIInfo'
  import { mapGetters } from 'vuex'

  export default {
    components: {
      'ci-info': CIInfo,
    },

    computed: {
      ...mapGetters({
        contact: 'page/getContact'
      })
    }
  }
</script>
