<template>
	<div class="ci-landing">
		<ci-hero
			ref="home"
			id="about"
			class="ci-landing__hero"
			:initialized="true"
			:title="hero.title"
			:content="hero.content"
		/>

		<ci-about
			ref="about"
			id="about2"
			class="ci-landing__about"
			:initialized="initialized.includes('about')"
			caption="Sobre o Citrus"
			:title="about.title"
			:content="about.content"
			:video="about.video"
			:thumbnail="about.thumbnail"
		/>

		<ci-solutions
			ref="solutions"
			id="solutions"
			class="ci-landing__solutions"
			:initialized="initialized.includes('solutions')"
			:title="solutions.title"
			:content="solutions.content"
			:solutions="solutions.items"
		/>

		<ci-suite
			ref="suite"
			id="suite"
			class="ci-landing__suite"
			:initialized="initialized.includes('suite')"
			:content="suite.content"
		/>

		<ci-apps
			ref="apps"
			id="apps"
			class="ci-landing__apps"
			:initialized="initialized.includes('apps')"
			:title="apps.title"
			:content="apps.content"
			:apps="apps.items"
		/>

		<ci-squad
			ref="squad"
			id="squad"
			class="ci-landing__squad"
			:initialized="initialized.includes('squad')"
			:content="squad.content"
			:features="squad.features"
		/>

		<ci-try-out
			ref="contact"
			id="contact"
			class="ci-landing__try-out"
			:initialized="initialized.includes('contact')"
			:title="tryOut.title"
			:content="tryOut.content"
			@contact="$emit('contact', $event)"
		/>

		<!--
		<ci-alert-cookie :expose="alertLGPD" @update="toggleAlertLGPD">
			<div>
                {{ $t('alertCookie.mainMessage') }}
                <a href="https://citrus.cx/wp-content/uploads/2021/06/documento_pol%C3%ADtica_de_privacidade.pdf"
					target="_blank">
					{{ $t('alertCookie.privacyPolicy')}}
				</a> 
				{{ $t('alertCookie.toAgree')}}
            </div>
		</ci-alert-cookie>
		-->

	</div>
</template>

<script>
import CIHero from '@/components/organisms/CIHero'
import CIAbout from '@/components/organisms/CIAbout'
import CISolutions from '@/components/organisms/CISolutions'
import CISuite from '@/components/organisms/CISuite'
import CIApps from '@/components/organisms/CIApps'
import CISquad from '@/components/organisms/CISquad'
import CITryOut from '@/components/organisms/CITryOut'
import CIAlertCookie from '@/components/molecules/CIAlertCookie'

export default {
	components: {
		'ci-hero': CIHero,
		'ci-about': CIAbout,
		'ci-solutions': CISolutions,
		'ci-suite': CISuite,
		'ci-apps': CIApps,
		'ci-squad': CISquad,
		'ci-try-out': CITryOut,
		'ci-alert-cookie': CIAlertCookie
	},

	props: {
		scroll: {
			type: Number,
			default: 0
		},
		selected: {
			type: String|Number,
			default: 'home'
		},
		sending: {
			type: Boolean,
			default: false
		},
		hero: {
			type: Object,
			default: () => ({})
		},
		about: {
			type: Object,
			default: () => ({})
		},
		solutions: {
			type: Object,
			default: () => ({})
		},
		suite: {
			type: Object,
			default: () => ({})
		},
		apps: {
			type: Object,
			default: () => ({})
		},
		squad: {
			type: Object,
			default: () => ({})
		},
		tryOut: {
			type: Object,
			default: () => ({})
		},
	},

	data: () => ({
		active: null,
		previous: null,
		initialized: [],
		alertLGPD: false
	}),

	methods: {
		toggleAlertLGPD(){
			let data = localStorage.getItem("alertCookie") || null;
			if (!data) {
				this.alertLGPD = true;
			}
			this.alertLGPD = false;
		}
	},

	watch: {
		scroll(value) {
			for (let ref in this.$refs) {
				const component = this.$refs[ref],
					offsetTop = component.$el.offsetTop - window.innerHeight

				if (value > offsetTop && !this.initialized.includes(ref))
					this.initialized.push(ref)
			}
		},

		active(value) {
			this.$emit('change', value)
		}
	},
	beforeRouteEnter (to, from, next) {
		next(vm => {
		if (from.path === '/blog') {
			alert('kwemf')
		}else{
			alert('123123')
		}  //this bracket missing
		next()
		});
	},

	mounted() {
		this.active = this.selected
		this.alertLGPD = localStorage.getItem("alertCookie") ? false : true;
	},
}
</script>

<style lang="scss">
.ci-landing{
	overflow: hidden;
	background: white;
	&__hero{
		padding: 32px 0;
		box-sizing: border-box;
	}
	&__about,
	&__suite,
	&__solutions,
	&__apps,
	&__squad,
	&__try-out{
		padding: 32px 0;
		box-sizing: border-box;
	}

	@include grid-media($g-tablet-up){
		.ci-landing{
			&__hero{
				padding: 140px 0;
			}
			&__about,
			&__suite,
			&__solutions,
			&__apps,
			&__squad,
			&__try-out{
				padding: 48px 0;
			}
		}
	}

	@include grid-media($g-desktop-up){
		.ci-landing{
			&__hero{
				padding: 180px 0;
			}
			&__about,
			&__suite,
			&__solutions,
			&__apps,
			&__squad,
			&__try-out{
				padding: 45px 0;
			}
		}
	}
}

</style>
