<template>
	<header class="ci-header">
		<div class="ci-header__container">
			<ci-brand class="ci-header__brand" /> 

			<router-link to="/blog">
				<ci-text type="subh" class="onlyB" 
					v-if="isBlog">
					| {{ $t('menu.blog') }}
				</ci-text>
			</router-link>
			
			<ci-navigation
				v-if="!isBlog"
				class="ci-header__navigation"
				:value="selected"
				:options="navigation"
				:inline="true"
				@input="$emit('update:selected', $event)"
			/>
			
			<ci-navigation-blog	
				class="ci-header__navigation"
				v-if="isBlog"		
			/>

			<div>
				<ci-lang
					class="ci-header__lang"
					:value="lang"
					@input="$emit('update:lang', $event)"
				/>
				<ci-hamburger class="ci-header__hamburger" @click="$emit('menu')" 
				/>
			</div>
		</div>
	</header>
</template>

<script>
import CIBrand from '@/components/atoms/CIBrand'
import CINavigation from '@/components/molecules/CINavigation'
import CINavigationBlog from '@/components/molecules/CINavigationBlog'
import CIHamburger from '@/components/atoms/CIHamburger'
import CILang from '@/components/molecules/CILang'
import CIText from '@/components/atoms/CIText'

export default {
	props: {
		navigation: {
			type: Array,
			default: undefined
		},
		selected: {
			type: String,
			default: 'home'
		},
		lang: {
			type: String,
			default: 'pt'
		}
	},

	components: {
		'ci-brand': CIBrand,
		'ci-navigation': CINavigation,
		'ci-navigation-blog': CINavigationBlog,
		'ci-hamburger': CIHamburger,
		'ci-lang': CILang,
		'ci-text': CIText
	},

	computed: {
		isBlog(){
			const texts = ['blog','post', 'categoria'];
			return texts.includes(this.$route.name);
		}
	}
}
</script>

<style lang="scss">
.ci-header{
	background: white;
	position: relative;
	box-shadow: 0 3px 7px rgba(black, .03);
	&__container{
		display: flex;
		/*justify-content: space-between;*/
		align-items: center;
		padding: 16px;
		@include outer-container;
	}
	&__navigation{
		display: none;
	}
	&__brand{
		width: 100px;
	}
	&__lang{
		position: absolute;
		right: 64px;
		top: 50%;
		transform: translateY(-50%);
	}
	&__hamburger{
		position: absolute;
		top: 50%;
		right: 16px;
		transform: translateY(-50%);
	}

	@include grid-media($g-desktop-up){
		.ci-header{
			&__container{
				padding: 16px 0;
			}
			&__brand{
				width: 120px;
				margin: 0 8px;
				flex-shrink: 0;
			}
			&__navigation{
				display: flex;
				justify-content: flex-end;
				margin: 0 8px;
				flex: 1;
			}
			&__hamburger{
				display: none;
			}
			&__lang{
				position: relative;
				top: 0;
				right: 0;
				margin-right: 24px;
				transform: initial;
			}
		}
	}
}
.onlyB{
	margin-top: -9px;
	margin-left: 4px;
}
</style>
