import Vue from 'vue'

export default {
  namespaced: true,

  state: {
    alerts: [],
    current: null
  },

  getters: {
    getCurrent(state) {
      return state.current
    },
  },

  mutations: {
    setCurrent(state, index) {
      Vue.set(state, 'current', state.alerts[index])
    }
  },

  actions: {
    add({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        let alert = {
          type: payload.type,
          message: payload.message,
          timeout: payload.timeout || 3000
        }

        state.alerts.push(alert)

        if (state.alerts.length == 1) {
          commit('setCurrent', 0)

          // Wait for timeout
          setTimeout(() => {
            // Reset current
            Vue.set(state, 'current', null)

            // Wait delay for new message
            setTimeout(() => {
              // Remove from list
              state.alerts.splice(0, 1)

              if (state.alerts.length > 0)
                commit('setCurrent', 0)
            }, 400)
          }, state.current.timeout)
        }

        return resolve(alert)
      })
    },
  }
}