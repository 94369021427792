<template>
    <div class="content-03">
        <img class="setinha01" src="../image/setinhas-baixo.png" alt="i" />
        <img class="setinha02" src="../image/setinhas-baixo.png" alt="i" />
        <img class="setinha03" src="../image/setinhas-cima.png" alt="i" />
        <img class="setinha04" src="../image/setinhas-cima.png" alt="i" />

        <img class="triangulo01" src="../image/triangulo.png" alt="i" />
        <img class="triangulo02" src="../image/triangulo.png" alt="i" />
        <img class="triangulo03" src="../image/triangulo.png" alt="i" />
        <img class="triangulo04" src="../image/triangulo.png" alt="i" />
        <img class="triangulo05" src="../image/triangulo.png" alt="i" />
        <img class="triangulo06" src="../image/triangulo.png" alt="i" />
        <img class="triangulo07" src="../image/triangulo.png" alt="i" />
        <img class="triangulo08" src="../image/triangulo.png" alt="i" />

        <img class="setinha" src="../image/setinhas-baixo.png" alt="i" />
        <div v-for="item in podcasts" :key="item.id">
            <Podcast :data="item" />
        </div>
        <div class="banner-m">
            <img class="img-banner" src="../image/send.png" alt="i" />
            <div class="info">Participe também do nosso canal no Telegram</div>
            <a href="#" class="btn-primary">Clique aqui</a>
        </div>
    </div>
</template>

<script>
import Podcast from './Podcast'

export default {
    components: { Podcast },
    data() {
        return {
            podcasts: [
                {
                    id: '1',
                    title: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
                    description: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
                    link: '#',
                    link2: '#',
                    link3: '#',
                },
                {
                    id: '2',
                    title: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
                    description: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
                    link: '#',
                    link2: '#',
                    link3: '#',
                },
                {
                    id: '3',
                    title: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
                    description: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
                    link: '#',
                    link2: '#',
                    link3: '#',
                },
                {
                    id: '4',
                    title: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
                    description: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
                    link: '#',
                    link2: '#',
                    link3: '#',
                },
                {
                    id: '5',
                    title: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
                    description: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
                    link: '#',
                    link2: '#',
                    link3: '#',
                },
                {
                    id: '6',
                    title: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
                    description: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
                    link: '#',
                    link2: '#',
                    link3: '#',
                },
                {
                    id: '7',
                    title: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
                    description: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
                    link: '#',
                    link2: '#',
                    link3: '#',
                },
                {
                    id: '8',
                    title: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
                    description: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
                    link: '#',
                    link2: '#',
                    link3: '#',
                },
                {
                    id: '9',
                    title: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
                    description: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
                    link: '#',
                    link2: '#',
                    link3: '#',
                },
                {
                    id: '10',
                    title: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
                    description: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
                    link: '#',
                    link2: '#',
                    link3: '#',
                }
            ]
        }
    }
}
</script>

<style lang="scss">
    .page-cast {
        .content-03 {
            position: relative;
            padding-top: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .setinha {
                width: 20px;
                margin-bottom: 25px;
            }

            .setinha01 {
                position: absolute;
                left: 10%;
                top: 20%;
                width: 20px;
                z-index: -1;
            }

            .setinha02 {
                position: absolute;
                left: 10%;
                top: 60%;
                width: 20px;
                z-index: -1;
            }

            .setinha03 {
                position: absolute;
                right: 10%;
                top: 40%;
                width: 20px;
                z-index: -1;
            }

            .setinha04 {
                position: absolute;
                right: 10%;
                top: 80%;
                width: 20px;
                z-index: -1;
            }

            .triangulo01 {
                position: absolute;
                right: 15%;
                top: 5%;
                z-index: -1;
            }

            .triangulo02 {
                position: absolute;
                left: 15%;
                top: 20%;
                z-index: -1;
            }

            .triangulo03 {
                position: absolute;
                right: 15%;
                top: 30%;
                z-index: -1;
            }

            .triangulo04 {
                position: absolute;
                left: 15%;
                top: 40%;
                z-index: -1;
            }

            .triangulo05 {
                position: absolute;
                right: 15%;
                top: 50%;
                z-index: -1;
            }

            .triangulo06 {
                position: absolute;
                left: 15%;
                top: 62%;
                z-index: -1;
            }

            .triangulo07 {
                position: absolute;
                right: 15%;
                top:75%;
                z-index: -1;
            }

            .triangulo08 {
                position: absolute;
                left: 15%;
                top: 90%;
                z-index: -1;
            }

            .banner-m {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                margin: 70px 0 0 0;

                .img-banner {
                    width: 50px;
                }

                .info {
                    font-weight: bold;
                    font-size: 30px;
                    color: #585858;
                    margin: 15px 0 20px 0;
                    text-align: center;
                }

                .btn-primary {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 25px;
                    background-color: #fed626;
                    padding: 8px 35px;
                    font-size: 14px;
                    color: #585858;
                    user-select: none;
                }


            }

        }
    }
</style>
