<template>
    <div class="container-fixed" v-if="expose">
        <div class="cookie-container">
            <slot />
            <div>
                <ci-button v-on:click="cookiesLGPD">Ok</ci-button>
            </div>
        </div>
    </div>
</template>

<script>
import CIButton from '@/components/atoms/CIButton'
export default {
    props: {
        expose: {
            type: Boolean,
            required: true,
        }
    },
    components: {
        'ci-button': CIButton
    },
    methods: {
		cookiesLGPD(){
			localStorage.setItem("alertCookie", "true");
            this.$emit("update", false);
		}
	}
}
</script>

<style lang="scss" scoped>

.container-fixed {
    width: 90%;
    position: fixed;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
	z-index: 999;

    .cookie-container {
        display: flex;
        
        justify-content: space-between;
        align-items: center;
        max-width: 1334px;
        font-size: 15px;
        line-height: 18px;
        padding: 10px 20px;
        border-radius: 5px;
        background-color: rgba(70, 70, 70, 0.925);
        color: white;
        user-select: none;

        @media (max-width:480px) {
            flex-direction: column;
        }

        a {
            color: white;
            font-size: 15px;
            font-weight: 600;
            text-decoration: underline;
        }
    }
}
</style>