<template>
	<div class="ci-about"
		:class="{
			'is-idle': !initialized && !animated
		}"
	>
		<ci-leaf class="ci-about__leaf ci-about__animate--stroke" theme="primary" :filled="false" />

		<div class="ci-about__container">
			<div class="ci-about__content ci-about__animate--slide">
				<ci-text class="ci-about__caption" type="caption">
					{{ $t('about.subhead') }}
				</ci-text>
				<ci-text class="ci-about__title" type="subhead">
					{{ $t('about.title') }}
				</ci-text>
				<ci-text class="ci-about__text" type="body" v-html="$t('about.content')">
				</ci-text>
			</div>

			<ci-player
				class="ci-about__player ci-about__animate--scale"
				theme="warn"
				:video="video"
				:thumbnail="thumbnail"
			>
			</ci-player>

			<ci-circle class="ci-about__circle ci-about__animate--scale" />
		</div>

		<img class="ci-about__shape ci-about__animate--slide" :src="`${publicPath}images/shape-about.svg`">
	</div>
</template>

<script>
import CIText from '@/components/atoms/CIText'
import CIPlayer from '@/components/molecules/CIPlayer'
import CILeaf from '@/components/atoms/CILeaf'
import CICircle from '@/components/atoms/CICircle'
import anime from 'animejs'
import { TweenMax, Power2, Elastic } from 'gsap/TweenMax'

export default {
	components: {
		'ci-text': CIText,
		'ci-player': CIPlayer,
		'ci-leaf': CILeaf,
		'ci-circle': CICircle,
	},

	props: {
		caption: {
			type: String,
			default: 'Pellentesque ac aliquet'
		},
		title: {
			type: String,
			default: 'Um ecossistema.'
		},
		content: {
			type: String,
			default: `
				<p>Donec malesuada metus libero, ut pretium ligula malesuada mattis. Cras nec purus nulla. Integer accumsan nisi eu orci facilisis, at aliquam augue finibus. Integer in tempor augue, sed sodales turpis. Vivamus ac mi ultrices, lobortis eros sed, commodo quam.</p>
				<p>Proin et feugiat nunc. Nam vitae tellus tristique, tincidunt diam dictum, consequat lorem. Pellentesque ac aliquet purus, non laoreet tortor. Praesent mattis sodales leo, id lobortis erat pharetra in.</p>
			`
		},
		video: {
			type: String,
			default: undefined
		},
		thumbnail: {
			type: String,
			default: undefined
		},
		initialized: {
			type: Boolean,
			default: true
		}
	},

	data: () => ({
		animated: false
	}),

	mounted() {
		if (this.initialized)
			this.init()
	},

	watch: {
		initialized(value) {
			if (value && !this.animated)
				this.init()
		}
	},

	methods: {
		init() {
			this.animated = true

			TweenMax.staggerFrom(this.$el.querySelectorAll('.ci-about__animate--scale'), 1.4, {
				scale: .9,
				delay: .5,
				ease: Elastic.easeOut
			}, .5)

			TweenMax.staggerFrom(this.$el.querySelectorAll('.ci-about__animate--scale'), 1, {
				opacity: 0,
				delay: .5,
				ease: Power2.easeOut,
			}, .5)

			anime({
				targets: this.$el.querySelectorAll('.ci-about__animate--stroke path'),
				strokeDashoffset: [anime.setDashoffset, 0],
				easing: 'easeInOutQuad',
				delay: 750,
				duration: 4000
			})

			TweenMax.staggerFrom(this.$el.querySelectorAll('.ci-about__animate--slide'), 1.5, {
				x: "+=64",
				opacity: 0,
				ease: Power2.easeOut
			}, .5)
		}
	}
}
</script>

<style lang="scss">
.ci-about{
	position: relative;
	z-index: 20;
	transition: opacity .6s;
	@include grid-container;
	&__container{
		position: relative;
		@include outer-container;
	}
	&__caption{
		margin-bottom: 16px;
		color: $c-lightgray;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		&:before,
		&:after{
			content: "";
			display: block;
			width: 16px;
			height: 1px;
			background: $c-lightgray;
			transform: translateY(-2px);
			opacity: .5;
		}
		&:before{
			margin-right: 8px;
		}
		&:after{
			margin-left: 8px;
		}
	}
	&__content{
		@include grid-column(6);
	}
	&__title{
	}
	&__text{
	}
	&__player{
		@include grid-column(6);
	}
	&__leaf{
		display: none;
	}
	&__shape{
		display: none;
	}
	&__circle{
		display: none;
	}

	@include grid-media($g-tablet-up) {
		display: flex;
		align-items: center;
		.ci-about{
			&__leaf{
				position: absolute;
				top: 70%;
				left: 70%;
				z-index: -1;
				transform: translateY(-50%) rotate(180deg);
				display: block;
			}
			&__shape{
				position: absolute;
				top: -83%;
				left: 89%;
				z-index: -1;
				display: block;
			}
			&__container{
				display: flex;
				align-items: center;
			}
			&__shape{
				top: -77%;
			}
			&__caption{
			}
			&__title{
			}
			&__content{
				@include grid-column(6);
			}
			&__player{
				@include grid-column(6);
			}
		}
	}

	@include grid-media($g-desktop-up) {
		.ci-about{
			&__leaf{
				top: 50%;
			}
			&__shape{
				top: -77%;
			}
			&__circle{
				position: absolute;
				top: 50%;
				left: -1020px;
				z-index: -2;
				display: block;
			}
			&__content{
				@include grid-column(5);
			}
			&__player{
				@include grid-column(6);
				@include grid-push(1);
			}
		}
	}
	// Idle
	&.is-idle{
		opacity: 0;
		.ci-about{
			&__container{
			}
		}
	}
}
</style>
