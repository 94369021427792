import Vue from 'vue'

export default {
	namespaced: true,

	state: {},
	getters: {},

	actions: {
		send({ state }, form) {
			return new Promise((resolve, reject) => {
				Vue.http.post('contact', form)
					.then(response => {
						if (response.status == 200) {
							// RDStation integration
							Vue.http.get('https://app.rdstation.com.br/api/1.2/conversions.json', {
								params: {
									...form,
									token_rdstation: '80ee3185653b49a1de9f730328676ebb',
									identificador: 'site-citrus',
									redirect_to: 'https://citrus.cx/contato-sucesso'
								}
							})

							return resolve('Obrigado pelo contato!')
						}

						return reject('Um erro inesperado ocorreu.')
					})
					.catch(response => {
						reject(response.body.message)
					})
			})
		},

		sendSeed({ state }, form) {
			return new Promise((resolve, reject) => {
				Vue.http.post('contact/seed', form)
					.then(response => {
						if (response.status == 200) {
							return resolve('Obrigado pelo contato!')
						}

						return reject('Um erro inesperado ocorreu.')
					})
					.catch(response => {
						reject(response.body.message)
					})
			})
		},

		sendLemonade({ state }, form) {
			return new Promise((resolve, reject) => {
				Vue.http.post('contact/lemonade', form)
					.then(response => {
						if (response.status == 200) {
							return resolve('Obrigado pelo contato!')
						}

						return reject('Um erro inesperado ocorreu.')
					})
					.catch(response => {
						reject(response.body.message)
					})
			})
		}
	}
}
