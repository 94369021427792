var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"post"},[_c('div',{staticClass:"post__container"},[_c('div',{staticClass:"post__single_page"},[_c('div',{staticClass:"grid-blog"},[_c('div',{staticClass:"post__reading"},[_c('div',{staticClass:"post__breadcrumb"},[(_vm.post.cats[0].cat_name !== 'Sem categoria')?_c('router-link',{attrs:{"to":{
                name: 'categoria',
                params: {
                  catID: _vm.post.cats[0].cat_ID,
                  catSlug: _vm.post.cats[0].slug,
                },
              }}},[_c('ci-text',{attrs:{"type":"smallb"}},[_vm._v(_vm._s(_vm.post.cats[0].cat_name))])],1):_vm._e(),_c('i',{staticClass:"fas fa-chevron-right"}),_c('router-link',{attrs:{"to":{ name: 'post', params: { postID: _vm.post.id } }}},[_c('ci-text',{attrs:{"type":"smallb"},domProps:{"innerHTML":_vm._s(_vm.post.title.rendered)}})],1)],1),_c('div',{staticClass:"post__content"},[_c('figure',{staticClass:"primeira"},[_c('img',{staticClass:"primeira",attrs:{"src":_vm.post.firstimg[0],"alt":""}})]),_c('ci-text',{attrs:{"type":"subhead"},domProps:{"innerHTML":_vm._s(_vm.post.title.rendered)}}),_c('div',{staticClass:"content_bellow"},[_c('ci-text',{staticClass:"moment"},[_c('i',{staticClass:"far fa-clock"}),_vm._v("\n                "+_vm._s(_vm._f("mom")(_vm.post.date))+"\n              ")]),_c('ci-text',{staticClass:"moment"},[_vm._v("Por:\n                "+_vm._s(_vm.author
                    .replace(/1/g, " ")
                    .toLowerCase()
                    .replace(/(?:^|\s)(?!da|de|do)\S/g, function (l) { return l.toUpperCase(); }
                    )))]),_vm._l((_vm.post.cats),function(category){return (category.name != 'Sem categoria')?_c('small',{key:category.id,staticClass:"catSingle",class:_vm.post.cats[0].slug},[_c('router-link',{attrs:{"to":{
                    name: 'categoria',
                    params: {
                      catID: category.cat_ID,
                      catSlug: category.slug,
                    },
                  }}},[_vm._v("\n                  "+_vm._s(category.name)+"\n                ")])],1):_vm._e()})],2),_c('ci-text',{staticClass:"post__body",attrs:{"type":"content"},domProps:{"innerHTML":_vm._s(_vm.post.content.rendered)}}),_c('br'),_c('fb-comment',{staticStyle:{"min-height":"50px"},attrs:{"url":'/blog/' + _vm.post.id + '/' + _vm.post.slug,"width":"100%"}})],1)]),_c('div',{staticClass:"pophigh"},[_c('ci-highlight'),_c('ci-advertising',{attrs:{"anuncios":_vm.anuncios}})],1)])])]),_c('ci-blog-footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }