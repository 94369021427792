<template>
	<div class="ci-experience">
		<div class="ci-experience__background">
			<img class="ci-experience__reard" :src="publicPath + 'images/shape-experience.png'" />
		</div>

		<div class="ci-experience__container">
			<!-- <div class="ci-experience__custom_mobile ci-experience__animate">
				<div class="rounded">
					<img src="images/aryvilhenajr.png">
				</div>
			</div>		 -->
			<div class="ci-experience__content ci-experience__animate">
				<router-link to="/sobre"><ci-text class="ci-experience__title" type="body" v-html="title"></ci-text></router-link>
				<ci-text class="ci-experience__text" type="body" v-html="content"></ci-text>
			</div>
			<div class="ci-experience__custom ci-experience__animate">

				<div class="rounded">
					<img class="ci-experience__scrn ci-experience__animate" :src="$t('hero.url')" :alt="title">
					
					<!-- <div class="socials">
						<ci-button-icon
							v-for="item in social"
							class="ci-footer__social"
							:name="item.icon"
							theme="accent"
							size="sm"
							@click="onSocialClick(item)"
						/>
					</div> -->
					<div class="ci-experience__quota">
						<a href="https://www.linkedin.com/in/aryvilhena/" target="_blank" title="LinkedIn">
							<div class="ci-experience__avatar">
								<img :src="thumbnail">
							</div>
						</a>
						<img class="quote" :src="publicPath + 'images/quote.png'">
						<ci-text class="ci-experience__quote ci-experience__sic" type="body" v-html="quote"></ci-text>
						<ci-text type="body" class="ci-experience__ref">ARY VILHENA JR. - CEO</ci-text>
					</div>
					<div class="clear"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import CIText from '@/components/atoms/CIText'
import CIButtonIcon from '@/components/atoms/CIButtonIcon'
import { TweenMax, Power2, Elastic } from 'gsap/TweenMax'

export default {
	components: {
		'ci-text': CIText,
		'ci-button-icon': CIButtonIcon
	},
	props: {		
		title: {
			type: String,
			default: 'Conheça o Citrus'
		},
		thumbnail: {
			type: String,
			default: ''
		},
		content: {
			type: String,
			default: ''
		},
		quote: {
			type: String,
			default: ''
		},
		initialized: {
			type: Boolean,
			default: true
		},
		social: {
			type: Array,
			default: () => ([
				{
					icon: 'fab fa-linkedin',
					link: 'https://www.linkedin.com/in/aryvilhena/'
				}
				// {
				// 	icon: 'fab fa-facebook-f',
				// 	link: 'http://facebook.com'
				// },
				// {
				// 	icon: 'fab fa-twitter',
				// 	link: 'http://twitter.com'
				// },
			])
		}
	},

	data: () => ({
		animated: true
	}),

	mounted() {
		if (this.initialized)
			this.init()
	},

	watch: {
		initialized(value) {
			if (value && !this.animated)
				this.init()
		}
	},

	methods: {
		init() {
			this.animated = true

			TweenMax.staggerFrom(this.$el.querySelectorAll('.ci-experience__animate'), 1.5, {
				x: "-=80",
				opacity: 0,
				ease: Power2.easeOut
			}, .4)
		},
		onSocialClick(item) {
			window.open(item.link, '_blank')
		}
	}
}
</script>

<style lang="scss">
.clear{
	clear: both!important;
}
.ci-experience{
	position: relative;
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	position: relative;
	transition: opacity .6s;
	z-index: 30;
    padding-bottom: 107px;
	@include grid-container;

	a{
		text-decoration: none;
	}
	&__container{
		width: 100%;
		@include outer-container;
	}
	&__background{
		/*position: absolute;
		left: 0;
		right: 0;
		top: 0;
		overflow: hidden;
		z-index: -2;		
		height: 100vh;*/
		position: absolute;
	    top: 0;
	    left: 0;
	    right: 0;
	    bottom: 0;
	    /*overflow: hidden;*/
	    z-index: -2;
	}
	&__scrn {
		width: 90%;
		pointer-events: none;
		/* margin-bottom: 51px; */
		clear: both;
		margin: 0 auto 25px;
		display: block;
   }
	&__reard{
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
	}
	&__content{
		@include grid-column(6);
	}
	&__title{
	    color: #58595b;
	   	font-size: 25px;
	    line-height: 125%;
	    max-width: 440px;
	    font-weight: 600;
	    margin: 15px auto 25px;
	    text-align: center;
	}
	&__text{
	    color: #64666b;
	    line-height: 165%;
	    font-size: 16px;
	    max-width: none;
	    margin: 0 auto 30px;
	    font-family: 'montserrat';
	    text-align: justify;
	}

	.ci-button-icon--sm {
	    width: 40px;
	    height: 40px;
	    margin-bottom: 15px!important;
	    display: inline-flex;
	    margin: 0;
	}
	&__custom{
		@include grid-column(6);

		.rounded{
			border-radius: 10px;
			background: #fffef3;
			position: relative;
			max-width: 515px;
			margin: 0 auto;
			padding: 25px 15px 15px 15px;
			-webkit-box-shadow: 0px 0px 10px rgba(239, 239, 239, 0.6901960784313725);
			box-shadow: 0px 0px 10px rgba(239, 239, 239, 0.6901960784313725);
		}
		.socials{
			position: absolute;
			right: 0;
			top: 20px;
			height: auto;
			margin-left: 0;
			padding-bottom: 0;
			width: 100%;
			text-align: center;
		}
	}
	&__avatar{
	    border-radius: 100%;
	    margin: 0 auto 10px;
	    -webkit-box-flex: none;
	    -ms-flex-positive: none;
	    flex-grow: none;
	    max-width: 80px;
	    height: 80px;
	    overflow: hidden;
		float: left;
		flex-grow: none;
		overflow: hidden;
		margin-right: 10px;

		img{
			width: 105%;
			object-position: 1% center;
			object-fit: cover;
		}
	}
	&__quota{
	    float: left;
	    padding: 15px;

		img.quote{
			float: left;
			width: 20px;
			margin-top: 5px;
		}
		.ci-experience__sic{
			float: left;
			margin: 16px 0;
			color: #58595b;
			font-size: 16px;
			line-height: 125%;
			font-weight: 600;
			margin: 5px 0 0 10px;
			width: calc(100% - 120px);				
		}
		.ci-experience__ref{
			color: #a5a6ac;
			font-size: 13px;
			font-weight: 600;
			float: left;
			margin-left: 0;
			letter-spacing: 1.5px;
			margin-top: 4px;
			text-align: center;
			width: 100%;
		}

	}
	@include grid-media($g-tablet-up){
		.ci-experience{
			&__container{
				margin-top: 95px;
			}
			&__title{
				margin: 0 auto 25px;
				font-size: 32px;
			}
			&__text{
				font-size: 18px;
				text-align: center;
			}
			&__screen{
				position: absolute;
				right: 48.9%;
				top: 20%;
				max-width: 800px;
				width: 55%;
				margin-bottom: 0;
			}
			&__shape{
				width: auto;
				right: 52%;
				top: -400px;
			}
			&__content{
				@include grid-column(6);
			}
			&__custom{
				@include grid-column(6);

				.rounded{
					max-width: 515px;
					margin: 0 auto;
					padding: 20px;
					box-shadow: 0px 0px 10px rgba(239, 239, 239, 0.6901960784313725);
				}
				.socials{
					right: 10%;
					/*top: 83px;*/
					    top: 30%;
					height: auto;
					width: 45px;
				}
			}
			&__avatar{
				float: left;
				flex-grow: none;
				max-width: 100px;
				height: 100px;
				overflow: hidden;

				img{
					width: 105%;
					object-position: 1% center;
					object-fit: cover;
				}
			}
			&__quota{
				max-width: 100%;
				margin: 0 auto;
				float: left;

				img.quote{
					float: left;
					width: 20px;
					margin-top: 5px;
				}
				.ci-experience__sic{
					float: left;
					margin: 16px 0;
					color: #58595b;
					font-size: 20px;
					line-height: 125%;
					font-weight: 600;
					margin: 5px 0 0 10px;
					width: calc(100% - 140px);				
				}
				.ci-experience__ref{
					color: #a5a6ac;
					font-size: 13px;
					font-weight: 600;
					float: left;
					margin-left: 33px;
					letter-spacing: 1.5px;
					margin-top: 4px;
					width: auto;
				}
			}
		}
	}
	@include grid-media($g-tablet-up) {
		.ci-experience{
			&__title{
				font-size: 29px;
				text-align: center;
			}
			&__reard{
				animation-name: wave;
				animation-iteration-count: infinite;
			}
			&__reard{
				animation-duration: 6.5s;
			}
			&__scrn {
				width: 90%;
				pointer-events: none;
				/* margin-bottom: 51px; */
				clear: both;
				margin: 0 auto 25px;
				display: block;
		   }
		}

		@keyframes wave {
			0% {
				transform: translate(-50%, 0px);
			}
			50% {
				transform: translate(-50%, 11px);
			}
			100% {
				transform: translate(-50%, 0px);
			}
		}

		
	}

	@include grid-media($g-desktop-up) {
		.ci-experience{
			&__shape{
				width: auto;
				right: 51%;
				top: -340px;
			}
			&__background{

				img{
					min-width: 100vw;
				}
			}
			&__reard{
				position: absolute;
				top: 0;
				left: 50%;
				transform: translateX(-50%);
			}
			&__custom{
				@include grid-column(6);

				.rounded{
					
					box-shadow: 0px 0px 10px rgba(239, 239, 239, 0.6901960784313725);
				}
				.socials{
					right: 14%;
					/*top: 83px;*/
				    top: 30%;
					height: auto;
					width: 45px;
					margin-left: 0;
					padding-bottom: 0;
				}
			}
		}
	}

	// Idle
	&.is-idle{
		opacity: 0;
	}
}
</style>
