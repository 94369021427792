<template>
  <div class="ci-alert ci-text ci-text--body is-dark is-bold"
    :class="[
      `ci-alert--${type}`
    ]"
  >
    <slot></slot>
  </div>
</template>

<script>
  export default {
    props: {
      type: {
        type: String,
        default: 'success' // success | error
      }
    }
  }
</script>

<style lang="scss">
.ci-alert{
  border-radius: 8px;
  padding: 8px 16px;
  &--success{
    background: $t-primary;
  }
  &--error{
    background: $t-warn;
  }
}
</style>