<template>
	<div class="ci-bookinfo"
		:class="[
			{
				'is-idle': !initialized && !animated
			}
		]">
		
		<div class="ci-bookinfo__container">

			<img class="ci-bookinfo__screen ci-bookinfo__animate" :src="thumbnail" :alt="title">
						
			<div class="ci-bookinfo__content ci-bookinfo__animate">				

				<ci-text class="ci-bookinfo__title" type="title" v-html="title">
				</ci-text>

				<ci-text class="ci-bookinfo__text" type="text" v-html="content"></ci-text>

				<a href="https://materiais.citrus.cx/ebook-a-experiencia-do-cliente" target="_blank">
				<button class="ci-button ci-text ci-text--body is-dark ci-bookinfo__button ci-button--accent ci-button--md" type="submit">
					{{$t('book.text')}} 
				</button>
				</a>
				<a :href="publicPath + 'docs/citrus-ebook.pdf'"></a>
			</div>
			
		</div>
	</div>
</template>

<script>
import CIText from '@/components/atoms/CIText'
import { TweenMax, Power2, Elastic } from 'gsap/TweenMax'

export default {
	components: {
		'ci-text': CIText
	},
	props: {
		screen: {
			type: String,
			default: 'images/citrus-screen.png'
		},
		title: {
			type: String,
			default: 'O Conceito de CX que vai revolucionar seus resultados'
		},
		content: {
			type: String,
			default: 'A Jornada Visual 3D™ é o principal recurso do Citrus para uma apresentação visual da jornada do cliente. Ela é construída a partir de três dimensões de informações inseridas em uma única camada: '
		},
		thumbnail: {
			type: String,
			default: undefined
		},
		initialized: {
			type: Boolean,
			default: true
		}
	},

	data: () => ({
		animated: false
	}),

	mounted() {
		if (this.initialized)
			this.init()
	},

	watch: {
		initialized(value) {
			if (value && !this.animated)
				this.init()
		}
	},

	methods: {
		init() {
			this.animated = true

			TweenMax.staggerFrom(this.$el.querySelectorAll('.ci-bookinfo__animate'), 1.5, {
				x: "-=80",
				opacity: 0,
				ease: Power2.easeOut
			}, .4)
			

		},
	}
}
</script>

<style lang="scss">
.ci-bookinfo{
	background: transparent!important;
	position: relative;
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	position: relative;
	transition: opacity .6s;
	z-index: 30;

	a{
		text-decoration: none;
	}
	@include grid-container;
	&__container{
		width: 100%;
		@include outer-container;
	}
	&__background{
		position: absolute;
		top: -600px;
		left: 0;
		right: 0;
		bottom: 0;
		overflow: hidden;
		z-index: -2;
	}
	&__screen{
		width: 100%;
		margin-left: -16%;
	}
	&__rear{
		position: absolute;
		top: 0;
		left: 48%;
		transform: translateX(-50%);
	}
	&__front{
	    position: absolute;
	    top: 0;
	    left: 100%;
	}
	&__content{
		@include grid-column(6);
	}
	&__title{
		margin: 16px 0;
		font-size: 28px;
		line-height: 115%;
		font-weight: 700;
		font-family: 'montserrat';
	}
	&__text{
		color: $c-darkgray;
	    line-height: 165%;
        font-size: 18px;
	    /*max-width: 425px;*/
	    font-family: 'montserrat';

	    small{
		    font-size: 16px;
		    line-height: 165%;
		    margin: 10px 0;
		    float: left;

			span{
				padding: 4px 0;
				float: left;
				width: 100%;
			}
	    }
	}
	&__button{
	    padding: 0 25px;
	    width: auto;
	    margin: 32px 0;
	    font-size: 13.5px;
	    height: 40px;
	    line-height: 41px;
	    letter-spacing: 1px;
	    float: left;
	    width: 100%;
	    max-width: 303px;
	    font-weight: 600;
	}
	
	@include grid-media($g-tablet-up){
		.ci-bookinfo{
			&__container{
				margin-top: 95px;
			}
			&__title{
				margin: 0 0 24px;
				font-size: 35px;
	    		max-width: 473px;
			}
			&__screen{
			    position: absolute;
			    right: 56%;
			    top: 16%;
			    max-width: 800px;
			    margin-bottom: 0;
			    width: auto;
			}
			&__text{
		        font-size: 20px;
			    /*max-width: 425px;*/
			    font-family: 'montserrat';

			    small{
				    font-size: 16px;
				    line-height: 165%;
				    margin: 10px 0;
				    float: left;

					span{
						padding: 9px 0;
						float: left;
						width: 100%;
					}
			    }
			}
			
			&__content{
				@include grid-column(6);
				@include grid-push(6);
			}
		}
	}
	@include grid-media($g-tablet-up) {
		.ci-bookinfo{

			&__rear,
			&__front{
				animation-name: wave;
				animation-iteration-count: infinite;
			}
			&__rear{
				animation-duration: 8s;
			}
			&__front{
				animation-duration: 10s;
			}
			&__brand{
				animation-name: brand;
				animation-iteration-count: infinite;
				animation-duration: 8s;
			}
		}

		@keyframes wave {
			0% {
				transform: translate(-50%, 0px);
			}
			50% {
				transform: translate(calc(-81% - 100px), 40px);
			}
			100% {
				transform: translate(-50%, 0px);
			}
		}

		@keyframes brand {
			0% {
				transform: translateY(0px);
			}
			50% {
				transform: translateY(-10px);
			}
			100% {
				transform: translateY(0px);
			}
		}
	}

	@include grid-media($g-desktop-up) {
		.ci-bookinfo{
			&__shape{
				width: auto;
				right: 51%;
				top: -340px;
			}
		}
	}

	// Idle
	&.is-idle{
		opacity: 0;
	}
}
</style>
