<template>
	<div class="ci-squad"
		:class="{
			'is-idle': !initialized && !animated
		}"
	>
		<div class="ci-squad__background">
			<img class="ci-squad__rear" :src="`${publicPath}images/shape-squad-rear.svg`" />
			<img class="ci-squad__front" :src="`${publicPath}images/shape-squad-front.svg`" />
		</div>

		<div class="ci-squad__container">
			<header class="ci-squad__header ci-squad__animate--horizontal">
				<ci-brand class="ci-squad__brand" type="squad" />
				<ci-text class="ci-squad__text" :dark="true" :v-html="content">{{$t('squad.content')}}
				</ci-text>
			</header>

			<div class="ci-squad__body ci-squad__animate--horizontal">
				<div
					class="ci-squad__features"
					v-for="(items, category, index) in featuresData"
					:key="category"
					:class="index < 1 ? 'first' : '' "
				>
					<ci-text class="ci-squad__category ci-squad__animate--horizontal" :dark="true" type="subhead">
						{{ category }}
					</ci-text>

					<div class="ci-squad__items">
						<ci-feature
							class="ci-squad__feature ci-squad__animate--scale"
							v-for="(feature, index) in items"
							:key="`feature-${index}`"
							:image="feature.image"
							:label="feature.label"
						/>
					</div>
				</div>
				
			</div>
			<div class="ci-squad__sample">
				<img class="ci-squad__sample__screen chat" :src="publicPath + 'images/squadchat.png'">
				<!--<img class="ci-squad__sample__screen apps" :src="publicPath + 'images/squadapps.png'" :alt="title">-->
			</div>	
			<div class="box-download-area ci-squad__animate--horizontal"> 
				<div class="download-area__title">{{$t('squad.donwloadArea.title')}}</div>
				<div class="download-area__subtitle">{{$t('squad.donwloadArea.subtitle')}}</div>
				<div class="download-area__box">
					<a class="download-area__text-btn" href="https://play.google.com/store/search?q=Citrus%20Squad&c=apps"><span class="fas fa-download"></span> {{$t('squad.donwloadArea.textBtn')}}</a>
				</div>
			</div>
		</div>

		<div class="ci-squad__arrow">
			<span class="fas fa-angle-down"></span>
			<img :src="`${publicPath}images/shape-squad-arrow.png`" />
		</div>
	</div>
</template>

<script>
import CIText from '@/components/atoms/CIText'
import CIBrand from '@/components/atoms/CIBrand'
import CIFeature from '@/components/molecules/CIFeature'
import { TweenMax, Power2, Elastic } from 'gsap/TweenMax'

export default {
	components: {
		'ci-text': CIText,
		'ci-brand': CIBrand,
		'ci-feature': CIFeature
	},

	props: {
		content: {
			type: String,
			default: 'Donec viverra volutpat congue. Sed lacinia, magna id pellentesque rutrum, neque nulla facilisis odio, nec tincidunt ipsum velit a nisl. Etiam eu tristique nunc. Integer placerat consequat accumsan.'
		},
		features: {
			type: Object,
			default: () => ({
				'Comunicação Unificada': [
					{}, {}, {},
				],
				'Colaboração': [
					{}, {}, {},
				]
			})
		},
		initialized: {
			type: Boolean,
			default: true
		}
	},

	data: () => ({
		animated: false,
	}),

	computed: {
		featuresData(){
			const data = {};
			const comunicacao_unificada = this.$t('squad.features.comunicacao_unificada.title');
			data[comunicacao_unificada] = ['voz','video','mensagem'].map(i => {
				return {
					image: this.$t(`squad.features.comunicacao_unificada.${i}.image`),
					label: this.$t(`squad.features.comunicacao_unificada.${i}.label`),
				}
			})

			const colaboracao = this.$t('squad.features.colaboracao.title');
			data[colaboracao] =  ['audio_videoconferencia','gerenciamento_documentos','calendario'].map(i => {
				return {
					image: this.$t(`squad.features.colaboracao.${i}.image`),
					label: this.$t(`squad.features.colaboracao.${i}.label`),
				}
			})

			return data;
		}
	},

	watch: {
		initialized(value) {
			if (value && !this.animated)
				this.init()
		}
	},

	methods: {
		init() {
			this.animated = true

			TweenMax.staggerFrom(this.$el.querySelectorAll('.ci-squad__animate--horizontal'), 1.5, {
				x: '-=80',
				opacity: 0,
				ease: Power2.easeOut,
			}, .3)

			TweenMax.staggerFrom(this.$el.querySelectorAll('.ci-squad__animate--scale'), 1.5, {
				opacity: 0,
				ease: Power2.easeOut,
			}, .15)

			TweenMax.staggerFrom(this.$el.querySelectorAll('.ci-squad__animate--scale'), 1.5, {
				scale: .75,
				ease: Elastic.easeOut,
			}, .15)
		}
	}
}
</script>

<style lang="scss">

.box-download-area {
	width: 100%;
	float: left;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	.download-area {
		

		&__title {
			padding-top: 20px;
			font-family: 'avenir';
			color: #50504f;
			font-size: 45px;
			font-weight: 700;
		}

		&__subtitle {
			font-family: 'avenir';
			font-size: 30px;
			font-weight: 700;
			color: #ffffff;
		}

		&__box {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 10px;
			padding-bottom: 50px;
			padding-top: 20px;
		}

		&__text-btn {
			font-family: 'avenir';
			background-color: #D5EC38;
			padding: 25px 50px;
			border-radius: 50px;
			font-weight: 700;
			font-size: 20px;
			color: #859921;
			text-align: center;
		}

	}
}


.ci-squad{
	padding: 40px 0;
	margin-bottom: 64px;
	position: relative;
	z-index: 10;
	@include grid-container;
	&__container{
		transition: opacity .6s;
		@include outer-container;
		max-width: 1024px!important;
	}
	&__header{
		text-align: center;
		padding: 80px 24px;
	}
	&__brand{
		width: 250px;
		margin: 0 auto 24px;

		img{
			max-width: 250px;
		}
	}
	&__features{
		position: relative;
		/*border: solid 1px green;*/
		&:before{
			content: "";
			position: absolute;
			left: 50%;
			top: 50%;
			-webkit-transform: translate(-50%);
			width: 2px;
			height: 65%;
			transform: translateY(-50%);
			background: #b9cf2f;
			-webkit-transform-origin: 0 0;
			transform-origin: 0 0;
			z-index: -1;
		}				
	}
	&__category{
		margin: 0;
		-webkit-order: 0;
		-ms-flex-order: 0;
		order: 0;
		-webkit-flex: 0 1 auto;
		-ms-flex: 0 1 auto;
		flex: 0 1 auto;
		-webkit-align-self: auto;
		-ms-flex-item-align: auto;
		align-self: auto;
		width: 100%;
		text-align: center;
		font-size: 23px;
		margin-bottom: 35px;
	}
	&__items{
		position: relative;
	}
	&__feature{
		margin-bottom: 50px;
		position: relative;
	}
	&__body{
		/*border: solid 1px red;*/
	}
	&__sample{
		max-width: 100vw;
		flex-flow: column wrap;
		pointer-events: none;
		align-self: flex-start;
		text-align: center;
		/*border: solid 1px red;*/
		/*display: none;*/
		&__screen{	
			width: 100%;
			max-width: 320px;
		}
		.chat{
			width: 100%;
			max-width: 320px;
		}
		.apps{
			margin-left: -15px;
		}
	}
	&__background{
		position: absolute;
		top: -200px;
		left: 0;
		right: 0;
		bottom: 0;
		overflow: hidden;
		z-index: -2;
		&:before{
			content: "";
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			bottom: 0;
			background: #aec70b;
			z-index: -1;
		}
	}
	&__rear,
	&__front{
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
	}
	&__arrow{
		position: absolute;
		top: 100%;
		left: 50%;
		transform: translate(-50%, -2px);
		span{
			color: white;
			position: absolute;
			left: 50%;
			top: -10px;
			font-size: 20px;
			margin-left: -8px;
			animation-name: arrow-down;
			animation-duration: 1.5s;
			animation-iteration-count: infinite;
			transition: .2s $easeInOutQuad;
		}
	}
	

	@include grid-media($g-tablet-up) {
		padding: 80px 0;

		.ci-squad{
			&__header{
				text-align: left;
				display: flex;
				align-items: center;
				margin-bottom: 40px;
				padding: 80px 0 24px 24px;
			}
			&__brand{
				width: 250px;
				margin-bottom: 0;
				margin-right: 48px;
				flex-shrink: 0;
			}
			&__content{
				flex: 1;
			}
			&__features{
				text-align: left;
				display: flex;
				/*margin-bottom: 32px;*/
			}
			&__category{
				margin-bottom: 20px;
			}
			&__body{
				margin: 0 auto;
				@include grid-column(8);
				margin-left: -16px;
			}
			&__features{
				text-align: center;
				@include grid-column(12);
				flex-flow: column wrap;
				float: left;
				&:before{
					content: none;
				}
				&.first{
					position: relative;
					
					.ci-squad__feature:last-child,
					.ci-squad__feature:first-child{
						
						&:before{
							content: "";
							position: absolute;
							left: 50%;
							top: 62%;
							-webkit-transform: translate(-50%);
							width: 2px;
							height: 100%;
							background: #b9cf2f;
							-webkit-transform-origin: 0 0;
							transform-origin: 0 0;
							z-index: -1;
						}					
					}
				}
			}
			&__sample{
				@include grid-column(4);
				flex-flow: column wrap;
				pointer-events: none;
				align-self: flex-start;

				&__screen{	
					width: 100%;
				}
				.chat{
					width: 107%;
				}
			}
			&__items{
				display: flex;
				position: relative;
				flex: 1;
				&:before{
					content: "";
					position: absolute;
					left: 50%;
					top: 55px;
					transform: translate(-50%);
					width: 65%;
					height: 2px;
					background: #b9cf2f;
					transform-origin: 0 0;
					z-index: -1
				}
			}
			&__feature{
				flex: 1;
				position: relative;
				&:last-child{
					&:after{content: none;}
				}

			}
			
		}
	}
	
	@include grid-media($g-tablet-up) {
		.ci-squad{
			&__rear,
			&__front{
				animation-name: wave;
				animation-iteration-count: infinite;
			}
			&__rear{
				animation-duration: 8s;
			}
			&__front{
				animation-duration: 10s;
			}
			&__brand{
				animation-name: brand;
				animation-iteration-count: infinite;
				animation-duration: 8s;
			}
		}

		@keyframes wave {
			0% {
				transform: translate(-50%, 0px);
			}
			50% {
				transform: translate(calc(-50% - 100px), 40px);
			}
			100% {
				transform: translate(-50%, 0px);
			}
		}

		@keyframes brand {
			0% {
				transform: translateY(0px);
			}
			50% {
				transform: translateY(-10px);
			}
			100% {
				transform: translateY(0px);
			}
		}
	}

	@include grid-media($g-desktop-up) {
		padding: 80px 0;
	}

	@keyframes arrow-down {
		0% {
			transform: translateY(0px);
		}
		50% {
			transform: translateY(5px);
		}
		100% {
			transform: translateY(0px);
		}
	}

	&.is-idle{
		.ci-squad{
			&__container{
				opacity: 0;
			}
		}
	}
}

</style>
