<template>
	<form class="ci-contact"
		:action="action"
		:class="{
			'is-sending': state == 'sending'
		}"
		method="POST"
		@submit="onSubmit"
	>
		<ci-input-text
			class="ci-contact__name"
			:placeholder="$t('contact.name')"
			v-model="form.name"
			name="name"
			:theme="theme"
		/>
		<ci-input-text
			class="ci-contact__email"
			:placeholder="$t('contact.email')"
			v-model="form.email"
			name="email"
			type="email"
			:theme="theme"
		/>
		<ci-input-text
			class="ci-contact__company"
			:placeholder="$t('contact.company')"
			v-model="form.company"
			name="company"
			:theme="theme"
		/>
		<ci-input-text
			class="ci-contact__phone"
			:placeholder="$t('contact.phone')"
			v-model="form.phone"
			name="phone"
			:theme="theme"
		/>
		<ci-input-text
			class="ci-contact__subject"
			:placeholder="$t('contact.subject')"
			v-model="form.subject"
			name="assunto"
			:theme="theme"
		/>
		<ci-input-text
			class="ci-contact__message"
			type="textarea"
			:placeholder="$t('contact.message')"
			v-model="form.message"
			name="message"
			:theme="theme"
		/>
		<ci-button
			class="ci-contact__submit"
			type="submit"
			:theme="theme"
		>
			{{ sending ? $t('contact.sending') : $t('contact.submit') }}
		</ci-button>
	</form>
</template>

<script>
import CIButton from '@/components/atoms/CIButton'
import CIInputText from '@/components/atoms/CIInputText'

export default {
	components: {
		'ci-input-text': CIInputText,
		'ci-button': CIButton
	},

	props: {
		action: {
			type: String,
			default: ''
		},
		theme: {
			type: String,
			default: 'accent'
		},
		sending: {
			type: Boolean,
			default: false
		},
	},

	data: () => ({
		form: {
			name: '',
			email: '',
			company: '',
			phone: '',
			subject: '',
			message: '',
		},
		sent: false,
		state: 'idle'
	}),

	methods: {
		onSubmit(event) {
		  event.preventDefault()

		  // Pass the component itself to event
		  this.$emit('submit', this)
		},

		clear() {
			this.form.name = ''
			this.form.email = ''
			this.form.company = ''
			this.form.phone = ''
			this.form.subject = ''
			this.form.message = ''
		}
	}
}
</script>

<style lang="scss">
.ci-contact{
	.ci-input-text{
		margin-bottom: 8px;
	}
	&__submit{
		width: 100%;
	}

	@include grid-media($g-desktop-up) {
		.ci-input-text{
			&__submit{
				width: 240px;
			}
		}
	}

	&.is-sending{
		pointer-events: none;
		opacity: .6 !important;
	}
}
</style>
