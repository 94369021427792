<template>
    <div class="content-02">
        <div class="card-form">
            <div class="title">
                <div>Fique por dentro de mais</div>
                <div>novidades de CX</div>
            </div>
            <div class="form">
                <div class="form-group" style="margin-bottom: 20px;">
                    <label>Nome:</label>
                    <input type="text" class="form-control">
                </div>
                <div class="form-group">
                    <label>E-mail:</label>
                    <input type="email" class="form-control">
                </div>
            </div>
            <div class="box-footer">
                <button class="btn-default">Cadastre-se</button>
            </div>
        </div>
        <div class="desc-box">
            <div class="star-box">
                <img src="../image/star.png" />
            </div>
            <div class="box-title">
                <div class="title">
                    <div>Episódios novos disponíveis</div>
                    <div>todas as terças-feiras</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            
        }
    }
}
</script>

<style lang="scss">
    .page-cast {
        .content-02 {
            position: relative;
            background-color: #adcf00;
            padding: 150px 0 140px 0;
            display: flex;
            justify-content: center;
            align-items: center;

            .card-form {
                width: 500px;
                background-color: #ffffff;
                border-radius: 30px;
                box-shadow: 0 5px 5px 5px rgba(0, 0, 0, .05);

                .title {
                    color: #414858;
                    font-weight: bold;
                    text-align: center;
                    margin: 60px 0 20px 0;
                    font-size: 20px;
                }

                .form {
                    margin: 20px 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;

                    .form-group {
                        label {
                            width: 100%;
                            font-size: 13px;
                            color: #414858;
                            padding: 0 0 0 10px;
                        }
                        input {
                            box-sizing: border-box;
                            width: 100%;
                            min-width: 300px;
                            border: 1px solid #adcf00;
                            border-radius: 25px;
                            min-height: 32px;
                            padding: 0 10px ;
                            outline: none;
                        }
                    }
                }

                .box-footer{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 20px 0 70px 0;

                    .btn-default {
                        background-color: #adcf00;
                        border: none;
                        border-radius: 25px;
                        padding: 12px 45px;
                        font-size: 20px;
                        font-weight: bold;
                        color: #414858;
                        outline: none;

                        &:active {
                            background-color: #b6da02;
                        }
                    }
                }

            }

            .desc-box {
                position: absolute;
                bottom: -50px;
                left: calc(50% - 200px);
                width: 400px;
                height: 100px;
                background-color: #ffffff;
                border-radius: 30px;
                box-shadow: 0 5px 5px 5px rgba(0, 0, 0, .05);
                display: flex;
                justify-content: space-between;
                align-items: center;
                z-index: 1;

                .star-box {
                    background-color: #fed626;
                    width: 40%;
                    height: 100%;
                    border-radius: 30px 30px 0 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        width: 50px;
                    }
                }

                .box-title {
                    width: 100%;

                    .title {
                        font-size: 15px;
                        color: #707070;
                        text-align: center;
                        font-weight: bold;
                    }
                }
            }

            @media screen and (max-width: 600px){
                .card-form {
                    width: 95vw;

                    .form {
                        .form-group {
                            input {
                                min-width: auto;
                            }
                        }
                    }   
                }

                .desc-box {
                    width: 95vw;
                    left: calc(50% - (95vw / 2));
                }
            }

        }
    }
</style>