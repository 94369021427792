<template>
    
    <div class="highlight">
        <ci-text class="highlight__titles subh" type="subhead">
            Destaques
        </ci-text>

        <carousel :per-page="1" 
                  :mouse-drag="true" 
                  :navigationEnabled="true" 
                  @slideclick="handleSlideClick"
                  :paginationEnabled="false"
                  navigationPrevLabel=""
                  navigationNextLabel=""
                  class="carouselHighlights"
                  autoplay="autoplay"
        >
            <slide class="oneHighlight" v-for="post in highlighted" v-bind:key="post.id">
                <router-link :to="{ name: 'post', params: { postID: post.id, postSlug: post.slug}}">
                    <figure>
                        <img v-bind:src="post.firstimg[0]">

                            <small v-bind:class="post.cats[0].slug" class="cats" v-for="category in post.cats" v-bind:key="category.id" v-if="category.name != 'Sem categoria'">
                                <router-link :to="{
                                        name: 'categoria',
                                        params: {catID: category.cat_ID, catSlug: category.slug } 
                                    }
                                ">
                                    {{category.name}}
                                </router-link>
                            </small>
                    </figure>
                    <div class="oneBody">
                        <ci-text class="high__titles" type="subhead" v-html="post.title.rendered"></ci-text>
                                
                        <ci-text class="moment"> 
                            <i class="far fa-clock"></i>
                            {{post.date | moment }}
                        </ci-text>
                    </div>
                </router-link>
            </slide>
        </carousel>
    </div>
</template>
<script>
import CIText from '@/components/atoms/CIText'
import moment from 'moment'
import { Carousel, Slide } from 'vue-carousel'

export default {
	components: {
        'ci-text': CIText,
        Carousel,
        Slide
    },
    data(){
		return{
			highlighted: ''
		}
		animated: false
	},
    mounted () {
        this.$http.get("/wp-json/wp/v2/posts?tags[]=6").then(response => {
			this.highlighted = response.data;
		});

    },
    methods: {
       handleSlideClick (dataset) {
            console.log(dataset.index, dataset.name)
        },
        carouselNav($event, direction) {
            const ref = $event.target.closest('[data-ref]').dataset.ref;
            const carousel = this.$refs[ref];

            carousel.advancePage(direction);
        }
    },
    filters: {
		moment: function (date) {
			return moment(date).format('DD.MM.YYYY');
		}
	},
}
</script>
<style lang="scss">
.highlight{
    
    width: 100%;

    &__titles.subh{		
		text-transform: uppercase;
		color: #7f8189;
		border-bottom: solid 1px #d8d9db;
		position: relative;
		padding-bottom: 14px;
		margin-bottom:30px;
		width: 100%;
	}
	
	&__titles.subh::after{
		content: '';
		width: 50%;
		border-radius: 3px;
		padding: 3px;
		background: #b3c520;
		position: absolute;
		bottom: -3.5px;
		left: 0;
	}
    .moment{
        color: #fff;
    }
    
    .VueCarousel-navigation{
        position: absolute;
        top: 28px;
        right: 40px;
    }
}
.VueCarousel-navigation-prev{
    background: rgba(0,0,0,0.6)!important;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    background-size: cover;
    -webkit-transform: rotate(180deg)!important;
    transform: translateY(-52%) translateX(-125%) rotate(180deg)!important;
    left: 0;
    outline: none!important;
    padding: 0;
    display: flex;    
    align-items: center;
    justify-content: center;
    
    &:after{
        font-size:9px;
       font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f054";
       color: #fff;
    }
}
.VueCarousel-navigation-next{
    
    background: rgba(0,0,0,0.6)!important;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    background-size: cover;
    outline: none!important;
    padding: 0;
    display: flex;    
    align-items: center;
    justify-content: center;

    &:after{
        font-size:9px;
       font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f054";
       color: #fff;
    }
} 
.oneHighlight{
        position: relative;
        height: 380px;
        max-width: 100%!important;
        display: block;
        width: 100%;
        
        figure{
            overflow: hidden;
            margin: 0;
            border-radius: 10px;
            padding: 0;
            position: relative;
            width: 100%;
            height: 99%!important;
            
            &:before {
                content: '';
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.34);
            }
            img{
                width: 100%;
                height: 100%;
                border-radius: 10px;
                object-fit: cover;
                object-position: center center;
            }
        }
        .oneBody{
            position: absolute;
            color: #fff !important;
            bottom: 6%;
            left: 6%;
            width: calc(100% - 12%);

            .high__titles{
                line-height: 130%;
                letter-spacing: 0.5px;
                margin-top: 5px;
                font-size: 20px;
                color: #fff!important;
            }
        }
    }

@include grid-media($g-tablet-up) {
    .highlight{
        width: 50%!important;
        margin-bottom: 23px;
        display: inline-block;
        vertical-align: top;
    }
}
@include grid-media($g-desktop-up) {
    .highlight{
        width: 100%!important;	  
    }
    .oneHighlight{
        position: relative;

        figure{
            overflow: hidden;
            margin: 0;
            border-radius: 10px;
            padding: 0;
            position: relative;
            width: 100%;
            height: 99%!important;
            
            &:before {
                content: '';
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.34);
            }
            img{
                object-fit: cover;
                width: 100%;
                height: 380px;
                border-radius: 10px;
                object-fit: cover;
                object-position: center center;
            }
        }
        .oneBody{
            position: absolute;
            color: #fff !important;
            bottom: 6%;
            left: 6%;
            width: calc(100% - 12%);

            .high__titles{
                line-height: 130%;
                letter-spacing: 0.5px;
                margin-top: 5px;
                font-size: 20px;
                color: #fff!important;
            }
        }
    }
}
</style>