<template>
<div id="ftBlog">
    <!-- <div class="groundAbove"></div> -->
	<img class="groundAbove" :src="publicPath + 'images/groundBlog.png'">
	<img class="groundBellow" :src="publicPath + 'images/groundBlogbellow.png'">
	<!-- <div class="groundBellow"></div> -->
	<footer class="ci-b-footer">        
		<div class="ci-b-footer__container">
            
        <ci-text class="bfooterTyping" v-html="$t('footer.group')" />
        <nav class="ci-b-footer__navigation ">
        <ci-button-icon
                :v-for="item in social"
                class="ci-mapping__social"
                :name="item.icon"
                theme="accent"
                size="sm"
                @click="onSocialClick(item)"
            />
        </nav>
		</div>
	</footer>
</div>
</template>

<script>
import CIText from '@/components/atoms/CIText'
import CIButtonIcon from '@/components/atoms/CIButtonIcon'

export default {
	components: {
		'ci-text': CIText,
		'ci-button-icon': CIButtonIcon
	},

	props: {
		social: {
			type: Array,
			default: () => ([
				{
					icon: 'fab fa-instagram',
					link: 'https://www.instagram.com/citrus.cx'
				},
				{
					icon: 'fab fa-linkedin',
					link: 'https://www.linkedin.com/company/citruscx'
				},
				{
					icon: 'fab fa-facebook-f',
					link: 'https://www.facebook.com/citrus.cx/'
				},
				{
					icon: 'fab fa-twitter',
					link: 'https://twitter.com/citruscx'
				},
				{
					icon: 'fab fa-youtube',
					link: 'https://www.youtube.com/channel/UCRjhPkpLlh0S6muu8y6KlmA'
				},
			])
		}
	},

	methods: {
		onSocialClick(item) {
			window.open(item.link, '_blank')
		}
	}
}
</script>

<style lang="scss">
#ftBlog{
	float: left;

	img{
		pointer-events: none!important;
		
	}
}
.groundAbove{
    width: 100%;
    height: 38vh;
    -o-object-fit: cover;
    object-fit: cover;
    margin-bottom: -25vh;
    z-index: -98;// background: url(../../../images/groundBlogbellow.png) no-repeat;
    // background-size: cover;	
	
}
.groundBellow{
    // width: 100%;
    // height: 24vh;
    // background: url(../../../images/groundBlog.png) no-repeat;
    // background-size: cover;
	// object-fit: cover;
    // z-index: -99;

	width: 100%;
    height: 46vh;
    -o-object-fit: cover;
    object-fit: cover;
    z-index: -99;
    margin-top: -22vh;
}
.ci-b-footer{
	// border-top: 9px solid $t-primary;
	background: #f6f5f8!important;
	width: 100%;
	height: auto;
	display: flex;
	align-items: center;
	flex-flow: row;

	&__container{
		width: 100%;
		text-align: left;
		@include outer-container;
		font-size: 15px!important;
		display: block;
		text-align: center;
		padding-bottom: 30px;
	}
	.bfooterTyping{
		line-height: 60px;
	}
	@include grid-media($g-tablet-up) {
		padding: 0;

		.ci-b-footer{
			height: 120px;
			padding: 32px 0; 

			&__container{
				display: flex;
				align-items: center;
				height: 85px;
				padding: 0 15px;
				position: relative;
			}
			&__copy{
				order: 1;
				text-align: left;
				margin-top: 0;
				@include grid-column(12);
			}
			&__info{
				order: 1;
				text-align: right;
				margin-top: 0;
				@include grid-column(12);
			}
			&__navigation{
				position: absolute;
				right: 15px;
			}
		}
	}
	@include grid-media($g-desktop-up) {
		
		.ci-b-footer{
			width: 100%;
			text-align: left;
			@include outer-container;
			font-size: 15px!important;
			display: block;
			text-align: center;

			&__container{
				display: flex;
				align-items: center;
				position: relative;
				display: inline-flex;
			}
			
		}
	}
}
</style>
