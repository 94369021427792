<template>
  <div
    class="ci-navigation"
    :class="[
      `ci-navigation--${size}`,
      {
        'is-inline': inline,
      },
    ]"
  >
    <div
      class="ci-navigation__option"
      :class="{ 'is-active': item.link == value }"
      v-for="(item, index) in options"
      @click.stop="$emit('input', item.link)"
    >
      <ci-text
        class="ci-navigation__label"
        :type="size == 'md' ? 'body' : 'subhead'"
      >
        {{ itemMenu(item.label) }}

        <ci-text
          class="ci-navigation__tooltip"
          v-if="item.classes.includes('hot')"
          type="tooltip"
          :dark="true"
        >
          {{ item.description }}
        </ci-text>
      </ci-text>

      <div
        class="ci-navigation__submenu"
        v-if="item.children && item.children.length > 0"
      >
        <ci-text
          class="ci-navigation__child"
          v-for="(child, index) in item.children"
          @click.native.stop="$emit('input', child.link)"
        >
          {{ child.label }}
        </ci-text>
      </div>
    </div>
  </div>
</template>

<script>
import CIText from "@/components/atoms/CIText";

export default {
  components: {
    "ci-text": CIText,
  },

  props: {
    value: {
      type: String | Number,
      default: "home",
    },
    inline: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    size: {
      type: String,
      default: "md", // md | lg
    },
  },

  methods: {
	  itemMenu(label){
		  const text = String(label).toLowerCase().replace(/[^a-zA-Zs]/g, "");
		  return this.$t(`menu.${text}`);
	  }
  }
};
</script>

<style lang="scss">
.ci-navigation {
  display: flex;
  flex-flow: column;
  &__option {
    padding: 8px 16px;
    background: transparent;
    border: 0;
    text-align: left;
    outline: none;
    cursor: pointer;
    position: relative;
    &.is-active {
      color: white;
      &:after {
        width: 62px;
      }
    }
    &:after {
      content: "";
      position: absolute;
      width: 0px;
      bottom: 0;
      left: 16px;
      height: 2px;
      background: $t-accent;
      transition: 0.3s $easeInOutQuad;
    }
  }
  &__label {
    position: relative;
    display: inline-block;
  }
  &__tooltip {
    background: rgba($t-accent, 1);
    position: absolute;
    border-radius: 4px;
    text-align: center;
    white-space: nowrap;
    padding: 2px 4px 0;
    left: 100%;
    top: 50%;
    transform: translate(16px, -50%);
    animation-name: nav-tooltip;
    animation-iteration-count: infinite;
    animation-duration: 4s;
    box-shadow: 0 2px 4px rgba(black, 0.05);
    &:before {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      top: 50%;
      left: initial;
      right: 100%;
      transform: translate(0, -50%);
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
      border-right: 4px solid $t-accent;
    }
  }

  &--lg {
    &:not(.is-inline) {
      .ci-navigation {
        &__option {
          margin-bottom: 8px;
        }
      }
    }
  }

  &.is-inline {
    flex-flow: row;
    align-items: center;
    .ci-navigation {
      &__tooltip {
        top: initial;
        bottom: 100%;
        left: 50%;
        transform: translate(-50%, -4px);
        animation-name: nav-tooltip-inline;
        &:before {
          top: 100%;
          left: 50%;
          transform: translate(-50%, 0);
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-top: 4px solid $t-accent;
        }
      }
      &__option {
        &:after {
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }

  @include grid-media($g-desktop-up) {
    .ci-navigation {
      &__tooltip {
        opacity: 0.6;
        transition: opacity 0.2s $easeInOutQuad;
      }
      &__option {
        position: relative;
      }
      &__option:hover {
        &:after {
          width: 62px;
        }
        .ci-navigation {
          &__tooltip {
            opacity: 1;
          }
          &__submenu {
            top: 100%;
            opacity: 1;
            visibility: visible;
          }
        }
      }
      &__submenu {
        position: absolute;
        top: 70%;
        left: 0%;
        min-width: 200px;
        background: white;
        padding: 16px 0;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(black, 0.06);
        transition: 0.3s $easeInOutQuad;
        opacity: 0;
        visibility: hidden;
      }
      &__child {
        padding: 8px 16px;
        line-height: 120%;
      }
    }
  }

  @keyframes nav-tooltip {
    0% {
      transform: translate(16px, -50%);
    }
    60% {
      transform: translate(20px, -50%);
    }
    100% {
      transform: translate(16px, -50%);
    }
  }

  @keyframes nav-tooltip-inline {
    0% {
      transform: translate(-50%, -4px);
    }
    60% {
      transform: translate(-50%, 0);
    }
    100% {
      transform: translate(-50%, -4px);
    }
  }
}
</style>
