<template>
	<div class="ci-benefits"
		:class="[
			{
				'is-idle': !initialized && !animated
			}
		]"
	>
	
		<div class="ci-benefits__container">
			<div class="ci-benefits__content ci-benefits__animate">
					<ci-text class="ci-benefits__title" type="title" v-html="title"></ci-text>
					<ci-text class="ci-benefits__description" type="body" v-html="content"></ci-text>
				</div>

			<div class="ci-benefits__items">
				<ci-benefit
					class="ci-benefits__item ci-benefits__animate"
					v-for="(benefit, index) in benefits"
					:icon="benefit.icon"
					:description="benefit.description"
					:key="index"
				/>
			</div>
		</div>

	</div>
</template>

<script>
import CIText from '@/components/atoms/CIText'
import CIBenefit from '@/components/molecules/CIBenefit'

import { TweenMax, Power2, Elastic } from 'gsap/TweenMax'

export default {
	components: {
		'ci-text': CIText,
		'ci-benefit': CIBenefit
	},
	props: {
		icon: {
			type: String,
			default: '/images/icon-finance.png'
		},
		title: {
			type: String,
			default: 'Default'
		},
		description: {
			type: String,
			default: 'Maecenas et nibh lorem. Proin malesuada, lorem quis convallis elementum, velit sapien posuere purus, nec tempor ligula augue a elit.'
		},
		content: {
			type: String,
			default: 'Default'
		},
		benefits: {
			type: Array,
			default: () => ([
				{}, {}, {}, {}, {}, {}
			])
		},
		initialized: {
			type: Boolean,
			default: true
		}
	},

	data: () => ({
		animated: true
	}),

	mounted() {
		if (this.initialized)
			this.init()
	},

	watch: {
		initialized(value) {
			if (value && !this.animated)
				this.init()
		}
	},

	methods: {
		init() {
			this.animated = true

			TweenMax.staggerFrom(this.$el.querySelectorAll('.ci-benefits__animate'), 1.5, {
				x: "-=80",
				opacity: 0,
				ease: Power2.easeOut
			}, .4)
			

		},
	}
}
</script>

<style lang="scss">
.ci-benefits{
	position: relative;
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	position: relative;
	transition: opacity .6s;
	z-index: 30;

	@include grid-container;

	&__container{
		width: 100%;
		@include outer-container;
	}
	&__content{
		@include grid-column(6);
		margin: 0 auto!important;
		float: none!important;
	}
	&__title{
		margin: 16px 0;
		font-family: 'montserrat';
		font-size: 28px;
		line-height: 115%;
		text-align: center;
		font-weight: bold;
	}
	&__description{
	    color: #64666b;
	    line-height: 165%;
	    font-size: 16px;
	    font-family: 'montserrat';
	    margin-top: 26px;
	    text-align: justify;
	}
	
	&__items{
		@include grid-column(6);
		text-align: center;
		margin-top: 30px;
		align-items: center;
	    display: inline-flex;
	    flex-wrap: wrap;
	    justify-items: center;
	}
	&__item{
		@include grid-column(6);
		float: left;
		margin-left: 16px;
		text-transform: uppercase;
		font-size: 14px;
		margin-bottom: 25px;
	}
	&__circle{
		width: 100px;
		height: 100px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 auto 10px;
		position: relative;
		z-index: 5;
		&:after{
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			border-radius: 999px;
			background: white;
			z-index: -1;
			box-shadow: 0px 1px 13px 0px #f1f1f1;
			transition: all 0.3s;
		}
	}
	&__comment{
		font-size: 14px;
		line-height: 23px;
		max-width: 84%;
		font-weight: 600;
		color: #94969f;
		margin: 0 auto;
	}
	@include grid-media($g-tablet-up){
		.ci-benefits{

			&__container{
				margin-top: 95px;
			}
			&__title{
				margin: 0 0 24px;
				text-align: center;
				font-size: 38px;
				max-width: 660px;
				margin: 0 auto;
				float: none;
			}
			&__content{
				@include grid-column(10);
			}
			&__description{
				max-width: 940px;
				line-height: 165%;
				font-size: 18px;
				text-align: center !important;
				font-family: 'montserrat';
				margin-top: 30px;
			}
			&__items{
				@include grid-column(12);
				text-align: center;
				margin-top: 45px;
			}
			&__item{
				width: calc(50% - 25px);
				float: left;
				margin-left: 16px;
				text-transform: uppercase;
				font-size: 14px;
				margin-bottom: 50px;
			}
			&__circle{
				width: 130px;
				height: 130px;
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 0 auto 10px;
				position: relative;
				z-index: 5;
				&:after{
					content: "";
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					border-radius: 999px;
					background: white;
					z-index: -1;
				}
			}
			&__comment{
				font-size: 14px;
				line-height: 23px;
				max-width: 84%;
				font-weight: 600;
				color: #94969f;
				margin: 0 auto;
			}
		}
	}
	
	@include grid-media($g-desktop-up) {
		.ci-benefits{
			&__shape{
				width: auto;
				right: 51%;
				top: -340px;
			}
			&__item{
				@include grid-column(3);
				float: left;
				margin-left: 16px;
				text-transform: uppercase;
				font-size: 14px;
				margin-bottom: 50px;
				.ci-benefits__circle{
					transition: all 0.3s;	
				}
				&:hover{

					.ci-benefits__circle{
						transform: scale(1.08) translateY(-3px);
						transition: all 0.4s;	
					}
				}
			}
		}
	}

	// Idle
	&.is-idle{
		opacity: 0;
	}
}
</style>
