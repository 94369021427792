<template>
	<div class="ci-seed-system">
		<header class="ci-seed-system__header">
			<img
				class="ci-seed-system__brand"
				:src="brand"
			>
		</header>
		<div class="ci-seed-system__body">
			<ci-text class="ci-seed-system__text" type="lead" v-html="content" />

			<div class="ci-seed-system__items">
				<div class="ci-seed-system__item" :v-for="feature in features">
					<span class="ci-seed-system__check fa fa-check">
					</span>

					<ci-text class="ci-seed-system__feature" type="lead">
						{{ feature.name }}
					</ci-text>

					<ul class="ci-seed-system__subitems">
						<li class="ci-seed-system__subitem" :v-for="item in feature.items">
							<ci-text class="ci-seed-system__subfeature">
								{{ item.name }}
							</ci-text>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import CIText from '@/components/atoms/CIText'

export default {
	components: {
		'ci-text': CIText
	},
	props: {
		content: {
			type: String,
			default: 'Consectetur odio excepturi fugit consectetur sit. Rerum placeat blanditiis quod maxime corporis Incidunt doloribus hic accusamus sequi blanditiis, labore sequi. Magni dicta assumenda dolorum fugiat officia. Numquam enim nihil aliquid'
		},
		brand: {
			type: String,
			default: 'images/brand-citrus-seed.png'
		},
		features: {
			type: Array,
			default: () => ([
				{
					name: 'Consectetur odio ',
					items: [
						{ name: 'Elit cupiditate iste.' }
					]
				}
			])
		}
	}
}
</script>

<style lang="scss">
.ci-seed-system{
	padding: 40px 0;
	&__header{
		text-align: center;
		margin-bottom: 24px;
	}
	&__brand{
		max-width: 200px;
	}
	&__text{
	}
	&__items{
		margin-top: 24px;
	}
	&__item{
		padding-left: 24px;
		position: relative;
		box-sizing: border-box;
		margin: 8px 0;
	}
	&__check{
		color: $t-primary;
		position: absolute;
		top: 4px;
		left: 0;
	}
	&__feature{
	}
	&__subitems{
		padding-left: 24px;
		margin: 0;
	}
	&__subitem{
	}
	&__subfeature{
	}

	@include grid-media($g-desktop-up) {
		display: flex;
		.ci-seed-system{
			&__header{
				background: white;
				border-radius: 8px;
				box-shadow: 0 0 8px rgba(black, .06);
				width: 270px;
				height: 270px;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-shrink: 0;
				position: relative;
				z-index: 10;
			}
			&__body{
				padding: 40px;
				flex: 1;
				@include grid-column(12);
			}
			&__text{
				margin-bottom: 48px;
			}
			&__items{
				position: relative;
				z-index: 5;
				display: flex;
				flex-flow: row wrap;
				&:before{
					content: "";
					position: absolute;
					top: -24px;
					right: -24px;
					bottom: -24px;
					left: -80px;
					background: white;
					border-radius: 8px;
					box-shadow: 0 0 8px rgba(black, .06);
				}
			}
			&__item{
				width: 50%;
			}
		}
	}
}
</style>
