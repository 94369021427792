<template>
	<div class="ci-home">
		<ci-spotlight
			ref="spotlight"
			id="spotlight"
			class="ci-home__spotlight"
			:title="spotlight.title"
			:content="spotlight.content"
			:video="spotlight.video"
			:thumbnail="spotlight.thumbnail"
		/>
		<ci-bookinfo
			ref="bookinfo"
			id="bookinfo"
			class="ci-home__bookinfo"
			:title="bookinfo.title"
			:content="bookinfo.content"
			:thumbnail="bookinfo.thumbnail"
		/>
		<ci-benefits
			ref="benefits"
			id="benefits"
			class="ci-home__benefits"
			:title="benefits.title"
			:content="benefits.content"
			:benefits="benefits.items"
		/>
		<ci-experience
			ref="experience"
			id="experience"
			class="ci-home__experience"
			:title="experience.title"
			:content="experience.content"
			:thumbnail="experience.thumbnail"
			:quote="experience.quote"
		/>
		<ci-mapping
			ref="mapping"
			id="mapping"
			class="ci-home__mapping"
			:title="mapping.title"
			:content="mapping.content"
			:mtitle="mapping.mtitle"
		/>
	</div>
</template>

<script>
import CISpotlight from '@/components/organisms/CISpotlight'
import CIBookinfo from '@/components/organisms/CIBookinfo'
import CIBenefits from '@/components/organisms/CIBenefits'
import CIExperience from '@/components/organisms/CIExperience'
import CIMapping from '@/components/organisms/CIMapping'

export default {
	components: {
		'ci-spotlight': CISpotlight,
		'ci-bookinfo': CIBookinfo,
		'ci-benefits': CIBenefits,
		'ci-experience': CIExperience,
		'ci-mapping': CIMapping,
	},

	props: {
		spotlight: {
			type: Object,
			default: () => ({})
		},
		bookinfo: {
			type: Object,
			default: () => ({})
		},
		benefits: {
			type: Object,
			default: () => ({})
		},
		experience: {
			type: Object,
			default: () => ({})
		},
		mapping: {
			type: Object,
			default: () => ({})
		}

	},
	data: () => ({
		active: null,
		previous: null,
		initialized: []
	}),
	watch: {
		// scroll(value) {
		// 	for (let ref in this.$refs) {
		// 		const component = this.$refs[ref],
		// 			offsetTop = component.$el.offsetTop - window.innerHeight

		// 		if (value > offsetTop && !this.initialized.includes(ref))
		// 			this.initialized.push(ref)
		// 	}
		// },

		active(value) {
			this.$emit('change', value)
		}
	},

	mounted() {
		this.active = this.selected
	}
}
</script>

<style lang="scss">
.ci-home{
	overflow: hidden;
	background: white;
}
.header__lang{
	display: none;
}
</style>
