<template>
	<ci-post 
	:anuncios="anuncios"/>
</template>

<script>
import CIPost from '@/components/templates/CIPost'
import { mapGetters } from 'vuex'

export default {
	components: {
		'ci-post': CIPost
	},
	computed: {
		...mapGetters({
			anuncios: 'page/getAnuncios'
		})
	},
	
}
</script>
