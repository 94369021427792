<template>
	<ci-home
		id="ebooks"
		:spotlight="spotlight"
		:bookinfo="bookinfo"
		:benefits="benefits"
		:experience="experience"
		:mapping="mapping"
	/>
</template>

<script>
import CIHome from '@/components/templates/CIHome'
import { mapGetters } from 'vuex'

export default {
	components: {
		'ci-home': CIHome
	},

	props: {
	},

	computed: {
		...mapGetters({
			spotlight: 'page/getSpotlight',
			bookinfo: 'page/getBookinfo',
			benefits: 'page/getBenefits',
			experience: 'page/getExperience',
			mapping: 'page/getMapping'
		})
	},

	methods: {
	}
}
</script>
