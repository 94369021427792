<template>
	<div class="ci-seed-ecosystem"
		:class="[
			{
				'is-idle': !initialized && !animated
			}
		]"
	>
		<div class="ci-seed-ecosystem__container">
			<ci-text class="ci-seed-ecosystem__title ci-seed-ecosystem__animate" type="title">
				{{ title }}
			</ci-text>
			<ci-text class="ci-seed-ecosystem__subhead ci-seed-ecosystem__animate" type="subhead">
				{{ subhead }}
			</ci-text>
			<ci-text
				class="ci-seed-ecosystem__text ci-seed-ecosystem__animate"
				type="lead"
				v-html="content"
			/>
			<!-- <ci-seed-system
				class="ci-seed-ecosystem__system ci-seed-ecosystem__animate"
				v-for="item in items"
				:brand="item.brand"
				:content="item.content"
				:features="item.features"
			/> -->
		</div>
	</div>
</template>

<script>
import CIText from '@/components/atoms/CIText'
import CISeedSystem from '@/components/molecules/CISeedSystem'
import { TweenMax, Power2, Elastic } from 'gsap/TweenMax'

export default {
	components: {
		'ci-text': CIText,
		'ci-seed-system': CISeedSystem
	},

	props: {
		title: {
			type: String,
			default: 'Assumenda veritatis odio (SDC)'
		},
		subhead: {
			type: String,
			default: 'Quas expedita nulla labore ad est sit'
		},
		items: {
			type: Array,
			default: () => ([{}, {}])
		},
		content: {
			type: String,
			default: 'Amet deleniti magni assumenda veritatis odio, enim! Quas expedita nulla labore ad est sit odit? Atque aliquam at ut commodi dignissimos Repellat possimus repellendus quasi veritatis debitis Dolor voluptates quo?'
		},
		initialized: {
			type: Boolean,
			default: true
		}
	},

	data: () => ({
		animated: false
	}),

	mounted() {
		
		if (this.initialized)
			this.init()
	},

	watch: {
		initialized(value) {
			if (value && !this.animated)
				this.init()
		}
	},

	methods: {
		init() {
			this.animated = true

			TweenMax.staggerFrom(this.$el.querySelectorAll('.ci-seed-ecosystem__animate'), 1.5, {
				x: "-=80",
				opacity: 0,
				ease: Power2.easeOut
			}, .4)
		},
	}
}
</script>

<style lang="scss">
.ci-seed-ecosystem{
	background: white;
	padding: 60px 0;
	transition: opacity .6s;
	@include grid-container;
	&__container{
		@include outer-container;
	}
	&__title{
		margin-bottom: 8px;
		@include grid-column(6);
	}
	&__subhead{
		margin-bottom: 24px;
		@include grid-column(6);
	}
	&__text{
		@include grid-column(6);
	}
	&__system{
		@include grid-column(6);
	}

	// Idle
	&.is-idle{
		opacity: 0;
	}
}
</style>
