<template>
	<div class="ci-seed-program">
		<ci-seed-germinating 
			:title="germinatingTitle"
			:content="germinatingContent"
			:initialized="true"
		/>

		<ci-seed-planting
			ref="planting"
			:title="plantingTitle"
			:content="plantingContent"
			:initialized="initialized.includes('planting')"
		/>

		<ci-seed-ecosystem
			ref="sdc"
			class="ci-seed-program__ecosystem"
			:title="$t('seed.sdc.title')"
			:subhead="$t('seed.sdc.subtitle')"
			:content="$t('seed.sdc.content')"
			:items="sdcItems"
			:initialized="initialized.includes('sdc')"
		/>

		<ci-seed-ecosystem
			ref="swc"
			:title="$t('seed.swc.title')"
			:subhead="$t('seed.swc.subtitle')"
			:content="$t('seed.swc.content')"
			:items="swcItems"
			:initialized="initialized.includes('swc')"
		/>

		<ci-seed-transform
			ref="transform"
			:content="transformContent"
			:video="transformVideo"
			:video-thumbnail="transformVideoThumbnail"
			:initialized="initialized.includes('transform')"
		/>

		<ci-try-out
			ref="contact"
			class="ci-seed-program__try-out"
			:title="contactTitle"
			:content="contactContent"
			:brands="false"
			:initialized="initialized.includes('contact')"
			@contact="$emit('contact', $event)"
		/>
	</div>
</template>

<script>
import CISeedGerminating from '@/components/organisms/CISeedGerminating'
import CISeedPlanting from '@/components/organisms/CISeedPlanting'
import CISeedEcosystem from '@/components/organisms/CISeedEcosystem'
import CISeedTransform from '@/components/organisms/CISeedTransform'
import CITryOut from '@/components/organisms/CITryOut'

export default{
	components: {
		'ci-seed-germinating': CISeedGerminating,
		'ci-seed-planting': CISeedPlanting,
		'ci-seed-ecosystem': CISeedEcosystem,
		'ci-seed-transform': CISeedTransform,
		'ci-try-out': CITryOut
	},

	props: {
		scroll: 0,
		germinatingTitle: String,
		germinatingContent: String,
		plantingTitle: String,
		plantingContent: String,
		sdcTitle: String,
		sdcSubhead: String,
		sdcContent: String,
		sdcItems: Array,
		swcTitle: String,
		swcSubhead: String,
		swcContent: String,
		swcItems: Array,
		transformContent: String,
		transformVideo: String,
		transformVideoThumbnail: String,
		contactTitle: String,
		contactContent: String,
		contactEmail: String
	},

	data: () => ({
		initialized: []
	}),

	watch: {
		scroll(value) {
			for (let ref in this.$refs) {
				const component = this.$refs[ref],
					offsetTop = component.$el.offsetTop - window.innerHeight

				if (value > offsetTop && !this.initialized.includes(ref))
					this.initialized.push(ref)
			}
		}
	}
}
</script>

<style lang="scss">
.ci-seed-program{
	background: white;
	&__ecosystem{
		padding-top: 100px;
		margin-top: -100px;
	}
	&__try-out{
		padding-top: 80px;
		padding-bottom: 80px;
	}

	@include grid-media($g-desktop-up) {
		&__ecosystem{
			padding-top: 200px;
			margin-top: -100px;
		}
	}
}
</style>
