<template>
<div>
	<footer class="ci-footer" style="flex-direction:column;text-align: center;">
		<div class="ci-footer__container">
			<!-- <nav class="ci-footer__navigation">
				<ci-button-icon
					v-for="item in social"
					class="ci-footer__social"
					:name="item.icon"
					theme="accent"
					size="sm"
					@click="onSocialClick(item)"
				/>
			</nav> -->

			<ci-text type="body" class="ci-footer__copy">
			  <a :href="publicPath + 'docs/documento_política_de_privacidade.pdf'" class="ci-footer__link">Política de Privacidade</a><br>
			  <a :href="publicPath + 'docs/Politica_de_Cookies_-_Digivox.pdf'" class="ci-footer__link">Políticas de Cookies</a>
			</ci-text>

      		<ci-text type="body" class="ci-footer__info">
      			0800 724 8181 | contato@citrus.cx <br>
				João Pessoa | São Paulo | Miami <br>
				Rua Paulino Pinto, 1500, Cabo Branco, João Pessoa - Paraíba
      		</ci-text>
		</div>
		<div>
			<ci-text>DPO Responsável: Daniela Pitta - dpo@digivox.com.br</ci-text>
			<ci-text>CNPJ: 12.806.448/0001-86 - Copyright @2019 - {{$t('ftCopy.rights')}} - Citrus</ci-text>
		</div>
	</footer>
</div>
</template>

<script>
import CIText from '@/components/atoms/CIText'
import CIButtonIcon from '@/components/atoms/CIButtonIcon'

export default {
	components: {
		'ci-text': CIText,
		'ci-button-icon': CIButtonIcon
	},

	props: {
		// social: {
		// 	type: Array,
		// 	default: () => ([
		// 		{
		// 			icon: 'fab fa-instagram',
		// 			link: 'http://instagram.com'
		// 		},
		// 		{
		// 			icon: 'fab fa-linkedin',
		// 			link: 'http://linkedin.com'
		// 		},
		// 		{
		// 			icon: 'fab fa-facebook-f',
		// 			link: 'http://facebook.com'
		// 		},
		// 		{
		// 			icon: 'fab fa-twitter',
		// 			link: 'http://twitter.com'
		// 		},
		// 	])
		// }
	},

	methods: {
		onSocialClick(item) {
			window.open(item.link, '_blank')
		}
	}
}
</script>

<style lang="scss">
.ci-footer{
	border-top: 9px solid $t-primary;
	background: #f6f5f8;
	width: 100%;
	height: auto;
	display: flex;
	align-items: center;
	padding: 12px 0;

	&__container{
		width: 100%;
		text-align: left;
		@include outer-container;
		font-size: 15px!important;
	}
	&__copy{
		margin-top: 8px;
		@include grid-column(6);
	
	}
	&__info{
		margin-top: 8px;
		@include grid-column(6);
	}

	&__link{
		color:#7a7a7a !important;
		font-weight: normal !important;
		text-decoration: none !important;
	}


	&__link:hover{
		text-decoration: underline !important;
	}

	@include grid-media($g-tablet-up) {
		padding: 0;
		.ci-footer{
			height: 120px;
			padding: 32px 0;    
			&__container{
				display: flex;
				align-items: center;
			}
			&__copy{
				order: 1;
				text-align: left;
				margin-top: 0;
				@include grid-column(12);
			}
			&__info{
				order: 1;
				text-align: right;
				margin-top: 0;
				@include grid-column(12);
			}
		}
	}
	@include grid-media($g-desktop-up) {
		
		.ci-footer{
			padding: 10px 0!important;
			&__container{
				display: flex;
				align-items: center;
			}
			&__copy{
				order: 1;
				text-align: left;
				margin-top: 0;
				@include grid-column(6);
			}
			&__info{
				order: 1;
				text-align: right;
				margin-top: 0;
				@include grid-column(6);
			}
		}
	}
}
</style>
