<template>
	<div class="ci-solution"
		:class="[
			`ci-solution--${theme}`
		]"
	>
		<img class="ci-solution__icon" :src="icon" :alt="title">

		<div class="ci-solution__content">
			<ci-text :theme="theme" type="lead" :bold="true">
				{{ title }}
			</ci-text>
			<ci-text class="ci-solution__text" type="small">
				{{ content  }}
			</ci-text>
		</div>
	</div>
</template>

<script>
import CIText from '@/components/atoms/CIText'

export default {
	components: {
		'ci-text': CIText
	},
	props: {
		icon: {
			type: String,
			default: 'images/icon-finance.png'
		},
		title: {
			type: String,
			default: 'Lorem ipsum'
		},
		content: {
			type: String,
			default: 'Bancos, fintechs e demais instutições financeiros.'
		},
		theme: {
			type: String,
			default: 'primary'
		}
	}
}
</script>

<style lang="scss">
.ci-solution{
	display: flex;
	align-items: flex-start;
	&__icon{
		width: 48px;
		padding-right: 16px;
	}
	&__content{
		flex: 1;
	}
	&__title{
	}
	&__text{
	}
}
</style>
