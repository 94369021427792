<template>
	<div class="ci-suite"
		:class="[
			{
				'is-idle': !initialized && !animated
			}
		]"
	>
		<div class="ci-suite__container">
			<header class="ci-suite__header ci-suite__animate">
				<ci-brand class="ci-suite__brand" type="suite" />
				<ci-text class="ci-suite__text" type="body" :v-html="content"> {{$t('suite.content')}}
				</ci-text>
			</header>

			<div class="ci-suite__items">
				<ci-channel
					v-for="(channel, index) in channelsHanlde"
					class="ci-suite__channel ci-suite__animate"
					:key="index"
					:icon="channel.icon"
					:title="channel.title"
					:content="channel.content"
				/>
			</div>
		</div>

		<div class="ci-suite__swiper swiper-container ci-suite__animate--slide">
			<div class="swiper-wrapper">
				<ci-channel
					v-for="(channel, index) in channelsHanlde"
					class="ci-suite__slide swiper-slide"
					:key="`slide-${index}`"
					:icon="channel.icon"
					:title="channel.title"
					:content="channel.content"
				/>
			</div>
		</div>

		<div class="ci-suite__container">
			<div class="ci-suite__newer ci-suite__animate">
				<!-- <ci-brand class="ci-suite__brand genesys" type="genesys" /> -->
				<ci-text class="ci-suite__text">
					{{ $t('suite.genesys') }}
				</ci-text>
			</div>
		</div>
	</div>
</template>

<script>
import CIBrand from '@/components/atoms/CIBrand'
import CIText from '@/components/atoms/CIText'
import CIChannel from '@/components/molecules/CIChannel'
import Swiper from 'swiper'

export default {
	components: {
		'ci-brand': CIBrand,
		'ci-text': CIText,
		'ci-channel': CIChannel
	},

	props: {
		channels: {
			type: Array,
			default: () => ([
			'voice',
			'whatsapp',
			'chat',
			'socialMedia',
			'sms',
			'webForm',
			'video',
			'apis',
			'email',
			'presential',
			'contactCenter',
			'ura'
		])
		},
		content: {
			type: String,
			default: 'Etiam eu tristique nunc. Integer placerat consequat accumsan. Integer iaculis tellus arcu, id tincidunt felis pellentesque a. Mauris vel tincidunt sem. Vestibulum non magna ornare mauris hendrerit semper.'
		},
		initialized: {
			type: Boolean,
			default: true
		},
	},

	data: () => ({
		animated: false,
		swiper: null,
	}),

	computed: {
		channelsHanlde(){
			return this.channels.map(item => {
				return {
						icon: this.$t(`channels.${item}.icon`),
						title: this.$t(`channels.${item}.title`),
						content: this.$t(`channels.${item}.content`),
					}
				});
		}
	},

	mounted() {
		if (this.initialized)
			this.init()

		this.channelsHanlde;


		this.swiper = new Swiper(this.$el.querySelector('.ci-suite__swiper'), {
			slidesPerView: 'auto',
			centeredSlides: true,
			slideToClickedSlide: true,
			spaceBetween: 16
		})
	},

	watch: {
		initialized(value) {
			if (value && !this.animated)
				this.init()
		}
	},

	destroyed() {
		this.swiper.destroy()
	},

	methods: {
		init() {
			this.animated = true

			TweenMax.staggerFrom(this.$el.querySelectorAll('.ci-suite__animate'), 1, {
				y: "-=80",
				scale: .9,
				opacity: 0,
				ease: Power2.easeOut
			}, .2)

			TweenMax.staggerFrom(this.$el.querySelectorAll('.ci-suite__animate--slide'), 3, {
				y: "-=80",
				opacity: 0,
				ease: Power2.easeOut
			})
		}
	}
}
</script>

<style lang="scss">
.ci-suite{
	position: relative;
	transition: opacity .6s;
	z-index: 20;
	@include grid-container;
	&__container{
		@include outer-container;
	}
	&__header{
		background: white;
		border-radius: 8px;
		box-shadow: 0 4px 16px rgba(black, .06);
		padding: 32px 30px 80px;
		text-align: center;
		margin-bottom: 24px;
		@include grid-container;
	}
	&__brand{
		width: 240px;
		margin: 0 auto 16px;
	}
	&__text{
	}
	&__title{
	}
	&__items{
		display: none;
	}
	&__channel{
		margin-bottom: 5px;
		box-shadow: 0 4px 16px rgba(black, .06);
		@include grid-column(6);
	}
	&__swiper{
		margin-top: -80px !important;
		padding: 32px 0 !important;
	}
	&__slide{
		width: 70% !important;
		box-shadow: 0 4px 16px rgba(black, .06);
	}
	&__newer{
		flex-direction: column;
		padding: 48px 5.5%;
		border-radius: 8px;
		margin-top: 40px;
		background: #fff;
		text-align: center;
		-webkit-box-shadow: 0 4px 16px rgba(0,0,0,.06);
		box-shadow: 0 4px 16px rgba(0,0,0,.06);
		

		.genesys{
			margin:0 auto 24px;

			img{
				max-width: none;
			}
		}		
	}

	@include grid-media($g-tablet-up) {
		.ci-suite{
			&__header{
				text-align: left;
				display: flex;
				align-items: center;
				padding: 48px 10%;
			}
			&__brand{
				width: 240px;
				margin-right: 24px;
				flex-shrink: 0;

				img{
					width: 100%;
					max-width: 240px;
				}
			}
			&__text{
				padding: 0 0;
				flex: 1;
			}
			&__slide{
				width: 240px !important;
			}
			&__newer{
				text-align: left;
				display: flex;
				flex-direction: row-reverse;
				align-items: center;
				padding: 48px 5%;

				.genesys{
					max-width: 290px;
					width: 100%!important;
					margin-right: 0!important;
					margin-bottom: 0;

					img{
						width: 100%;
						max-width: none!important;
					}
				}
				.ci-suite__text{
					text-align: justify;
					font-size: 18px;
					padding: 0 0 0 0 ;
				}
			}
		}
	}

	@include grid-media($g-desktop-up) {
		.ci-suite{
			&__header{
				padding-bottom: 160px;
			}
			&__items{
				/*border: solid 1px green;*/
				max-width: 1100px;
				display: flex;
				flex-flow: row wrap;
				justify-content: flex-start;
				margin: -120px auto 0;
			}
			&__channel{
				flex: 0;
				flex-basis: calc(25% - 12px);
				margin: 6px;
				&:nth-child(2n+2){
					transform: translateY(-45px);
				}
			}
			&__swiper{
				display: none;
			}
			&__text{
			}

		}
	}

	&.is-idle{
		opacity: 0;
	}
}
</style>
