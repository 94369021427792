<template>
	<div class="ci-player"
		:class="[
			`ci-player--${theme}`,
			`ci-player--${aspec}`
		]"
	>
		<div class="ci-player__thumbnail" :style="{ backgroundImage: `url(${thumbnail})` }">
		</div>

		<ci-button-icon
			class="ci-player__play"
			name="fas fa-play"
			:theme="theme"
			@click="playing = true"
		/>

		<transition name="fade">
			<div class="ci-player__video" v-if="playing" v-html="video">
			</div>
		</transition>
	</div>
</template>

<script>
import CIButtonIcon from '@/components/atoms/CIButtonIcon'

export default {
	components: {
		'ci-button-icon': CIButtonIcon
	},

	props: {
		thumbnail: {
			type: String,
			default: 'images/video-thumbnail.png'
		},
		theme: {
			type: String,
			default: 'primary'
		},
		aspec: {
			type: String,
			default: 'wide' // wide | wider | square
		},
		video: {
			type: String,
			default: '<iframe title="Pyre Original Soundtrack - Full Album" width="640" height="360" src="https://www.youtube.com/embed/uH3Aoj1nw58?start=4902&feature=oembed" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
		}
	},

	data: () => ({
		playing: false
	})
}
</script>

<style lang="scss">
.ci-player{
	position: relative;
	border-radius: 16px;
	overflow: hidden;
	z-index: 10;
	&__thumbnail{
		position: absolute;
		background-color: rgba(black, .2);
		background-position: center;
		background-size: cover;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		transition: transform $easeInOutQuad 1s .25s;
	}
	&__play{
		position: absolute;
		top: 50%;
		left: 50%;
		transition: transform $easeInQuad .3s;
		transform: translate(-50%, -50%);
	}
	&__video{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: black;
		iframe{
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
		}
	}

	&:after{
		content: "";
		display: block;
	}

	&--wider{
		&:after{
			padding-top: 65%;
		}
	}
	&--wide{
		&:after{
			padding-top: 75%;
		}
	}
	&--square{
		&:after{
			padding-top: 100%;
		}
	}

	@include grid-media($g-desktop-up) {
		.ci-player{
			&__play{
				&:hover{
					transform: translate(-50%, -50%) scale(1.2);
				}
			}
		}
		&:hover{
			.ci-player{
				&__thumbnail{
					transform: scale(1.05);
				}
			}
		}
	}
}
</style>
