<template>
	<div class="ci-seed-germinating">
		<div class="ci-seed-germinating__container">
			<header class="ci-seed-germinating__header ci-seed-germinating__animate">
				<img
					class="ci-seed-germinating__brand"
					:src="publicPath + 'images/brand-citrus-seed-full.png'"
				>

				<ci-text class="ci-seed-germinating__title" type="subhead">
					{{$t("germinatingTitle")}}
				</ci-text>
			</header>

			<ci-text
				class="ci-seed-germinating__text ci-seed-germinating__animate"
				type="lead"
				v-html="$t('germinatingContent')"
			/> 

			<ci-circle
				class="ci-seed-germinating__circle ci-seed-germinating__scale"
				theme="accent"
			/>
			<img
				class="ci-seed-germinating__leaf ci-seed-germinating__animate"
				:src="publicPath + 'images/bg-germinating.png'"
			/>
			<img
				class="ci-seed-germinating__shape"
				:src="publicPath + 'images/shape-seed.svg'"
			/>
		</div>
	</div>
</template>

<script>
import CIText from '@/components/atoms/CIText'
import CILeaf from '@/components/atoms/CILeaf'
import CICircle from '@/components/atoms/CICircle'
import { TweenMax, Power2, Elastic } from 'gsap/TweenMax'

export default {
	components: {
		'ci-text': CIText,
		'ci-leaf': CILeaf,
		'ci-circle': CICircle
	},

	props: {
		title: {
			type: String,
			default: 'Elit suscipit temporibus iste maxime laborum?'
		},
		content: {
			type: String,
			default: 'Amet eveniet fugit ipsa vel sunt Sit obcaecati harum aut tempora ea itaque corporis? Vel <b>laboriosam aperiam</b> ipsa molestiae quibusdam Vero sunt quo fuga totam consectetur Voluptate reiciendis est iste! Lorem ipsum sit amet harum auto tempora ea itaque corporis.'
		},
		initialized: {
			type: Boolean,
			default: true
		}
	},

	data: () => ({
		animated: false
	}),

	mounted() {
		if (this.initialized)
			this.init()
	},

	methods: {
		init() {
			this.animated = true

			TweenMax.staggerFrom(this.$el.querySelectorAll('.ci-seed-germinating__animate'), 1.5, {
				x: "-=80",
				opacity: 0,
				ease: Power2.easeOut
			}, .4)

			TweenMax.staggerFrom(this.$el.querySelectorAll('.ci-seed-germinating__scale'), 1.5, {
				scale: ".9",
				opacity: 0,
				ease: Elastic.easeOut
			})
		},
	}
}
</script>

<style lang="scss">
.ci-seed-germinating{
	background: white;
	position: relative;
	padding: 80px 0;
	z-index: 10;
	overflow: hidden;
	&__container{
		@include outer-container;
	}
	&__header{
		text-align: center;
		margin-bottom: 24px;
		@include grid-container;
	}
	&__brand{
		width: 200px;
		margin: 0 auto 40px;
	}
	&__title{
		text-align: center;
		@include grid-column(6);
	}
	&__text{
		@include grid-column(6);
	}
	&__leaf,
	&__circle{
		display: none;
	}
	&__shape{
		top: -40px;
		left: 0%;
		transform: scale(.8);
		transform-origin: 0 0;
		position: absolute;
		z-index: -3;
	}

	@include grid-media($g-tablet-up) {
		padding: 150px 0;
		.ci-seed-germinating{
			&__header{
				display: flex;
				align-items: center;
				clear: both;
				margin-bottom: 40px;
				@include grid-column(6);
			}
			&__brand{
				border-right: 2px solid $t-primary;
				padding-right: 32px;
				margin-right: 32px;
				margin-bottom: 0;
			}
			&__title{
				margin: 0;
				width: auto;
				text-align: left;
			}
			&__text{
				clear: both;
				@include grid-column(6);
			}
			&__leaf,
			&__shape,
			&__circle{
				display: block;
				position: absolute;
			}
			&__shape{
				top: -120px;
				left: 54%;
				transform: translateX(-50%) scale(1.5);
				transform-origin: 0 0;
			}
			&__leaf{
				left: 47%;
				bottom: -24px;
				z-index: -1;
			}
			&__circle{
				bottom: 10%;
				left: 50%;
				z-index: -2;
			}
		}
	}
}
</style>
