<template>
	<button class="ci-button ci-text ci-text--body is-dark"
		:class="[
			`ci-button--${theme}`,
			`ci-button--${size}`,
			{}
		]"
		@click="$emit('click', $event)"
	>
		<slot></slot>
	</button>
</template>

<script>
export default {
	props: {
		theme: {
			type: String,
			default: 'accent'
		},
		size: {
			type: String,
			default: 'md' // sm | md | lg
		}
	}
}
</script>

<style lang="scss">
.ci-button{
	border-radius: 24px;
	padding: 9px 32px;
	box-sizing: border-box;
	border: 0;
	outline: none;
	cursor: pointer;
	transition: .2s $easeInOutQuad;
	&--primary{
		background: $t-primary;
		&:hover{
			background: $t-accent;
		}
	}
	&--accent{
		background: #ffad0e;
		&:hover{
			background: darken(#ffad0e, 5%);
		}
	}
	&--warn{
		background: $t-warn;
		&:hover{
			background: $t-accent;
		}
	}
	&--sm{
	}
	&--md{
		height: 50px;
	}
	&--sm{
	}
}
</style>
