<template>
  <div id="">
	<div class="blog">
		<div class="blog__container">
			<div class="blog__destaques">
				<div class="grid-container">
					<article v-for="post in highlighted" v-bind:key="post.id">						
						<router-link :to="{ name: 'post', params: { postID: post.id, postSlug: post.slug}}">
							<figure>
								<img v-bind:src="post.firstimg[0]">

								<small v-bind:class="post.cats[0].slug" class="cats" v-for="category in post.cats" v-bind:key="category.id" v-if="category.name != 'Sem categoria'">
									<router-link :to="{
											name: 'categoria', 
											params: {catID: category.cat_ID, catSlug: category.slug } 
										}
									">
										{{category.name}}
									</router-link>
								</small>
							</figure>

							<div class="body_destaques">
								<ci-text class="high__titles" type="subhead" v-html="post.title.rendered"></ci-text>
								<!-- <small v-for="t in post.TAGGING" v-bind:key="t.id">{{t.slug}}</small> -->
								<ci-text class="moment"> 
									{{post.date | moment }}
								</ci-text>
							</div>

						</router-link>
					</article>
				</div>
			
				<div class="loader loaderHigh"><img :src="publicPath + 'images/loader.svg'" alt=""></div>
			</div> <!--blog__destaques-->
			<div class="blog__postsPage">
				<div class="grid-blog">			
					<div class="blog__post_list lastOnes">				
						<ci-text class="blog__titles blog__animate subh" type="subhead">
							Últimos artigos
						</ci-text>
						<div class="containerBoxes">
							<article class="blog__post_content blog__animate" v-for="post in posts" v-bind:key="post.id">
								<router-link :to="{ name: 'post', params: { postID: post.id, postSlug: post.slug}}">
									<figure>
										<img v-bind:src="post.firstimg[0]">

										<small class="cats" v-bind:class="post.cats[0].slug" v-for="category in post.cats" v-bind:key="category.id" v-if="category.name != 'Sem categoria'">
											<router-link :to="{
													name: 'categoria', 
													params: {catID: category.cat_ID, catSlug: category.slug } 
												}
											">
												{{category.name}}
											</router-link>
										</small>
									</figure>

									<ci-text class="blog__titles" type="subhead" v-html="post.title.rendered"></ci-text>
									
									<ci-text class="moment"> 
										<i class="far fa-clock"></i>
										{{post.date | moment }}
									</ci-text>
									<ci-text class="excerpt" v-html="post.excerpt.rendered">
									</ci-text>

								</router-link>
							</article>
						</div>
						<div class="loader"><img :src="publicPath + 'images/loader.svg'" alt=""></div>
						<button @click="getPosts" class="loadMore">CARREGAR MAIS</button>

					</div>	<!-- lastOnes  -->
					
					<div class="pophigh">
						<!-- <ci-popular></ci-popular> -->
						<ci-highlight></ci-highlight>
						<ci-advertising :anuncios="anuncios"></ci-advertising>
					</div>
				</div>
			</div>
		</div>
	</div>
   
	<ci-blog-footer></ci-blog-footer>
  </div>
</template>

<script>
import moment from 'moment'
import CIText from '@/components/atoms/CIText'
// import CIPopular from '@/components/organisms/CIPopular'
import CIHighlight from '@/components/organisms/CIHighlight'
import CIAdvertising from '@/components/organisms/CIAdvertising'
import CIBlogFooter from '@/components/organisms/CIBlogFooter'
import $ from 'jquery'
import { TweenMax, Power2, Elastic } from 'gsap/TweenMax'

export default {
	components: {
		'ci-text': CIText,
		'ci-highlight': CIHighlight,
		// 'ci-popular': CIPopular,
		'ci-blog-footer': CIBlogFooter,
		'ci-advertising': CIAdvertising
	},
	props: {
		initialized: {
			type: Boolean,
		},
		anuncios: {
			
		}
	},
  	data(){
		return{
			posts: '',
			page: 5,
			highlighted: '',
			number: '',
			animated: false
		}
	},
	mounted () {
		this.getsa();
		if (this.initialized){
			this.init();
		}
		$('.loaderHigh').fadeIn();
		this.$http.get("/wp-json/wp/v2/posts?tags[]=6").then(response => {
			this.highlighted = response.data;
			$('.loaderHigh').fadeOut();
		});
	},
	watch: {
		initialized(value) {
			if (value && !this.animated)
				this.init()
		}
	},
	filters: {
		moment: function (date) {
			return moment(date).format('DD.MM.YYYY');
		}
	},
	methods: {
		getsa: function () {
			$('.loadMore').hide();
			$('.loader').fadeIn();
			this.$http.get("/wp-json/wp/v2/posts?per_page=" + this.page).then(response => {
				this.posts = response.data;
				this.number = response.headers.map["x-wp-total"];
				$('.loader').fadeOut();
				$('.loadMore').fadeIn();
				if (this.number <=  this.page ){					
					$('.loadMore').hide();
				}
			});
		},
		getPosts: function() {
			this.page = this.page + 6;
			this.getsa();			
		},
		init() {
			this.animated = true

			TweenMax.staggerFrom(this.$el.querySelectorAll('.blog__animate'), 1.5, {
				x: "-=80",
				opacity: 0,
				ease: Power2.easeOut
			}, 1.4)
		},
	}
}
</script>


<style lang="scss">

.containerBoxes{
	margin-right: -30px;
    position: relative;
    z-index: 9999;
}
.blog{
	margin-top: 30px;

	&__container{
		@include outer-container;
	}
	&__post_list{
		width: 100%;
		display: inline-flex;
		flex-wrap: wrap;
	}
	&__post_content{
		width: calc(100% - 30px);
		margin-right: 0;
		margin-bottom: 23px;
		display: inline-block;
		vertical-align: top;


		img{
			width: 100%;
			height: 210px;
			border-radius: 10px;
			object-fit: cover;
			object-position: center;
		}
		.excerpt{
			display: none;
		}
		.blog__titles{
			line-height: 130%;
			letter-spacing: 0.5px;
			margin-top: 5px;
			font-size: 20px;
		}
	}
	&__postsPage{
		@include grid-column(6);
	}
	&__destaques{		
		@include grid-column(6);

		article{
			position: relative;
			&:first-child{
				.high__titles{
					font-size: 26px!important;
				}
			}
			figure{
				width: 100%;
				height: 100%;
				overflow: hidden!important;
				border-radius: 10px;
				
				&::after{
					content: '';
					position: absolute;
					top: 0px;
					left: 0px;
					width: 100%;
					height: 99.7%;
					border-radius: 10px;
					background: rgba(0, 0, 0, 0.34);
				}
				img{
					height: 100%;
					width: 100%;
					object-fit: cover;
					object-position: center;
					border-radius: 10px;
					overflow: hidden;
				}
			}
			.body_destaques{
				position: absolute;
				color: #fff !important;
				bottom: 6%;
				left: 6%;
				width: calc(100% - 12%);

				.high__titles{
					line-height: 130%;
					letter-spacing: 0.5px;
					margin-top: 5px;
					font-size: 20px;
					text-shadow: 0px 0px 7px #0000006b;
					color: #fff !important;
				}

				.moment{
					color: #fff;
				}
			}
		}
	}
	&__post_content:nth-child(1){
		width: calc(100% - 30px);
		margin-top: 0;
		height: auto!important;

		img{
			height: auto;
		}

		.blog__titles{
			line-height: 130%;
			letter-spacing: 0.5px;
			margin-top: 20px;
			font-size: 28px;
		}
		.excerpt{
			font-family: 'montserrat';
			font-size: 14px;
			line-height: 28px;
			display: block;
			margin: 0 0px 11px 0;
			float: left;

			i{
				font-size: 12px!important;
				margin-right: 3px;
			}
		}
	}

	&__popular{
		@include grid-column(2);
	}
	
	&__titles.subh{		
		text-transform: uppercase;
		color: #7f8189;
		border-bottom: solid 1px #d8d9db;
		position: relative;
		padding-bottom: 14px;
		margin-bottom:30px;
    	width: 100%;
	}
	
	&__titles.subh::after{
		content: '';
		width: 34%;
		border-radius: 3px;
		padding: 3px;
		background: #b3c520;
		position: absolute;
		bottom: -3.5px;
		left: 0;
	}
	.loadMore{
		width: 160px;
		border-radius: 4px;
		background: #b3c520;
		color: #fff;
		letter-spacing: 2px;
		font-size: 11px;
		margin: 0 auto;
		border: none;
		padding: 11px 14px;
		display: block;
		clear: both!important;
		cursor: pointer;
		z-index: 999999;
	}
	.loader{
		text-align: center;
		width: 100%;
	}
	figure{
		margin:0;
		padding:0;
		position: relative;
		width: 100%;
		height: 100%;
	}
	
}
.grid-container {
	margin-bottom: 45px;
	display: grid;
	grid-template-columns: 100%;
	grid-gap: 17px;
	
}
.blog__post_list { 
	grid-area: listAll;
	margin-bottom: 5vh;
}
.pophigh{
	grid-area: highlights;
}
.grid-blog {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr!important;
  grid-template-rows: 1fr 1fr!important;
  grid-gap: 15px;
  grid-template-areas: "listAll listAll listAll listAll" "highlights highlights highlights highlights"!important;
}	

@include grid-media($g-desktop-up){
	.grid-blog {
		width: 100%;
		display: grid;
		grid-template-columns: 71.5% 1fr!important;
		grid-template-columns: 21.2% 21.2% 1fr 1fr!important;
		grid-template-rows: 1fr!important;
		grid-gap: 20px;
		grid-template-areas: "listAll listAll listAll highlights"!important;
	}	
}
@include grid-media($g-tablet-up){
	.blog__post_content{
		width: calc(50% - 30px);
		margin-right: 29px;
		margin-bottom: 23px;
		display: inline-block;
		vertical-align: top;
	}
	.grid-container {
		margin-bottom: 45px;
		display: grid;
		grid-template-columns: 21.2% 21.2% 1fr 1fr;
		grid-template-rows: 1fr 1fr;
		grid-gap: 17px;
		grid-template-areas: "first first seg ter" "first first quat cin";
	}

	.grid-container article:first-child { grid-area: first;}

	.grid-container article:nth-child(2) { grid-area: seg; height: 235px;}

	.grid-container article:nth-child(3) { grid-area: ter;height: 235px; }

	.grid-container article:nth-child(4) { grid-area: quat;height: 235px; }

	.grid-container article:nth-child(5) { grid-area: cin;height: 235px; }

	// ----------------------------	
	.grid-blog {
		width: 100%;
		display: grid;
		grid-template-columns: 71.5% 1fr!important;
		grid-template-columns: 21.2% 21.2% 1fr 1fr!important;
		grid-gap: 20px;
		grid-template-areas: "listAll listAll listAll highlights"!important;
	}	
}

@media(max-width: 580px){
	.blog{
		&__destaques{
			article{
				position: relative;
				&:first-child{
					.high__titles{
						font-size: 20px!important;
					}
				}
			}
		}
	}
	
}
</style>