<template>
	<div class="ci-mapping" >
		<div class="ci-mapping__background ci-mapping__animate"></div>
			<div class="ci-mapping__container">
				<div class="ci-mapping__press">
					<ci-text type="title" class="ci-mapping__bigeye ci-mapping__animate">
						<!-- Citrus na <STRONG>imprensa</STRONG> -->
						<p class="lsngs"v-html="$t('press.title')"></p>
					</ci-text>
					<ul class="ci-mapping__cases ci-mapping__animate">
						<li class="ci-mapping__clipper">
							<!-- <a href="https://exame.abril.com.br/negocios/dino/plataforma-que-melhora-a-experiencia-do-cliente-com-inteligencia-artificial-e-lancada-em-evento-em-sao-paulo/" target="_blank"> -->
								<a href="https://exame.abril.com.br/negocios/dino_old/plataforma-que-melhora-a-experiencia-do-cliente-com-inteligencia-artificial-e-lancada-em-evento-em-sao-paulo/" target="_blank"></a>
								<img :src="publicPath + 'images/exame.png'">
							</a>
						</li>
						<li class="ci-mapping__clipper">
							<a href="https://www.infomoney.com.br/negocios/noticias-corporativas/noticia/8374851/como-analisar-a-experiencia-do-cliente-pode-fazer-a-diferenca-nos-negocios-" target="_blank">
								<img :src="publicPath + 'images/infomoney.png'">
							</a>
						</li>
						<li class="ci-mapping__clipper">
							<a href="https://www.terra.com.br/noticias/dino/empresas-investem-em-experiencia-digital-para-fidelizar-e-conquistar-novos-clientes,8df5a75590e9ea8c3ad2c2c1c2e6404a5gfqdhjp.html" target="_blank">
								<img :src="publicPath + 'images/terra.png'">
							</a>
						</li>
					</ul>
				</div>
				
				<div class="ci-mapping__content ci-mapping__animate">				
					<ci-text class="ci-mapping__title" type="title" v-html="title"></ci-text>
					<ci-text class="ci-mapping__text" type="text" v-html="content"></ci-text>
					<a href="https://materiais.citrus.cx/agende-uma-demonstracao" target="_blank"><button class="ci-button ci-text ci-text--body is-dark ci-spotlight__button ci-button--accent ci-button--md" type="submit">
						{{$t('demonstracao.text')}}
					</button></a>

					<img class="ci-mapping__brand" :src="publicPath + 'images/citruscx.png'" alt="Citrus CX">
					<!--  -->

					<nav class="ci-mapping__navigation ci-mapping__animate ">
						<ci-button-icon
							v-for="item in social"
							class="ci-mapping__social"
							:name="item.icon"
							theme="primary"
							size="sm"
							@click="onSocialClick(item)"
						/>
					</nav>
					<ci-text class="ci-mapping__copyright" v-html="$t('footer.group')" />
				</div>
				
			</div>

	</div>
</template>

<script>
import CIText from '@/components/atoms/CIText'
import CIButtonIcon from '@/components/atoms/CIButtonIcon'
import { TweenMax, Power2, Elastic } from 'gsap/TweenMax'

export default {
	components: {
		'ci-text': CIText,
		'ci-button-icon': CIButtonIcon
	},
	props: {
		title: {
			type: String,
			default: ''
		},
		content: {
			type: String,
			default: ''
		},
		social: {
			type: Array,
			default: () => ([
				{
					icon: 'fab fa-instagram',
					link: 'https://www.instagram.com/citrus.cx'
				},
				{
					icon: 'fab fa-linkedin',
					link: 'https://www.linkedin.com/company/citruscx'
				},
				{
					icon: 'fab fa-facebook-f',
					link: 'https://www.facebook.com/citrus.cx/'
				},
				{
					icon: 'fab fa-twitter',
					link: 'https://twitter.com/citruscx'
				},
				{
					icon: 'fab fa-youtube',
					link: 'https://www.youtube.com/channel/UCRjhPkpLlh0S6muu8y6KlmA'
				},
			])
		}
	},

	data: () => ({
		animated: false
	}),

	mounted() {
		if (this.initialized)
			this.init()
	},

	watch: {
		initialized(value) {
			if (value && !this.animated)
				this.init()
		}
	},

	methods: {
		init() {
			this.animated = true

			TweenMax.staggerFrom(this.$el.querySelectorAll('.ci-mapping__animate'), 1.0, {
				x: "-=80",
				opacity: 0,
				ease: Power2.easeOut
			}, .4)

		},
		onSocialClick(item) {
			window.open(item.link, '_blank')
		}
	}
}
</script>

<style lang="scss">
.lsngs{
	margin: 0;
}
.ci-mapping{
	position: relative;
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	position: relative;
	transition: opacity .6s;
	z-index: 30;

	a{
		text-decoration: none;
	}
	@include grid-container;
	&__background{
	    display: none;
	}
	&__container{
		width: 100%;
		@include outer-container;
	}
	&__press{
		@include grid-column(6);
	}
	&__content{
		@include grid-column(6);
	}
	&__bigeye{
	    margin: 16px 0;
	    font-size: 34px;
	    line-height: 90%;
	    font-weight: 100;
	    float: left;
	    width: 395px;
	}
	&__title{
		font-size: 28px;
		line-height: 115%;
		font-weight: bold;
		margin-bottom: 20px;
	}
	&__clipper{
		-webkit-transition: all 0.2s;
		&:hover{
			transition: all 0.2s;
			opacity:0.8;
			transform: translateY(-5px);
		}
	}
	&__text{
		color: $c-darkgray;
	    line-height: 165%;
	    font-size: 16px;
	    font-family: 'montserrat';

	    small{
			font-size: 16px;
			line-height: 161%;
			margin: 28px 0px;
			float: left;

			span{
				padding: 9px 0;
				float: left;
				width: 100%;
			}
	    }
	}
	&__cases{
		/*display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		justify-items: center;*/
	    padding-left: 0;
	    width: 100%;
	    margin: 20px 0 96px;
	    float: left;
	    text-align: center;

		li{
			list-style: none;
			/* margin: 0 3%; */
			height: 60px;
			width: 50%;
			display: inline-flex;
			text-align: center;
			align-items: center;

			img{
				align-self: center;
				width: 87%;
			}
		}
	}

	&__brand{
	    max-width: 250px;
	    margin: 64px auto;
	    float: none;
	    text-align: center;
	    display: block;
	}
	&__social{
		margin: 0 4px;
		display: inline-flex;
	}
	&__copyright{
		margin-top: 8px;
		margin: 20px 0 40px!important;
		text-align: center;
	}
	&__bigeye{
		margin: 16px 0;
		font-size: 51px;
		line-height: 90%;
		font-weight: 100;
		float: left;

		strong{
			color: #393734;
		}
	}
	&__navigation{
		text-align: center;
	}
	&__button{
	    padding: 0 25px;
	    width: auto;
	    margin: 32px 0;
	    font-size: 13.5px;
	    height: 40px;
	    line-height: 41px;
	    letter-spacing: 1px;
	    width: 100%;
	    max-width: 303px;
	    font-weight: 600;
	}
	@include grid-media($g-tablet-up){
		.ci-mapping{
			&__background{
			    background: url(images/mappingbg.png) no-repeat;
			    position: absolute;
			    bottom: 0;
			    left: 0;
			    z-index: -2;
			    width: 36%;
			    display: block;
			    height: 568px;
			    background-position: right top;
			    background-size: cover;
			}
			&__container{
				margin-top: 95px;
			}
			&__bigeye{
				margin: 0 0 24px;
				font-size: 70px;
	    		max-width: 440px;

	    		strong{
	    			color: #393734;
	    			font-size: 80px;
	    		}
			}
			&__title{
				max-width: 440px;
				font-size: 37px;
				margin-bottom: 27px;
			}
			&__text{
			    max-width: 458px;
				font-size: 18px;
			}
			&__content{
				margin-top: 100px;
				@include grid-column(6);
				@include grid-push(5);
			}
			&__brand{				
				max-width: 275px;
				margin: 50px 0;
			}
			&__cases{
			    padding-left: 0;
			    display: -webkit-box;
			    display: -ms-flexbox;
			    display: flex;
			    -webkit-box-align: center;
			    -ms-flex-align: center;
			    align-items: start;
			    margin: 0;
			    float: none;
			    width: auto;
			    margin-top: 35px;
			    padding-left: 2%;

				li{
					list-style: none;
					margin: 0 3%;

					img{
						width: 100%;
					}
				}
			}
		}
		&__navigation{
			text-align: left;
		}
		&__copyright{
			text-align: left;
		}
	}
	
	@include grid-media($g-desktop-up) {
		.ci-mapping{
			&__copyright{

		    	margin: 20px 0 40px!important;
			}
		}
	}

	// Idle
	&.is-idle{
		opacity: 0;
	}
}
</style>
