import Vue from 'vue'
import Router from 'vue-router'
import CIHome from '@/components/pages/CIHome'
import CILanding from '@/components/pages/CILanding'
import CISuccess from '@/components/pages/CISuccess'
import CICast from '@/components/pages/CICast'	
import CISeedProgram from '@/components/pages/CISeedProgram'
// import CILemonade from '@/components/pages/CILemonade'
import CIBlog from '@/components/pages/CIBlog'
import CIPost from '@/components/pages/CIPost'
import CICategory from '@/components/pages/CICategory'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: '/',
  routes: [
  {
			path: '/ebook',
			name: 'landing',
			component: CIHome
		},
		{
			path: '/',
			name: 'home',
			component: CILanding
		},
		{
			path: '/citruscast',
			name: 'citruscast',
			component: CICast
		},
		{
			path: '/contato-sucesso',
			name: 'contato-sucesso',
			component: CISuccess
		},
		{
			path: '/contato-seed-sucesso',
			name: 'contato-seed-sucesso',
			component: CISuccess
		},
		{
			path: '/contato-lemonade-sucesso',
			name: 'contato-lemonade-sucesso',
			component: CISuccess
		},
		{
			path: '/seed',
			name: 'seed',
			component: CISeedProgram
		},
		// {
		// 	path: '/lemonade',
		// 	name: 'lemonade',
		// 	component: CILemonade
		// },
		{
			path: '/blog',
			name: 'blog',
			component: CIBlog
		},
		{
			path: '/blog/:postID/:postSlug',
			name: 'post',
			component: CIPost
		},
		{
			path: '/blog/categoria/:catID/:catSlug',
			name: 'categoria',
			component: CICategory
		}
	],
	scrollBehavior(to, from, savedPosition) {
		const container = document.querySelector('.ci-app__body')

		container.scrollTo({
			top: 0,
			behavior: 'smooth'
		})
	}
})

export default router
