<template>
    <div class="card-cast">
        <div class="card-cast-header">
            <div class="card-cast-header-img">
                <img src="../image/mic.png" alt="header-img" />
            </div>
        </div>
        <div class="card-cast-body">
            <div class="box">
                <div class="podcast-box"></div>
                <div class="podcast-link-box">
                    <a :href="data.link" class="podcast-link">
                        <img src="../image/apple.png" alt="i" />
                    </a>
                    <a :href="data.link2" class="podcast-link">
                        <img src="../image/spotify.png" alt="i" />
                    </a>
                    <a :href="data.link3" class="podcast-link">
                        <img src="../image/google.png" alt="i" />
                    </a>
                </div>
            </div>
            <div class="box">
                <div>
                    <div class="episode">#Episódio {{ data.id }}</div>
                    <div class="title">{{ data.title }}</div>
                    <div class="description">{{ data.description }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: Object
    }
}
</script>

<style lang="scss" scoped>

    .card-cast {
        background-color: #ffffff;
        width: 1000px;
        border-radius: 30px;
        box-shadow: 0 5px 5px 5px rgba(0,0,0,.1);
        overflow: hidden;
        margin: 20px;

        .card-cast-header {
            position: relative;
            background-color: #fed626;
            width: 100%;
            height: 32px;

            .card-cast-header-img {
                background-color: #fed626;
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 60px;
                height: 60px;
                border-radius: 12px;
                left: calc(50% - 30px);

                img {
                    width: 18px;
                }
            }
        }

        .card-cast-body {
            width: 100%;
            height: 350px;
            padding: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;

            @media screen and (max-width: 600px) {
                height: auto;
                flex-direction: column;
            }

            .box {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                width: 50%;
                height: 100%;

                .episode {
                    font-weight: bold;
                    font-size: 25px;
                    color: #adcf00;
                }

                .title {
                    font-weight: bold;
                    font-size: 20px;
                    color: #585858;
                }

                .description {
                    margin-top: 10px;
                    margin-bottom: 40px;
                    color: #585858;
                    font-size: 13px;
                    line-height: 18px;
                }

                .podcast-box {
                    width: 400px;
                    height: 250px;
                    border-radius: 15px;
                    background-color: #b8b8b8;

                    @media screen and (max-width: 600px) {
                        width: 95%;
                        flex-direction: column;
                    }
                }

                .podcast-link-box {
                    width: 100%;
                    height: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .podcast-link {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 25px;
                        background-color: #fed626;
                        padding: 6px 12px;
                        margin: 10px 10px 0 10px;
                        user-select: none;

                        img {
                            height: 15px;
                        }
                    }
                }

            }

        }

        @media screen and (max-width: 600px) {
            width: 95vw;
        }
    }
        
</style>