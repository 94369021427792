<template>
	<svg
		class="ci-circle"
		:class="[
			`ci-circle--${theme}`,
		]"
		width="952"
		height="952"
		viewBox="0 0 952 952"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M476 952C738.888 952 952 738.888 952 476C952 213.112 738.888 0 476 0C213.112 0 0 213.112 0 476C0 738.888 213.112 952 476 952ZM476 805C657.702 805 805 657.702 805 476C805 294.298 657.702 147 476 147C294.298 147 147 294.298 147 476C147 657.702 294.298 805 476 805Z" fill="transparent"/>
	</svg>
</template>

<script>
export default {
	props: {
		theme: {
			type: String,
			default: 'primary'
		},
	}
}
</script>

<style lang="scss">
.ci-circle{
	&--primary{
		path {
			fill: $t-primary;
		}
	}
	&--accent{
		path {
			fill: $t-accent;
		}
	}
	&--warn{
		path {
			fill: $t-warn;
		}
	}
}
</style>
