<template>
	<div class="ci-seed-planting"
		:class="[
			{
				'is-idle': !initialized && !animated
			}
		]"
	>
		<div class="ci-seed-planting__container">
			<ci-text class="ci-seed-planting__title ci-seed-planting__animate" type="title" :dark="true">
				{{$t('plantingTitle')}}
			</ci-text>

			<ci-text
				class="ci-seed-planting__text ci-seed-planting__animate"
				type="lead"
				:dark="true"
				v-html="$t('plantingContent')"
			/>
		</div>

		<img
			class="ci-seed-planting__shape"
			:src="publicPath + 'images/shape-planting.svg'"
		>
		<img
			class="ci-seed-planting__lines"
			:src="publicPath + 'images/planting-lines.svg'"
		>
		<img
			class="ci-seed-planting__clouds"
			:src="publicPath + 'images/planting-clouds.svg'"
		>
	</div>
</template>

<script>
import CIText from '@/components/atoms/CIText'
import { TweenMax, Power2, Elastic } from 'gsap/TweenMax'

export default {
	components: {
		'ci-text': CIText,
	},

	props: {
		title: {
			type: String,
			default: 'Optio voluptatis Nihil voluptatum'
		},
		content: {
			type: String,
			default: 'Sit saepe ut eos minus ex, optio voluptatibus Nihil voluptatum quisquam accusamus eius odit Quos iure illum culpa consequuntur repellat Culpa ex explicabo temporibus quisquam officiis, ex, ea Eius commodi. Consectetur error cum illum culpa vitae ipsam? Ducimus aliquam deserunt quaerat dolorem ex? Totam similique quae animi necessitatibus natus perspiciatis vel iusto delectus? Corporis officiis laborum necessitatibus officiis sint error!'
		},
		initialized: {
			type: Boolean,
			default: true
		}
	},

	data: () => ({
		animated: false
	}),

	mounted() {
		if (this.initialized)
			this.init()
	},

	watch: {
		initialized(value) {
			if (value && !this.animated)
				this.init()
		}
	},

	methods: {
		init() {
			this.animated = true

			TweenMax.staggerFrom(this.$el.querySelectorAll('.ci-seed-planting__animate'), 1.5, {
				x: "-=80",
				opacity: 0,
				ease: Power2.easeOut
			}, .4)
		},
	}
}
</script>

<style lang="scss">
.ci-seed-planting{
	position: relative;
	padding: 80px 0 440px;
	overflow: hidden;
	z-index: 20;
	@include grid-container;
	&:before{
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		bottom: 200px;
		width: 100%;
		background: #7fca1f;
		z-index: -2;
	}
	&__container{
		transition: opacity .6s;
		@include outer-container;
	}
	&__title{
		margin-bottom: 24px;
		@include grid-column(6);
	}
	&__text{
		@include grid-column(6);
	}
	&__shape{
		position: absolute;
		min-width: 100%;
		left: 50%;
		bottom: 0;
		transform: translateX(-50%);
		z-index: -1;
	}
	&__lines{
		position: absolute;
		right: 0;
		width: 100%;
		min-width: 1000px;
		bottom: 180px;
		margin-right: -180px;
	}
	&__clouds{
		position: absolute;
		bottom: 300px;
		left: 50%;
		width: 200px;
		margin-left: -100px;
		animation-name: clouds;
		animation-duration: 5s;
		animation-iteration-count: infinite;
	}

	@include grid-media($g-tablet-up) {
		padding-bottom: 300px;
		.ci-seed-planting{
			&__title{
				clear: both;
				@include grid-column(6);
			}
			&__text{
				clear: both;
				@include grid-column(6);
			}
			&__lines{
				width: 240%;
				margin-right: -450px;
				max-width: 2400px;
				bottom: 140px;
			}
			&__clouds{
				width: 30%;
				max-width: 500px;
				min-width: 200px;
				right: 80px;
				left: initial;
				margin: 0;
				bottom: 450px;
			}
		}
	}

	@include grid-media($g-desktop-up) {
		.ci-seed-planting{
			&__lines{
				left: 50%;
				transform: translateX(-50%);
				bottom: 200px;
			}
			&__clouds{
				bottom: 500px;
				left: 60%;
			}
		}
	}

	// Idle
	&.is-idle{
		.ci-seed-planting{
			&__container{
				opacity: 0;
			}
		}
	}

	@keyframes clouds {
		0% {
			transform: translateY(0px);
		}
		50% {
			transform: translateY(-5px);
		}
		100% {
			transform: translateY(0px);
		}
	}
}
</style>
