/*Tradução do site, aqui é criada a parte PT-BR*/

export default {
  pt: {
    menu: {
      blog: "Blog",
      home: "Home",
      produtos: "Produtos",
      citrusseedprogram: "Citrus Seed Program",
      contato: "Contato",
      digivox: "Digivox",
      contedo: "Conteúdo",
    },
    about: {
      subhead: "Sobre o Citrus",
      title:
        "Um ecossistema de possibilidades que deve ser sentido e experimentado",
      content: `
				<p>Podemos registrar cada informação gerada, cada ponto de contato, por meio de qualquer canal de atendimento, e por isso temos dados suficientes para personalizar a experiência do seu cliente. Entender o contexto de cada pessoa e como ela segue sua jornada enquanto consumidor é o grande diferencial para as empresas que querem oferecer o melhor atendimento e crescer em produtividade e vendas.</p>
				<p>É com a ideia de desfrutar desse mundo de possibilidades que surge o Citrus, uma plataforma de experiência do cliente, e colaboração, que pensa em cada detalhe dessa jornada. Que pensa no cliente como indivíduo único, com todas as suas particularidades que o distinguem dos outros. Cada cliente possui uma jornada singular, e ela deve ser analisada e tratada de forma humanizada e personalizada.</p>
			`,
    },
    solution: {
      title: "Soluções para todos os tipos de mercados",
      content:
        "O Citrus se adapta ao seu tipo de negócio e oferece a solução que você precisa para atender seus clientes.",
      items: {
        financeiro: {
          title: "Financeiro",
          content: "Bancos, fintechs e demais instituições financeiras",
        },
        governo: {
          title: "Governo",
          content: "Empresas públicas e setores governamentais",
        },
        varejo: {
          title: "Varejo",
          content: "Comércio de produtos ou serviços",
        },
        educacao: {
          title: "Educação",
          content: "Escolas, faculdades e cursos de qualquer natureza",
        },
        saude: {
          title: "Saúde",
          content: "Hospitais, clínicas, laboratórios e planos de saúde",
        },
        coworking: {
          title: "Coworking",
          content: "Espaços de trabalho compartilhados",
        },
        corporativo: {
          title: "Corporativo",
          content: "Soluções para empresas de médio e grande porte",
        },
        utilities: {
          title: "Utilities",
          content: "Empresas públicas e serviços para a sociedade",
        },
        telecom: {
          title: "Telecom",
          content: "Operadoras, provedores, distribuidores e revendedores",
        },
      },
    },
    suite: {
      genesys:
        "O Ecossistema Citrus se integra com suítes de comunicação do mercado, como o Unity, da Digivox e plataformas omnichannel. Se sua empresa já possui uma solução de comunicação, ou outros canais de mensagens instalados, também pode se integrar com as aplicações da família Citrus de Experiência do Cliente.",
      content:
        "O Citrus possui uma suíte de comunicação Omnichannel que unifica todos os canais de atendimento com o seu cliente e mapeia a sua jornada, recebendo, registrando e documentando cada ponto de interação.",
    },
    apps: {
      content:
        "Camada de aplicações nativas que irão fluir sobre o ecossistema Citrus, a fim de personalizar e aperfeiçoar ainda mais a experiência e engajamento do cliente, e a colaboração do time que trabalha para oferecer o melhor atendimento.",
      "typo-lemon-desk": {
        name: "Lemon Desk",
        descri: "",
        content:
          "A aplicação de gestão de chamados com monitoramento operacional ativo, suporte e backoffice do Citrus é a solução que sua equipe precisa para responder seu cliente de maneira eficaz, mais rápido que fazer uma limonada. Com o LemonDesk você oferece um atendimento dedicado e mantém um saboroso relacionamento com ele.",
      },
      "typo-lime-sales": {
        name: "Lime Sales",
        descri: "",
        content:
          "Sistema de automação de força de vendas e insight sales do Citrus. O verde que sua equipe espera para aumentar a taxa de conversão e interagir de maneira mais inteligente e eficaz com os leads gerados. Tudo integrado ao ecossistema Citrus de experiência e colaboração.",
      },
      apis: {
        name: "apis",
        descri: "",
        content:
          "O Ecossistema Citrus também permite a integração de APPs e ERPs já existentes nos negócios dos clientes, numa perfeita harmonia com suas aplicações nativas.",
      },
      "typo-citrus-brain": {
        name: "",
        descri: "Inteligência Artificial, Machine Learning e CX Analytics",
        content: `O grande cérebro desse ecossistema trabalha para detectar todos os padrões de linguagens, comportamentos e sentimentos que passam pelo Citrus. O sistema de Inteligência Artificial e Machine Learning do Citrus transcreve e analisa contextos de conversas, aprendendo com cada caso particular para atuar na personalização das jornadas dos clientes.

				Com todos os dados coletados e analisados, nossa ferramenta de Analytics e Business Intelligence tem a tarefa de identificar personas, prever necessidades, antecipar soluções de problemas e gerar insights para novos negócios, baseada no contexto de cada jornada. 
				
				Através de padrões cognitivos, os bots especialistas circulam ao redor do brain, consumindo inteligência para atuar dentro de cada contexto, analisando e tratando cada expressão e sentimento captado. Tudo numa perfeita sintonia, atuando lado a lado, com o atendimento humano.`,
      },
      "typo-pomelo-flow-1": {
        name: "",
        descri: "Plataforma Omnichannel para Contact Center",
        content: `Harmoniza os fluxos de comunicação, atividades e processos de maneira mais suave, melhorando a experiência através de qualquer ponto de contato e reduzindo os impactos no trabalho. Todas as interações e eventos fluem como rio pela tela do Citrus, trazendo mais eficiência e benefícios reais, como aumento de rentabilidade e satisfação do cliente.

				A plataforma para gerenciamento de contact center omnichannel do Citrus oferece uma experiência mais leve para a sua equipe de atendimento. Roteamento inteligente de chamadas, análise de produtividade e performance e uma visão completa do engajamento com o cliente, tudo através de um dashboard de monitoramento e gerador de métricas em tempo real, além de relatórios de fácil visualização. 
				`,
      },
    },
    lemonade: {
      hero: ["A Experiência", "do Cliente como", "você nunca viu."],
      about: "Sobre o",
    },
    seed: {
      transform: {
        title: ["Transformando", "Limões em", "Limonada"],
      },
      swc: {
        title: "Sowers Certification (SWC)",
        subtitle: "Certificação para empresas parceiras Citrus",
        content:
          "Se você tem uma empresa e acredita que pode crescer junto com o Citrus firmando parcerias, atraindo clientes e plantando novas sementes para o nosso ecossistema, temos uma outra certificação que se enquadra melhor com o seu perfil. Seja um “Sower” e semeie essa experiência.",
      },
      sdc: {
        title: "Seeds Certification (SDC)",
        subtitle: "Certificação para consultores Citrus",
        content:
          "Contamos com pessoas capacitadas e alinhadas com nossa mensagem, os nossos “Seeds”, que irão passar por níveis dentro do programa. Cada parceiro nessa certificação será um consultor habilitado para transmitir o conceito do Citrus para o mercado, a fim de atrair prospecção e vendas. Serão como sementes plantadas para germinar, crescer e gerar frutos.",
      },
    },
    contact: {
      name: "Nome",
      email: "E-mail",
      company: "Empresa",
      phone: "Telefone",
      subject: "Assunto",
      message: "Mensagem",
      submit: "Enviar",
      sending: "Enviando",
    },
    footer: {
      group: `Uma empresa do grupo <a href="https://digivox.com.br/" target="_blank">Digivox</a>`,
    },
    hero: {
      url: "/wp-content/themes/citrus-cx/dist/images/citrus-screenpt.png",
      title:
        "A jornada do seu cliente personalizada e com muito mais sentimento",
      content:
        "Ele desfrutará da melhor e mais refrescante experiência com a sua marca",
      /*content: 'Ele desfrutará da melhor e mais refrescante experiência com a sua marca'*/
    },
    alertCookie: {
      mainMessage:
        "Nós ultilizamos cookies e tecnologias semelhantes para melhorar a sua experiência com os nossos serviços, a fim de personalizar as publicidades e recomendar conteúdo de seu interesse, seguindo a nossa",
      privacyPolicy: "Política de Privacidade.",
      toAgree:
        "Ao continuar navegando nesse site, você concorda com tal monitoramento.",
    },
    press: {
      title: "Citrus na <STRONG>imprensa</STRONG>",
    },
    demonstracao: {
      text: "AGENDE UMA DEMONSTRAÇÃO",
    },
    book: {
      text: "BAIXE NOSSO E-BOOK AGORA",
    },
    ftCopy: {
      rights: "Todos os direitos reservados",
    },
    squad: {
      donwloadArea: {
        title: "Baixa agora",
        subtitle: "um módulo de Citrus",
        textBtn: "BAIXE O CITRUS SQUAD",
      },
      content:
        "Ferramenta de colaboração que facilita a comunicação entre equipes internas e externas. Voz, vídeo, mensagens e conferência em uma mesma plataforma, contribuindo para que sua equipe não perca um ponto sequer das jornadas dos seus clientes.",
      features: {
        comunicacao_unificada: {
          title: "Comunicação Unificada",
          voz: {
            image: "https://citrus.cx/wp-content/uploads/2019/06/icon-mic.png",
            label: "Voz",
          },
          video: {
            image:
              "https://citrus.cx/wp-content/uploads/2019/06/icon-video.png",
            label: "Vídeo",
          },
          mensagem: {
            image:
              "https://citrus.cx/wp-content/uploads/2019/06/icon-message.png",
            label: "Mensagem",
          },
        },
        colaboracao: {
          title: "Colaboração",
          audio_videoconferencia: {
            image:
              "https://citrus.cx/wp-content/uploads/2019/06/icon-conference.png",
            label: "Áudio e videoconferência",
          },
          gerenciamento_documentos: {
            image:
              "https://citrus.cx/wp-content/uploads/2019/06/icon-document.png",
            label: "Gerenciamento de documentos",
          },
          calendario: {
            image:
              "https://citrus.cx/wp-content/uploads/2019/06/icon-calendar.png",
            label: "Calendário",
          },
        },
      },
    },
    tryOut: {
      title: "Faça parte dessa jornada!",
      content:
        "Se você quer fazer parte do Ecossistema Citrus de experiência do cliente e colaboração, entre em contato com a gente e tire suas dúvidas.",
    },

    /* página seed */
    germinatingTitle: "Programa de germinação de sementes do Citrus",
    germinatingContent:
      "O Ecossistema Citrus de Experiência do Cliente tem um programa para consultores e empresas que queiram fazer parte dessa jornada e levar junto esse conceito que encanta por onde passa. No Citrus Seed Program, nós plantamos sementes, cuidamos para que elas germinem e se tornem boas plantas e árvores frutíferas. Você pode ser uma semente nesse cenário e crescer junto com o Citrus.",

    plantingTitle: "Plantando sementes e colhendo frutos",
    plantingContent:
      'Com a finalidade de levar essa inovadora experiência para o mercado e atrair empresas parceiras e consultores que se interessem em desfrutar desse ecossistema, surge o "Citrus Seed", o programa de germinação de sementes do Citrus. O programa se divide em duas certificações que vão levar você e sua empresa para os melhores resultados nessa jornada',

    transformContent: `
		<p>Enxergamos os problemas de cada empresa como limões, e a resolução deles como uma refrescante limonada. O ecossistema Citrus de Experiência do Cliente é um mundo de possibilidades e soluções para empresas interessadas em melhorar o atendimento aos seus clientes e transformar esses limões em limonada. Cada cliente tem uma jornada única e ela deve ser analisada e tratada de forma humanizada e personalizada.</p>
		<p>Para a implementação da plataforma, desenvolvemos uma metodologia exclusiva apoiada no Design Thinking e em metodologias ágeis, como o SCRUM. De maneira rápida e criativa coletamos todos os limões do atendimento da empresa, para que possamos, juntos, fazer uma saborosa limonada com o Citrus.</p>
		<p>Estamos falando do Citrus Mapping. A metodologia usada por nossos consultores para otimizar os processos mais problemáticos das empresas e eliminar seus maiores gargalos, com técnicas de mapeamento das jornadas.</p>
		`,
    channels: {
      voice: {
        icon: "fas fa-microphone",
        title: "Voz",
        content: "Todos as chamadas recebidas pelo seu contact center",
      },
      whatsapp: {
        icon: "fab fa-whatsapp-square",
        title: "WhatsApp",
        content: "Não perca nenhum contato do seu cliente",
      },
      chat: {
        icon: "fas fa-comments",
        title: "Chat",
        content: "Você tem um webchat no seu site? Registre todos os contatos",
      },
      socialMedia: {
        icon: "fas fa-share-alt",
        title: "Social Media",
        content: "Interações que sua empresa recebe nas redes sociais",
      },
      sms: {
        icon: "fas fa-sms",
        title: "SMS",
        content: "Campanhas de SMS para uma comunicação mais rápida",
      },
      webForm: {
        icon: "fas fa-mouse-pointer",
        title: "Formulário Web",
        content: "Formulários e demais interações feitas através do site",
      },
      video: {
        icon: "fas fa-video",
        title: "Vídeo",
        content: "A forma de comunicação mais eficiente na era mobile",
      },
      apis: {
        icon: "fas fa-laptop-code",
        title: "APIs",
        content: "Integração com APIs externas de sistemas proprietários",
      },
      email: {
        icon: "fas fa-envelope-open-text",
        title: "E-mail",
        content: "O meio de comunicação que não perde o seu charme",
      },
      presential: {
        icon: "fas fa-user",
        title: "Presencial",
        content: "Não ignoramos as interações físicas feitas com a sua marca",
      },
      contactCenter: {
        icon: "fas fa-headset",
        title: "Contact Center",
        content: "Sua equipe de atendimento mais conectada",
      },
      ura: {
        icon: "fas fa-phone-volume",
        title: "URA",
        content: "Automação de atendimentos com suporte a navegação visual",
      },
    },
  },

  /*Tradução do site, aqui é criada a parte Inglês*/

  en: {
    menu: {
      blog: "Blog",
      home: "Home",
      produtos: "Products",
      citrusseedprogram: "Citrus Seed Program",
      contato: "Contact",
      digivox: "Digivox",
      contedo: "Content",
    },
    about: {
      subhead: "About Citrus",
      title: "An ecosystem of possibilities that must be felt and experienced",
      content: `
				<p>We can record each information generated and point of contact through any service channel, therefore we have enough data to personalize your customer's experience. Understanding the context of each person and how they follow their journey as a consumer is the great differential for companies that want to offer the best service and grow in productivity and sales.</p>
				<p>Citrus arises from the idea of ​​enjoying this world of possibilities. It’s a platform for customer experience and collaboration that perceives the customer as a unique individual, with all their particularities that distinguish them from others. Each client has a unique journey and it must be analyzed and treated in a humanized and personalized way.</p>
			`,
    },
    solution: {
      title: "Solutions for all types of markets",
      content:
        "Citrus adapts to your type of business and offers the solution you need to assist your customers.",
      items: {
        financeiro: {
          title: "Financial",
          content: "Banks, fintechs and other financial institutions",
        },
        governo: {
          title: "Government",
          content: "Public companies and government sectors",
        },
        varejo: {
          title: "Retail",
          content: "Trade in products or services",
        },
        educacao: {
          title: "Education",
          content: "Schools, colleges and courses of any kind",
        },
        saude: {
          title: "Health",
          content: "Hospitals, clinics, laboratories and health plans",
        },
        coworking: {
          title: "Coworking",
          content: "Shared workspaces",
        },
        corporativo: {
          title: "Corporate",
          content: "Solutions for medium and large companies",
        },
        utilities: {
          title: "Utilities",
          content: "Public companies and social services",
        },
        telecom: {
          title: "Telecom",
          content: "Operators, providers, distributors and resellers",
        },
      },
    },
    suite: {
      genesys:
        'You can integrate Citrus Ecosystem with any cloud communication solution available on the market. Platforms such as Unity by Digivox already have native plugins and APIs, and no extra effort for integration is needed. Even if your company still have an "On-Prem" communication solution or other message channels installed, you can also integrate with any of the Customer Experience Citrus’ APPs.',
      content:
        "Citrus has an Omnichannel communication suite that unifies all customer service channels and maps their journey, receiving, recording and documenting each point of interaction.",
    },
    apps: {
      content:
        "Layer of native applications that will flow over the Citrus ecosystem, in order to personalize and further improve the customer experience and engagement, and the collaboration of the team that works to offer the best service.",
      "typo-lemon-desk": {
        name: "Lemon Desk",
        descri: "",
        content: `Citrus’ call management application with active operational monitoring, support and backoffice is the solution your team needs to respond to your customer effectively, faster than making lemonade. With LemonDesk you offer a dedicated service to your customer and keep a tasty relationship with them.`,
      },
      "typo-lime-sales": {
        name: "",
        descri: "",
        content: `Citrus’ sales force automation and insight sales system. The green that your team expects to increase your conversion rate and interact more intelligently and effectively with the generated leads, all integrated into the Citrus ecosystem of experience and collaboration.`,
      },
      apis: {
        name: "",
        descri: "",
        content: `Citrus Ecosystem also allows the integration of APPs and ERPs already existing in the customers' businesses, in perfect harmony with their native applications.`,
      },
      "typo-citrus-brain": {
        name: "",
        descri: "Artificial Intelligence, Machine Learning and CX Analytics",
        content: `The big brain of this ecosystem works to detect all patterns of languages, behaviors and feelings that pass through Citrus. Citrus 'Artificial Intelligence and Machine Learning system transcribes and analyzes contexts of conversations, learning from each particular case to act in the personalization of clients' journeys.
				
				With all the data collected and analyzed, our Analytics and Business Intelligence tool has the task of identifying personas, predicting needs, anticipating problem solutions and generating insights for new businesses,
				
				Through cognitive patterns, specialist bots circulate around the brain, consuming intelligence to act within each context, analyzing and treating each expression and feeling captured. Everything in perfect harmony, working side by side with human service.
`,
      },
      "typo-pomelo-flow-1": {
        name: "",
        descri: "Omnichannel Platform for Contact Center",
        content: `Harmonizes communication flows, activities and processes in a smoother way, improving the experience through any point of contact and reducing impacts at work. All interactions and events flow like a river through the Citrus screen, bringing more efficiency and real benefits, such as increased profitability and customer satisfaction.
				
				Citrus' omnichannel contact center management platform offers a lighter experience for your service team. Intelligent call routing, productivity and performance analysis and a complete view of customer engagement, all through a real-time monitoring dashboard and metrics generator, as well as easy-to-view reports.`,
      },
    },
    lemonade: {
      hero: ["Customer experience", `as like you've never`, "seen before."],
      about: "About the",
    },
    seed: {
      transform: {
        title: ["Transforming", "Lemons into", "Lemonade"],
      },
      swc: {
        title: "Sowers Certification (SWC)",
        subtitle: "Citrus’ certification for partner companies",
        content:
          "If you have a company and believe that you can grow together with Citrus by signing partnerships, attracting customers and planting new seeds for our ecosystem, we have another certification that fits your profile better. Be a “Sower” and sow that experience.",
      },
      sdc: {
        title: "Seeds Certification (SDC)",
        subtitle: "Certification for Citrus consultants",
        content:
          "We have trained people aligned with our message, our “Seeds”, who will go through levels within the program. Each partner in this certification will be a qualified consultant to transmit the concept of Citrus to the market, in order to attract prospecting and sales. They will be like seeds planted to germinate, grow and bear fruit.",
      },
    },
    contact: {
      name: "Name",
      email: "E-mail",
      company: "Company",
      phone: "Phone",
      subject: "Subject",
      message: "Message",
      submit: "Submit",
      sending: "Sending",
    },
    footer: {
      group: `A <a href="https://digivox.com.br/" target="_blank">Digivox</a> Business`,
    },
    hero: {
      url: "/wp-content/themes/citrus-cx/dist/images/citrus-screeneng.png",
      title: "Your customer’s journey, personalized and with much more feeling",
      content:
        "They’ll enjoy the best and most refreshing experience with your brand",
    },
    alertCookie: {
      mainMessage:
        "Nós ultilizamos cookies e tecnologias semelhantes para melhorar a sua experiência com os nossos serviços, a fim de personalizar as publicidades e recomendar conteúdo de seu interesse, seguindo a nossa",
      privacyPolicy: "Política de Privacidade.",
      toAgree:
        "Ao continuar navegando nesse site, você concorda com tal monitoramento.",
    },
    press: {
      title: "Citrus in <STRONG>the Media</STRONG>",
    },
    demonstracao: {
      text: "SCHEDULE A DEMONSTRATION",
    },
    book: {
      text: "DOWNLOAD OUR E-BOOK NOW",
    },
    ftCopy: {
      rights: "All rights reserved",
    },
    squad: {
      donwloadArea: {
        title: "Donwload",
        subtitle: "a Citrus module now",
        textBtn: "DOWNLOAD CITRUS SQUAD",
      },
      content:
        "Collaboration tool that facilitates communication between internal and external teams. Voice, video, messages and conferencing on the same platform, helping so that your team doesn't miss a single point of your customers' journeys.",
      features: {
        comunicacao_unificada: {
          title: "Unified Communications",
          voz: {
            image: "https://citrus.cx/wp-content/uploads/2019/06/icon-mic.png",
            label: "Voice",
          },
          video: {
            image:
              "https://citrus.cx/wp-content/uploads/2019/06/icon-video.png",
            label: "Video",
          },
          mensagem: {
            image:
              "https://citrus.cx/wp-content/uploads/2019/06/icon-message.png",
            label: "Message",
          },
        },
        colaboracao: {
          title: "Collaboration",
          audio_videoconferencia: {
            image:
              "https://citrus.cx/wp-content/uploads/2019/06/icon-conference.png",
            label: "Audio and videoconference",
          },
          gerenciamento_documentos: {
            image:
              "https://citrus.cx/wp-content/uploads/2019/06/icon-document.png",
            label: "Document management",
          },
          calendario: {
            image:
              "https://citrus.cx/wp-content/uploads/2019/06/icon-calendar.png",
            label: "Calendar",
          },
        },
      },
    },
    tryOut: {
      title: "Be part of this journey!",
      content:
        "If you want to be part of the Citrus Ecosystem of customer experience and collaboration, contact us and ask your questions.",
    },

    /* tradução página seed */
    germinatingTitle: "Citrus seed germination program",
    germinatingContent:
      "The Citrus Ecosystem of Customer Experience has a program for consultants and companies that want to be part of this journey and adopt this enchanting program. In the Citrus Seed Program we plant seeds, take care that they germinate and become grown plants and fruit trees. You can be a seed in this scenario and grow with Citrus.",

    plantingTitle: "Planting seeds and reaping fruits",
    plantingContent:
      'In order to take this innovative experience to the market and attract partner companies and consultants interested in enjoying this ecosystem, we created the "Citrus Seed", Citrus seed germination program. The program is divided into two certifications, which will take you and your company to the best results in this journey.',

    transformContent: `
		<p>We see each company's problems as lemons and solving them, as a refreshing lemonade. The Citrus Customer Experience ecosystem is a world of possibilities and solutions for companies interested in improving customer service and transforming these lemons into lemonade. Each client has a unique journey and it must be analyzed and treated in a humanized and personalized way.</p>
		<p>For the implementation of the platform, we developed an exclusive methodology based on Design Thinking and agile methodologies, such as SCRUM. We quickly and creatively collect all lemons from the company's service, so that together we can make a tasty lemonade with Citrus.</p>
		<p>We’re talking about Citrus Mapping. The methodology used by our consultants to optimize the most problematic processes of the companies and eliminate their biggest bottlenecks, with techniques for mapping the journeys.</p>
		`,
    channels: {
      voice: {
        icon: "fas fa-microphone",
        title: "Voice",
        content: "All calls received by your contact center",
      },
      whatsapp: {
        icon: "fab fa-whatsapp-square",
        title: "WhatsApp",
        content: "Don't miss any contact from your customer",
      },
      chat: {
        icon: "fas fa-comments",
        title: "Chat",
        content: "Do you have a webchat on your website? Record all contacts",
      },
      socialMedia: {
        icon: "fas fa-share-alt",
        title: "Social Media",
        content: "Social interactions your company receives on social media",
      },
      sms: {
        icon: "fas fa-sms",
        title: "SMS",
        content: "SMS campaigns for faster communication",
      },
      webForm: {
        icon: "fas fa-mouse-pointer",
        title: "Web Form",
        content: "Forms and other interactions made through the website",
      },
      video: {
        icon: "fas fa-video",
        title: "Vídeo",
        content: "The most efficient form of communication in the mobile era",
      },
      apis: {
        icon: "fas fa-laptop-code",
        title: "APIs",
        content: "Integration with external APIs of proprietary systems",
      },
      email: {
        icon: "fas fa-envelope-open-text",
        title: "E-mail",
        content: "The communication tool that doesn’t lose its charm",
      },
      presential: {
        icon: "fas fa-user",
        title: "Presential",
        content:
          "We don’t ignore the physical interactions made with your brand",
      },
      contactCenter: {
        icon: "fas fa-headset",
        title: "Contact Center",
        content: "Your service team even more connected",
      },
      ura: {
        icon: "fas fa-phone-volume",
        title: "IRV",
        content: "Service automation with visual navigation support",
      },
    },
  },
};
