var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ci-app",attrs:{"id":"app"}},[_c('transition',{attrs:{"name":"loading"}},[(
          _vm.loadingState != 'hide' &&
          (_vm.$route.name == 'landing' ||
            _vm.$route.name == 'home' ||
            _vm.$route.name == 'seed' ||
            _vm.$route.name == 'lemonade')
        )?_c('ci-loading',{staticClass:"ci-app__loading",attrs:{"state":_vm.loadingState}}):_vm._e()],1),(_vm.initialized)?_c('ci-header',{staticClass:"ci-app__header",attrs:{"navigation":_vm.menu,"selected":_vm.selected,"lang":_vm.lang},on:{"update:lang":_vm.onLangChange,"update:selected":_vm.onNavigationChange,"menu":function($event){_vm.sidenav = true}}}):_vm._e(),_c('div',{staticClass:"ci-app__alerts"},[_c('transition',{attrs:{"name":"alert"}},[(_vm.alert)?_c('ci-alert',{staticClass:"ci-app__alert",attrs:{"type":_vm.alert.type}},[_vm._v("\n          "+_vm._s(_vm.alert.message)+"\n        ")]):_vm._e()],1)],1),_c('div',{staticClass:"ci-app__body"},[_c('transition',{attrs:{"name":"router","mode":"out-in"}},[(_vm.initialized)?_c('router-view',{key:_vm.$route.path,staticClass:"ci-app__view",attrs:{"scroll":_vm.scroll,"selected":_vm.selected}}):_vm._e()],1),(
          _vm.initialized &&
          this.$route.name !== 'blog' &&
          this.$route.name !== 'post' &&
          this.$route.name !== 'categoria'
        )?_c('ci-footer',{staticClass:"ci-app__footer",attrs:{"social":_vm.contact.social}}):_vm._e()],1),_c('ci-aside',{staticClass:"ci-app__aside",class:{ 'is-active': _vm.sidenav },attrs:{"selected":_vm.selected,"navigation":_vm.menu},on:{"update:selected":_vm.onNavigationChange,"close":_vm.onSidenavClose}}),_c('transition',{attrs:{"name":"fade"}},[(
          _vm.sidenav &&
          this.$route.name !== 'blog' &&
          this.$route.name !== 'post' &&
          this.$route.name !== 'categoria'
        )?_c('div',{staticClass:"ci-app__overlay",on:{"click":function($event){_vm.sidenav = false}}}):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }