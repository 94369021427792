<template>
    <div class="page-cast">
        <Content01 />
        <Content02 />
        <Content03 />
        <Content04 />
    </div>
</template>

<script>
import Content01 from './components/Content-01'
import Content02 from './components/Content-02'
import Content03 from './components/Content-03'
import Content04 from './components/Content-04'

export default {
    components: {
        Content01, Content02, Content03, Content04
    }
}
</script>

<style lang="scss">
    .page-cast {
        @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
        font-family: 'Montserrat', sans-serif;
    }
</style>