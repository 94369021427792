<template>
	<div class="ci-channel">
		<span class="ci-channel__icon"
			:class="[
				icon
			]"
		>
		</span>
		<ci-text class="ci-channel__title" type="lead" :bold="true" theme="primary">
			{{ title }}
		</ci-text>
		<ci-text class="ci-channel__text" type="small">
			{{ content }}
		</ci-text>
	</div>
</template>

<script>
import CIText from '@/components/atoms/CIText'

export default {
	components: {
		'ci-text': CIText
	},
	props: {
		icon: {
			type: String,
			default: 'play'
		},
		title: {
			type: String,
			default: 'Lorem ipsum'
		},
		content: {
			type: String,
			default: 'Donec viverra volutpat congue. Sed lacinia, magna id pellentesque.'
		}
	}
}
</script>

<style lang="scss">
.ci-channel{
	padding: 40px 30px;
	background: white;
	border-radius: 8px;
	text-align: center;
	transition: box-shadow .3s $easeInOutQuad;
	box-sizing: border-box;
	&__icon{
		transition: .3s $easeInOutQuad;
		font-size: 28px;
		color: $c-darkgray;
	}
	&__title{
		margin: 16px 0;
	}
	&__text{
	}

	@include grid-media($g-desktop-up){
		&:hover{
			box-shadow: 0 0 32px rgba($t-primary, .5);
			.ci-channel{
				&__icon{
					color: $t-primary;
				}
			}
		}
	}
}
</style>
