<template>
	<ci-seed-program
		:germinating-title="seed.title"
		:germinating-content="seed.content"
		:planting-title="seed.planting_title"
		:planting-content="seed.planting_content"
		:sdc-title="seed.sdc_title"
		:sdc-subhead="seed.sdc_subhead"
		:sdc-content="seed.sdc_content"
		:sdc-items="seed.sdc_items"
		:swc-title="seed.swc_title"
		:swc-subhead="seed.swc_subhead"
		:swc-content="seed.swc_content"
		:swc-items="seed.swc_items"
		:transform-content="seed.transform_content"
		:transform-video="seed.transform_video"
		:transform-video-thumbnail="seed.transform_video_thumbnail"
		:contact-title="seed.contact_title"
		:contact-content="seed.contact_content"
		v-bind="$attrs"
		@contact="onContact"
	/>
</template>

<script>
import CISeedProgram from '@/components/templates/CISeedProgram'
import { mapGetters } from 'vuex'

export default {
	components: {
		'ci-seed-program': CISeedProgram
	},

	computed: {
		...mapGetters({
			seed: 'page/getSeed'
		})
	},

	methods: {
	  onContact(component) {
			component.state = 'sending'

			this.$store.dispatch('contact/sendSeed', component.form)
				.then(message => {
          this.$store.dispatch('alert/add', {
            message: message,
						type: 'success'
          })

					// Redirect to success page
					this.$router.push({ name: 'contato-seed-sucesso' })
				})
				.catch(message => {
          this.$store.dispatch('alert/add', {
            message: message,
						type: 'error'
          })

			// Reset component state
					component.state = 'idle'
				})
		},
	}
}
</script>
