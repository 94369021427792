<template>
	<div class="ci-brand">
		<router-link to="/">
			<img class="ci-brand__image" :src="publicPath + image" alt="Citrus">
		</router-link>
<!-- 
		<router-link to="/blog" 
			v-if="this.$route.name == 'blog' ||
				this.$route.name == 'post' ||
				this.$route.name == 'categoria'"	>
			  <img class="ci-brand__image" :src="publicPath + image" alt="Citrus">
		</router-link> -->
	</div>
</template>

<script>
export default {
	props: {
		type: {
			type: String,
			default: 'citrus'
		}
	},

	computed: {
		image() {
			return this.type == 'citrus' ? 'images/brand-citrus.png' :
				this.type == 'suite' ? 'images/brand-citrus-suite.png' :
				this.type == 'genesys' ? 'images/brand-genesys.png' :
				this.type == 'squad' ? 'images/brand-citrus-squad.png' : null

		}
	}
}
</script>

<style lang="scss">
.ci-brand{
	&__image{
		width: 100%;
		max-width: 200px;
		height: auto;
	}
}
</style>
