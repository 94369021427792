<template>
	<div class="ci-solutions"
		:class="[
			{
				'is-idle': !initialized && !animated
			}
		]"
	>
		<div class="ci-solutions__container">
			<div class="ci-solutions__content ci-solutions__animate">
				<ci-text class="ci-solutions__title" type="title">
					{{ $t('solution.title') }}
				</ci-text>
				<ci-text class="ci-solutions__text" :v-html="content">{{ $t('solution.content') }}
				</ci-text>
			</div>

			<div class="ci-solutions__items">
				<ci-solution
					class="ci-solutions__solution ci-solutions__animate"
					v-for="(solution, index) in solutionsComputed"
					:icon="solution.icon"
					:title="solution.title"
					:content="solution.content"
					:key="index"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import CIText from '@/components/atoms/CIText'
import CISolution from '@/components/molecules/CISolution'
import { TweenMax, Power2 } from 'gsap/TweenMax'

export default {
	components: {
		'ci-text': CIText,
		'ci-solution': CISolution
	},
	props: {
		icon: {
			type: String,
			default: '/images/icon-finance.png'
		},
		title: {
			type: String,
			default: 'Curabitur libero augue, convallis ac elit non'
		},
		content: {
			type: String,
			default: 'Maecenas et nibh lorem. Proin malesuada, lorem quis convallis elementum, velit sapien posuere purus, nec tempor ligula augue a elit.'
		},
		solutions: {
			type: Array,
			default: () => ([
				{}, {}, {}, {}, {}, {}
			])
		},
		initialized: {
			type: Boolean,
			default: true
		},
	},

	data: () => ({
		animated: false,
	}),

	computed: {
		solutionsComputed(){
			return this.solutions.map((item) => {
				const flag = item.title.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^a-zA-Zs]/g, "")
				return {
					icon: item.icon,
					content: this.$t(`solution.items.${flag}.content`),
					title: this.$t(`solution.items.${flag}.title`),
				}
			});
		}
	},

	mounted() {
		if (this.initialized)
			this.init()
	},

	watch: {
		initialized(value) {
			if (value && !this.animated)
				this.init()
		}
	},

	methods: {
		init() {
			this.animated = true

			TweenMax.staggerFrom(this.$el.querySelectorAll('.ci-solutions__animate'), 1.5, {
				x: "-=80",
				opacity: 0,
				ease: Power2.easeOut
			}, .15)
		}
	}
}
</script>

<style lang="scss">
.ci-solutions{
	display: flex;
	align-items: center;
	position: relative;
	transition: opacity .6s;
	z-index: 20;
	@include grid-container;
	&__container{
		@include outer-container;
	}
	&__content{
		@include grid-column(6);
	}
	&__title{
		margin-bottom: 16px;
		color: $c-darkgray;
	}
	&__text{
	}
	&__items{
		width: 100%;
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		padding-top: 16px;
	}
	&__solution{
		padding: 16px 0;
		@include grid-column(5);
	}

	@include grid-media($g-tablet-up) {
		.ci-solutions{
			&__solution{
				@include grid-column(6);
			}
		}
	}

	@include grid-media($g-desktop-up) {
		.ci-solutions{
			&__items{
				padding-top: 0;
				justify-content: flex-start;
			}
			&__content{
				@include grid-column(4);
			}
			&__items{
				@include grid-column(8);
			}
			&__solution{
				@include grid-column(4);
			}
		}
	}

	&.is-idle{
		opacity: 0;
	}
}
</style>
