<template>
	<div class="ci-try-out"
		:class="{
			'is-idle': !initialized && !animated
		}"
	>
		<ci-circle class="ci-try-out__circle ci-try-out__animate--scale" theme="accent" />
		<ci-leaf class="ci-try-out__leaf ci-try-out__animate--stroke" theme="primary" :filled="false" />

		<div class="ci-try-out__container">
			<div class="ci-try-out__content">
				<ci-text class="ci-try-out__title ci-try-out__animate--slide" type="title">
					{{$t('tryOut.title')}} 
				</ci-text>
				<ci-text class="ci-try-out__text ci-try-out__animate--slide" type="lead" :v-html="content">{{$t('tryOut.content')}}
				</ci-text>

				<div class="ci-try-out__brands ci-try-out__animate--slide" v-if="brands">
					<img class="ci-try-out__brand" :src="`${publicPath}images/brand-squad.png`" alt="Citrus Squad" />
					<img class="ci-try-out__brand" :src="`${publicPath}images/brand-lemon-desk.png`" alt="Citrus Squad" />
					<img class="ci-try-out__brand" :src="`${publicPath}images/brand-lime-sales.png`" alt="Citrus Squad" />
					<img class="ci-try-out__brand" :src="`${publicPath}images/brand-pomelo-flow.png`" alt="Citrus Squad" />
					<img class="ci-try-out__brand" :src="`${publicPath}images/brand-tangy-metrics.png`" alt="Citrus Squad" />
					<img class="ci-try-out__brand" :src="`${publicPath}images/brand-citric-brain.png`" alt="Citrus Squad" />
					<img class="ci-try-out__brand" :src="`${publicPath}images/brand-blossom.png`" alt="Citrus Squad" />
				</div>
			</div>

			<ci-contact
				class="ci-try-out__contact ci-try-out__animate--slide"
				@submit="$emit('contact', $event)"
			/>
		</div>
	</div>
</template>

<script>
import CIContact from '@/components/molecules/CIContact'
import CILeaf from '@/components/atoms/CILeaf'
import CICircle from '@/components/atoms/CICircle'
import CIText from '@/components/atoms/CIText'
import anime from 'animejs'
import { TweenMax, Power2, Elastic } from 'gsap/TweenMax'

export default {
	components: {
		'ci-text': CIText,
		'ci-contact': CIContact,
		'ci-leaf': CILeaf,
		'ci-circle': CICircle,
	},

	props: {
		action: {
			type: String,
			default: '/obrigado'
		},
		title: {
			type: String,
			default: 'Vivamus conva, mi sed venenatis'
		},
		content: {
			type: String,
			default: 'Nunc rhoncus ipsum non nunc lobortis, ut tempus odio egestas. Sed vel pellentesque massa, sagittis placerat turpis. Nullam quis eleifend magna. '
		},
		initialized: {
			type: Boolean,
			default: true
		},
		brands: {
			type: Boolean,
			default: true
		}
	},

	data: () => ({
		animated: false
	}),

	mounted() {
		if (this.initialized)
			this.init()
	},

	watch: {
		initialized(value) {
			if (value && !this.animated)
				this.init()
		}
	},

	methods: {
		init() {
			this.animated = true

			TweenMax.staggerFrom(this.$el.querySelectorAll('.ci-try-out__animate--scale'), 1.4, {
				scale: .9,
				ease: Elastic.easeOut
			}, .5)

			TweenMax.staggerFrom(this.$el.querySelectorAll('.ci-try-out__animate--scale'), .75, {
				opacity: 0,
				ease: Power2.easeOut,
			}, .2)

			anime({
				targets: this.$el.querySelectorAll('.ci-try-out__animate--stroke path'),
				strokeDashoffset: [anime.setDashoffset, 0],
				easing: 'easeInOutQuad',
				delay: 750,
				duration: 4000
			})

			TweenMax.staggerFrom(this.$el.querySelectorAll('.ci-try-out__animate--slide'), 1.5, {
				x: "-=64",
				opacity: 0,
				ease: Power2.easeOut
			}, .5)
		}
	}
}
</script>

<style lang="scss">
.ci-try-out{
	overflow: hidden;
	position: relative;
	transition: opacity .6s;
	z-index: 10;
	@include grid-container;
	&__container{
		@include outer-container;
	}
	&__content{
		margin-bottom: 32px;
		@include grid-column(6);
	}
	&__title{
		margin-bottom: 24px;
		color: $c-darkgray;
	}
	&__text{
	}
	&__contact{
		@include grid-column(6);
	}
	&__brands{
		margin-top: 24px;
	}
	&__brand{
		width: 24px;
		height: auto;
		margin-right: 8px;
	}
	&__leaf{
		position: absolute;
		transform: scaleX(-1);
		display: none;
		z-index: -1;
	}
	&__circle{
		position: absolute;
		display: none;
		z-index: -1;
	}


	@include grid-media($g-tablet-up) {
		.ci-try-out{
			&__leaf{
				top: 512px;
				right: 60%;
				display: block;
			}
			&__circle{
				top: 432px;
				right: 70%;
				display: block;
			}
			&__content{
				@include grid-column(6);
			}
			&__contact{
				@include grid-column(6);
			}
		}
	}

	@include grid-media($g-desktop-up) {
		.ci-try-out{
			&__content{
				@include grid-column(4);
			}
			&__contact{
				@include grid-column(6);
				@include grid-push(2);
			}
		}
	}

	&.is-idle{
		opacity: 0;
	}
}
</style>
