<template>
	<span class="ci-text"
		:class="[
			`ci-text--${theme}`,
			`ci-text--${type}`,
			{
				'is-dark': dark,
				'is-bold': bold
			}
		]"
	>
		<slot></slot>
	</span>
</template>

<script>
export default {
	props: {
		theme: {
			type: String,
			default: 'default' // default | primary | accent | warn
		},
		type: {
			type: String,
			default: 'body' // check the style
		},
		dark: {
			type: Boolean,
			default: false
		},
		bold: {
			type: Boolean,
			default: false
		}
	}
}
</script>

<style lang="scss">
.ci-text{
	font-family: Avenir;
	display: block;
	color: $c-gray;

	// Themes
	&--primary{
		color: $t-primary;
	}
	&--accent{
		color: $t-accent;
	}
	&--warn{
		color: $t-warn;
	}

	// Types
	&--jumbo{
		font-family: "Montserrat";
		font-weight: 700;
		line-height: 100%;
		font-size: 40px;
		letter-spacing: -1px;
	}
	&--content{
		font-family: "Montserrat";
		line-height: 26px;
		font-size: 14px;
	}
	&--title{
		font-size: 32px;
		line-height: 40px;
		font-weight: bold;
	}
	&--subhead{
		font-size: 24px;
		line-height: 28px;
		font-weight: bold;
	}
	&--lead{
		font-size: 16px;
		line-height: 140%;
		font-weight: normal;
	}
	&--body{
		font-size: 16px;
		line-height: 28px;
		font-weight: normal;
	}
	&--bodyBold{
		font-size: 16px;
		line-height: 20px;
		font-weight: bold;
	}
	&--small{
		font-size: 14px;
		line-height: 22px;
		font-weight: normal;
	}
	&--smallb{
		font-size: 12px;
		line-height: 22px;
		font-weight: bold;
	}
	&--tooltip{
		font-size: 9px;
		line-height: 16px;
		text-transform: uppercase;
		font-weight: bold;
		letter-spacing: .5px;
	}
	&--caption{
		font-size: 11px;
		text-transform: uppercase;
		letter-spacing: 2px;
		font-weight: normal;
	}
	a {
		color: $t-accent;
		font-weight: 700;
		text-decoration: none;
	}

	// Dark
	&.is-dark{
		color: white;
	}

	&.is-bold{
		font-weight: bold;
	}

	@include grid-media($g-desktop-up) {
		&--jumbo{
			font-size: 64px;
			letter-spacing: -3px;
		}
		&--title{
			font-size: 46px;
			line-height: 52px;
		}
		&--subhead{
			font-size: 29px;
			line-height: 28px;
		}
		&--lead{
			font-size: 20px;
			line-height: 150%;
		}
	}
}
</style>
