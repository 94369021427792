<template>
  <div class="post">
    <div class="post__container">
      <div class="post__single_page">
        <div class="grid-blog">
          <div class="post__reading">
            <div class="post__breadcrumb">
              <router-link
                :to="{
                  name: 'categoria',
                  params: {
                    catID: post.cats[0].cat_ID,
                    catSlug: post.cats[0].slug,
                  },
                }"
                v-if="post.cats[0].cat_name !== 'Sem categoria'"
              >
                <ci-text type="smallb">{{ post.cats[0].cat_name }}</ci-text>
                <!--//pegando a prieira categoria deste post -->
              </router-link>
              <i class="fas fa-chevron-right"></i>

              <router-link :to="{ name: 'post', params: { postID: post.id } }">
                <ci-text type="smallb" v-html="post.title.rendered"></ci-text>
              </router-link>
            </div>

            <div class="post__content">
              <figure class="primeira">
                <img class="primeira" :src="post.firstimg[0]" alt="" />
              </figure>

              <ci-text type="subhead" v-html="post.title.rendered"></ci-text>

              <div class="content_bellow">
                <ci-text class="moment">
                  <i class="far fa-clock"></i>
                  {{ post.date | mom }}
                </ci-text>

                <ci-text class="moment"
                  >Por:
                  {{
                    author
                      .replace(/1/g, " ")
                      .toLowerCase()
                      .replace(/(?:^|\s)(?!da|de|do)\S/g, (l) =>
                        l.toUpperCase()
                      )
                  }}</ci-text
                >

                <small
                  v-bind:class="post.cats[0].slug"
                  class="catSingle"
                  v-for="category in post.cats"
                  v-bind:key="category.id"
                  v-if="category.name != 'Sem categoria'"
                >
                  <router-link
                    :to="{
                      name: 'categoria',
                      params: {
                        catID: category.cat_ID,
                        catSlug: category.slug,
                      },
                    }"
                  >
                    {{ category.name }}
                  </router-link>
                </small>
              </div>
              <ci-text
                type="content"
                class="post__body"
                v-html="post.content.rendered"
              ></ci-text>

              <br />
              <fb-comment
                :url="'/blog/' + post.id + '/' + post.slug"
                width="100%"
                style="min-height: 50px;"
              />
            </div>
          </div>

          <div class="pophigh">
            <!-- <ci-popular></ci-popular> -->

            <ci-highlight></ci-highlight>
            <ci-advertising :anuncios="anuncios"></ci-advertising>
          </div>
        </div>
      </div>
    </div>
    <ci-blog-footer></ci-blog-footer>
  </div>
</template>

<script>
import CIText from "@/components/atoms/CIText";
// import CIPopular from '@/components/organisms/CIPopular'
import CIAdvertising from "@/components/organisms/CIAdvertising";
import CIHighlight from "@/components/organisms/CIHighlight";
import CIBlogFooter from "@/components/organisms/CIBlogFooter";

import moment from "moment";
import $ from "jquery";

export default {
  components: {
    "ci-text": CIText,
    // 'ci-popular': CIPopular,
    "ci-highlight": CIHighlight,
    "ci-blog-footer": CIBlogFooter,
    "ci-advertising": CIAdvertising,
  },
  data() {
    return {
      post: [],
      author: "",
      ogtitle: "",
      linked: "",
      imgss: "",
    };
  },
  props: {
    anuncios: {},
  },
  mounted() {
    let promise = this.$http.get(
      "/wp-json/wp/v2/posts/" + this.$route.params.postID
    );
    promise
      .then((res) => res.json())
      .then((post) => {
        this.post = post;
        let promise2 = this.$http.get("/wp-json/wp/v2/users/" + post.author);
        promise2
          .then((res) => res.json())
          .then((author) => {
            this.author = author.name;
          });
      });
    //
    $("iframe")
      .parent()
      .addClass("iframe-container");
    //
  },
  after: {
    article() {
      let promise = this.$http.get(
        "/wp-json/wp/v2/posts/" + this.$route.params.postID
      );
      promise
        .then((res) => res.json())
        .then((post) => (this.ogtitle = post.title.rendered));

      var titled = this.ogtitle;
      return titled;
    },
    link() {
      let promise = this.$http.get(
        "/wp-json/wp/v2/posts/" + this.$route.params.postID
      );
      promise
        .then((res) => res.json())
        .then((post) => (this.linked = post.link));

      var linked = this.linked;
      return linked;
    },
    imagess() {
      let promise = this.$http.get(
        "/wp-json/wp/v2/posts/" + this.$route.params.postID
      );
      promise
        .then((res) => res.json())
        .then((post) => (this.imgss = post.firstimg[0]));

      var imgss = this.imgss;
      return imgss;
    },
  },
  metaInfo() {
    return {
      title: this.article,
      meta: [
        { name: "description", content: this.article },
        { name: "og:url", content: this.link },
        { name: "og:site_name", content: "Blog Citrus.cx" },
        { name: "og:image", content: this.imagess },
      ],
    };
  },
  filters: {
    mom: function(date) {
      return moment(date).format("DD.MM.YYYY");
    },
  },
};
</script>

<style lang="scss">
.item {
  padding: 10px;
  border: solid 1px silver;
  margin: 5px;
  display: none;
}
.post {
  margin-top: 5vh;

  .grid-blog {
    grid-template-rows: 1fr !important;
  }

  &__single_page {
    width: calc(100% - 32px);
    float: left;
    margin-left: 16px;
  }
  blockquote {
    position: relative;
    padding: 0 0 0 50px;
    font-style: italic;
    font-size: 15px;
    margin: 0;
  }
  blockquote::before {
    content: "";
    width: 5%;
    height: 46px;
    position: absolute;
    top: 6px;
    background-size: contain;
    left: 0;
    background-repeat: no-repeat;
  }
  &__container {
    @include outer-container;
    z-index: 999;
    position: relative;
  }
  &__reading {
    width: 100%;
  }

  &__breadcrumb {
    width: 100%;
    border-bottom: solid 1px #d8d9db;
    position: relative;
    padding-bottom: 15px;
    padding-top: 5px;
    display: inline-flex;
    flex-flow: wrap;
    align-items: center;

    a {
      text-transform: uppercase;
      font-weight: 700;
    }

    i {
      font-size: 7px;
      margin: 0 12px;
      color: #b3c520;
    }
  }
  &__breadcrumb::after {
    content: "";
    width: 34%;
    border-radius: 3px;
    padding: 3px;
    background: #b3c520;
    position: absolute;
    bottom: -3.5px;
    left: 0;
  }

  &__content {
    .primeira {
      display: block !important;
    }
    img {
      width: 100%;
      object-fit: contain;
      max-width: 100%;
    }
    figure {
      margin: 0;

      img {
        margin: 30px 0;
        max-width: 100%;
        width: 100%;
        border-radius: 10px;
      }
    }
    .iframe-container {
      overflow: hidden;
      padding-top: 56.25%;
      position: relative;
    }

    /*.iframe-container */
    iframe {
      /*  border: 0;
		   height: 100%;
		   left: 0;
		   position: absolute;
		   top: 0;
		   width: 100%;*/
      width: 100%;
      height: 500px;
    }
    p:first-child {
      figure {
        display: none !important;
      }
      img {
        display: none !important;
      }
    }
  }
  &__body {
    p:first-child {
      figure {
        display: none;
      }
      img {
        display: none;
      }
    }
    figure:nth-child(1) {
      display: none !important;
    }
    figure:last-child {
      display: block !important;
      width: auto !important;
    }
  }
}
.advertising {
  width: 100%;
  /*display: none;*/
  img {
    width: 100%;
  }
}
.content_bellow {
  margin: 30px 0 10px;
  border-top: solid 1px #ebebeb;
  border-bottom: solid 1px #ebebeb;
  padding: 8px 0;
  width: 100%;
  display: inline-flex;
  align-items: center;
}

@include grid-media($g-tablet-up) {
  .post {
    &__reading {
    }
    &__content {
      img {
        width: 100%;
        object-fit: 100%;
        max-width: 100%;
      }
    }
  }
}
.post__reading {
  grid-area: listAll;
}
.pophigh {
  grid-area: highlights;
}
.grid-blog {
  width: 100%;
  display: grid;
  grid-template-columns: 71.5% 1fr;
  grid-template-columns: 21.2% 21.2% 1fr 1fr;
  grid-gap: 20px;
  grid-template-areas: "listAll listAll listAll highlights";
}
.fb-comments,
.fb-comments span,
.fb-comments iframe {
  width: 100% !important;
  min-width: 100% !important;
}
.alignright {
  float: right !important;
  width: auto !important;
  display: block !important;
}
.alignleft {
  float: left !important;
  width: auto !important;
  display: block !important;
}
</style>
