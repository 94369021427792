<template>
	<aside class="ci-aside">
		<button
			class="ci-aside__close"
			@click="$emit('close')"
		/>

		<ci-navigation
			class="ci-aside__navigation"
			size="lg"
			:value="selected"
			:options="navigation"
			@input="$emit('update:selected', $event)"
			v-if=" 
			  this.$route.name !== 'blog' &&
			  this.$route.name !== 'post' &&
			  this.$route.name !== 'categoria'"
		/>
		<ci-navigation-blog	class="ci-aside__navigation ci-navigation--lg"
			v-if="this.$route.name == 'blog' ||
				  this.$route.name == 'post' ||
				  this.$route.name == 'categoria'"
				  @click="$emit('close')"
		/>
	</aside>
</template>

<script>
import CINavigation from '@/components/molecules/CINavigation'
import CINavigationBlog from '@/components/molecules/CINavigationBlog'

export default {
	components: {
		'ci-navigation': CINavigation,
		'ci-navigation-blog': CINavigationBlog,
	},

	props: {
		navigation: {
			type: Array,
			default: undefined
		},
		selected: {
			type: String,
			default: undefined
		}
	}
}
</script>

<style lang="scss">
.ci-aside{
	background: white;
	box-shadow: 0 0 24px rgba(black, .2);
	padding: 24px;
	box-sizing: border-box;
	display: flex;
	align-items: flex-start;
	overflow-x: hidden;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	&__navigation{
		width: 100%;
	}
	&__close{
		width: 44px;
		height: 44px;
		position: absolute;
		top: 16px;
		right: 16px;
		background: transparent;
		border: 0;
		outline: none;
		cursor: pointer;
		z-index: 9999;
		&:before,
		&:after{
			content: "";
			position: absolute;
			top: 50%;
			left: 50%;
			width: 24px;
			height: 4px;
			background: $c-darkgray;
			border-radius: 4px;
		}
		&:before{
			transform: translate(-50%, -50%) rotate(-45deg);
		}
		&:after{
			transform: translate(-50%, -50%) rotate(45deg);
		}
	}
}
</style>
