<template>
	<div class="ci-benefit"
		:class="[
			`ci-benefit--${theme}`
		]"
	>
		<figure class="ci-benefits__circle">
			<img class="ci-benefit__icon" :src="icon">
		</figure>
		<ci-text type="body" class="ci-benefits__comment">
			{{description}}
		</ci-text>
	</div>
</template>

<script>
import CIText from '@/components/atoms/CIText'

export default {
	components: {
		'ci-text': CIText
	},
	props: {
		icon: {
			type: String,
			default: 'images/icon-finance.png'
		},
		description: {
			type: String,
			default: 'Defaulting'
		},
		theme: {
			type: String,
			default: 'primary'
		}
	}
}
</script>

<style lang="scss">

</style>
