<template>
	<label class="ci-input-text"
		:class="[
			`ci-input-text--${theme}`,
			{
				'is-focused': focused,
				'is-textarea': type == 'textarea'
			}
		]"
	>
		<textarea
			v-if="type == 'textarea'"
			class="ci-input-text__field ci-text ci-text--body"
			:placeholder="placeholder"
			:value="value"
			@input="$emit('input', $event.target.value)"
			@focus="focused = true"
			@blur="focused = false"
		>
		</textarea>

		<input
			v-else
			class="ci-input-text__field ci-text ci-text--body"
			:type="type"
			:value="value"
			:placeholder="placeholder"
			@input="$emit('input', $event.target.value)"
			@focus="focused = true"
			@blur="focused = false"
		>
	</label>
</template>

<script>
export default {
	props: {
		value: {
			type: String,
			default: undefined
		},
		type: {
			type: String,
			default: 'text' // text | email | password | textarea
		},
		placeholder: {
			type: String,
			default: 'Field name'
		},
		theme: {
			type: String,
			default: 'primary' // primary | accent | warn
		},
	},

	data: () => ({
		focused: false
	})
}
</script>

<style lang="scss">
.ci-input-text{
	position: relative;
	display: block;
	&__field{
		width: 100%;
		border-radius: 24px;
		border: 1px solid rgba(black, .15);
		padding: 0 24px;
		height: 48px;
		outline: none;
		box-sizing: border-box;
		resize: none;
		&:placeholder{
			color: $c-gray;
		}
	}
	&:after{
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		opacity: 0;
		border: 2px solid transparent;
		border-radius: 24px;
		transition: opacity .15s ease-out;
		pointer-events: none;
	}

	&--primary{
		&:after{
			border-color: $t-primary;
		}
	}
	&--accent{
		&:after{
			border-color: $t-accent;
		}
	}
	&--warn{
		&:after{
			border-color: $t-warn;
		}
	}

	&:hover:after{
		opacity: .4;
	}

	&.is-focused{
		&:after{
			opacity: 1;
		}
	}

	&.is-textarea{
		.ci-input-text{
			&__field{
				padding: 9px 24px;
				height: 160px;
			}
		}
	}
}
</style>
