<template>
  <div class="category">
	<div class="category__container">
		<div class="category__list_page">
			<div class="grid-blog">				
				<div class="category__items">
					<ci-text class="category__titles subh" type="subhead">
						<span v-if="this.$route.params.catSlug == 'colaboracao'"> Colaboração</span>
						<span v-if="this.$route.params.catSlug == 'customer-experience'"> Customer Experience </span>
						<span v-if="this.$route.params.catSlug == 'omnichannel'"> Omnichannel</span>
						<span v-if="this.$route.params.catSlug == 'vendas'"> Marketing e vendas</span>
						<span v-if="this.$route.params.catSlug == 'jornada-do-cliente'"> Jornada do Cliente</span>
					</ci-text>
					
					<article v-for="post in posts" v-bind:key="post.id">
						<div class="post-content">
							<router-link :to="{ name: 'post', params: { postID: post.id, postSlug: post.slug}}">
								<figure>
									<img v-bind:src="post.firstimg[0]">
								</figure>
								<div class="body_text">
									<ci-text class="blog__titles" type="subhead" v-html="post.title.rendered"></ci-text>
									
									<ci-text class="moment"> 
										<i class="far fa-clock"></i> 
										{{post.date | moment }}
									</ci-text>

									<ci-text class="moment" v-html="post.excerpt.rendered"> 
									</ci-text>
								</div>
							</router-link>
						</div>
					</article>
				</div>
				<div class="pophigh">
					<ci-highlight></ci-highlight>
					<ci-advertising :anuncios="anuncios"></ci-advertising>
				</div>
			
			</div>
		</div>
	</div>
	<ci-blog-footer></ci-blog-footer>
  </div>
</template>

<script>
import CIText from '@/components/atoms/CIText'
// import CIPopular from '@/components/organisms/CIPopular'
import CIHighlight from '@/components/organisms/CIHighlight'
import CIBlogFooter from '@/components/organisms/CIBlogFooter'
import CIAdvertising from '@/components/organisms/CIAdvertising'
import moment from 'moment'
import { mapGetters } from 'vuex'


export default {
  	components: {
		'ci-text': CIText,
		// 'ci-popular': CIPopular,
		'ci-highlight': CIHighlight,
		'ci-blog-footer': CIBlogFooter,
		'ci-advertising': CIAdvertising
	},
   	data(){
		return{
			posts: [],
			currentPage: '1'
		}	
	},

	computed: {
		...mapGetters({
			anuncios: 'page/getAnuncios'
		})
	},
    mounted() {
		let categories = this.$http.get("/wp-json/wp/v2/posts?categories=" + this.$route.params.catID);
		categories
		.then(res => res.json())
		.then(posts => this.posts = posts);
	},
	filters: {
		moment: function (date) {
			return moment(date).format('DD.MM.YYYY');
		},
		slugger: function () {
			return replace('-', ' ');
		}
	},
}
</script>

<style lang="scss">
.category{
	margin-top:5vh;

	&__container{
		@include outer-container;
	}
	&__titles.subh{		
		text-transform: uppercase;
		color: #7f8189;
		border-bottom: solid 1px #d8d9db;
		position: relative;
		padding-bottom: 14px;
		margin-bottom:30px;
		text-transform: capitalize;
    	width: 100%;
	}
	
	&__titles.subh::after{
		content: '';
		width: 50%;
		border-radius: 3px;
		padding: 3px;
		background: #b3c520;
		position: absolute;
		bottom: -3.5px;
		left: 0;
	}
	&__list_page{
		width: calc(100% - 32px);
		float: left;
		margin-left: 16px;
	}
	&__items{
		width: 100%;
	}
	article{
		position: relative;
    	width: 100%;

		.post-content{
			width: 100%;
			margin-bottom: 23px;
			display: flex;
			
			a{
				width: 100%;
			}
			figure{
				width: 46%;
				float: left;

				img{
					width: 100%;
					height: 210px;
					border-radius: 10px;
					object-fit: cover;
					object-position: center;
				}
			}
			.body_text{
				display: flex;
				flex-flow: column;
				justify-items: center;
				height: 100%;
				width: 50%;
				float: right;
				justify-content: center;
			}
			.blog__titles{
				line-height: 130%;
				letter-spacing: 0.5px;
				margin-top: 5px;
				font-size: 21px;
			}
		}
	}
}
figure{
	margin: 0;
	padding: 0;
}

.category__items {
	grid-area: listAll;
	width: 100%;	
}
.pophigh{
	grid-area: highlights;
}
@include grid-media($g-desktop-up) {
	.grid-blog {
		width: 100%;
		display: grid;
		grid-template-columns: 21.2% 21.2% 1fr 1fr;
		grid-gap: 20px;
		grid-template-areas: "listAll listAll listAll highlights";
	}
}
</style>