<template>
	<div class="ci-hero"
		:class="[
			`ci-hero--${theme}`,
			{
				'is-idle': !initialized && !animated
			}
		]"
	>
		<div class="ci-hero__container">
			<img class="ci-hero__shape ci-hero__animate" :src="`${publicPath}images/shape-hero.svg`">
			<!-- <img class="ci-hero__screen ci-hero__animate" :src="'" :alt="title"> -->

			<img class="ci-hero__screen ci-hero__animate" :src="$t('hero.url')" :alt="title">

			<div class="ci-hero__content">
				<ci-text class="ci-hero__title ci-hero__animate" type="title">
					<!-- importando a tradução-->	{{$t('hero.title')}} 
				</ci-text>
				<ci-text class="ci-hero__text ci-hero__animate" type="lead" :v-html="content">{{$t('hero.content')}} 
				</ci-text>
				<a href="https://materiais.citrus.cx/agende-uma-demonstracao" target="_blank"><button class="ci-button ci-text ci-text--body is-dark ci-spotlight__button ci-button--accent ci-button--md ci-hero__animate" type="submit">
					{{$t('demonstracao.text')}}
				</button></a>
			</div>
		</div>
	</div>
</template>

<script>
import CIText from '@/components/atoms/CIText'
import { TweenMax, Power2, Elastic } from 'gsap/TweenMax'

export default {
	components: {
		'ci-text': CIText
	},
	props: {
		screen: {
			type: String,
			default: 'images/citrus-screen.png'
		},
		title: {
			type: String,
			default: 'Aenean congue pellentesque tortor.'
		},
		content: {
			type: String,
			default: 'Nunc pulvinar a ex sit amet maximus. Etiam sollicitudin commodo ipsum nec tincidunt. Mauris dictum id nibh id vehicula.'
		},
		theme: {
			type: String,
			default: 'accent'
		},
		initialized: {
			type: Boolean,
			default: true
		}
	},

	data: () => ({
		animated: false
	}),

	mounted() {
		if (this.initialized)
			this.init()
	},

	watch: {
		initialized(value) {
			if (value && !this.animated)
				this.init()
		}
	},

	methods: {
		init() {
			this.animated = true

			TweenMax.staggerFrom(this.$el.querySelectorAll('.ci-hero__animate'), 1.5, {
				x: "-=80",
				opacity: 0,
				ease: Power2.easeOut
			}, .4)
		},
	}
}
</script>

<style lang="scss">
.ci-hero{
	position: relative;
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	position: relative;
	transition: opacity .6s;
	z-index: 30;
	@include grid-container;
	&__container{
		width: 100%;
		@include outer-container;
	}
	&__screen{
		width: 100%;
		pointer-events: none;
		margin-bottom: 32px;
	}
	&__tag{
		width: 270px;
		pointer-events: none;
		margin-bottom: 32px;
		position: absolute;
	}
	&__shape{
		position: absolute;
		width: 800px;
		right: 0%;
		top: -35%;
		z-index: -1;
		pointer-events: none;
	}
	&__content{
		@include grid-column(6);
	}
	&__title{
		margin: 16px 0;
		color: $c-darkgray;
	}
	&__text{
		color: $c-darkgray;
	}

	// Themes
	&--primary{
		.ci-hero{
			&__shape{
				// background: $t-primary;
			}
		}
	}
	&--accent{
		.ci-hero{
			&__shape{
				// background: $t-accent;
			}
		}
	}
	&--warn{
		.ci-hero{
			&__shape{
				// background: $t-warn;
			}
		}
	}

	@include grid-media($g-tablet-up){
		.ci-hero{
			&__title{
				margin: 0 0 24px;
			}
			&__screen{
				position: absolute;
				right: 48.9%;
				top: 20%;
				max-width: 800px;
				width: 55%;
				margin-bottom: 0;
			}
			&__shape{
				width: auto;
				right: 52%;
				top: -400px;
			}
			&__content{
				@include grid-column(6);
				@include grid-push(6);
			}
		}
	}

	@include grid-media($g-desktop-up) {
		.ci-hero{
			&__shape{
				width: auto;
				right: 51%;
				top: -340px;
			}

		}
	}

	// Idle
	&.is-idle{
		opacity: 0;
	}
}
</style>
