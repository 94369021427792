<template>
	<div class="ci-feature">
		<div class="ci-feature__circle">
			<img class="ci-feature__image" :src="image" :alt="label">
		</div>
		<ci-text class="ci-feature__label" :bold="true" :dark="true" type="lead">
			{{ label }}
		</ci-text>
	</div>
</template>

<script>
import CIText from '@/components/atoms/CIText'

export default {
	components: {
		'ci-text': CIText
	},

	props: {
		image: {
			type: String,
			default: '/images/icon-mic.png',
		},
		label: {
			type: String,
			default: 'Lorem ipsum'
		}
	}
}
</script>

<style lang="scss">
.ci-feature{
	text-align: center;
	position: relative;
	&__circle{
		width: 80px;
		height: 80px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 auto 17px;
		position: relative;
		z-index: 5;
		&:before{
			content: "";
			position: absolute;
			top: -14px;
			right: -14px;
			bottom: -14px;
			left: -14px;
			border-radius: 999px;
			/*background: #b9cf2f;*/
			background: rgba(240, 251, 178, 0.19);
			z-index: -2;
		}
		&:after{
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			border-radius: 999px;
			background: white;
			z-index: -1;
		}
	}
	&__image{
		width: 32px;
		height: auto;
    filter: contrast(0.1) brightness(1.2);
	}
	&__label{
	    padding: 6px 10px;
	    border-radius: 999px;
	    background: #b9cf2f;
	    display: inline-block;
	    font-size: 13px;
	    max-width: 140px;
	}

	@include grid-media($g-desktop-up) {
		.ci-feature{
			&__circle{
				width: 110px;
				height: 110px;
				transition: .4s $easeOutBack;
				transform: scale(.8);
				z-index: 5;

				&:before{
					transition: .4s $easeOutBack .1s;
				}
			}
			&__label{
				transition: .3s $easeInOutQuad;
				font-size: 14px;
			}
			&__image{
				width: 48px;
			}
		}
		&:hover{
			.ci-feature{
				&__circle{
					transform: scale(1);
					&:before{
						transform: scale(1.1);
					}
				}
				&__label{
					opacity: 1;
					transform: translateY(15px);
				}
			}
		}
	}
}
</style>
