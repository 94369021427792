<template>
    <div class="content-04">
        <img class="elipse-bottom" src="../image/elipse-bottom.png" alt="elipse-bottom" />
        <img src="../image/logo.png" class="logo-cast" alt="logo-cast">
        <div class="box-social">
            <img src="../image/insta.png" class="social" alt="social">
            <img src="../image/linkedin.png" class="social" alt="social">
            <img src="../image/facebook.png" class="social" alt="social">
            <img src="../image/twitter.png" class="social" alt="social">
            <img src="../image/youtube.png" class="social" alt="social">
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            
        }
    }
}
</script>

<style lang="scss">
    .page-cast {
        .content-04 {
            position: relative;

            .elipse-bottom {
                width: 100%;
                height: 350px;
                margin-top: 50px;
            }

            .logo-cast {
                position: absolute;
                bottom: 160px;
                left: calc(50% - 60px);
                width: 120px;
            }

            .box-social {
                position: absolute;
                bottom: 70px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                .social {
                    width: 50px;
                    margin: 5px;
                }
            }

        }
    }
</style>