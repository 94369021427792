<template>
	<div class="ci-navigation navblog is-inline">
		<div class="ci-navigation__option">
			<ci-text class="ci-navigation__label">
				Categorias
			</ci-text><br>
			<div class="ci-navigation__submenu">							
				<ci-text v-for="c in catMenu" class="ci-navigation__child" v-if="c.slug !== 'sem-categoria' && c.count !== 0">
					
					<router-link :to="{name: 'categoria', params: {catID: c.id, catSlug: c.slug }}" >
						<span v-if="c.slug == 'colaboracao'"> Colaboração</span>

						<span v-if="c.slug == 'customer-experience'"> Customer Experience </span>

						<span v-if="c.slug == 'omnichannel'"> Omnichannel</span>

						<span v-if="c.slug == 'vendas'"> Marketing e vendas</span>

						<span v-if="c.slug == 'jornada-do-cliente'"> Jornada do Cliente</span>
					</router-link>
				</ci-text>				
			</div>
		</div>
		<div class="ci-navigation__option">
			<router-link to="/">
				<ci-text class="ci-navigation__label">
					Citrus
				</ci-text>
			</router-link>
		</div>
		<div class="ci-navigation__option">
			<a href="http://digivox.com.br" target="_blank">
				<ci-text class="ci-navigation__label">
					Digivox
				</ci-text>
			</a>
		</div>
		<div class="ci-navigation__option">
			<a href="https://materiais.citrus.cx/agende-uma-demonstracao" target="_blank">
				<ci-text class="ci-navigation__label" v-on:click="this.goToContact()">
					Contato
				</ci-text>
			</a>
		</div>
	</div>
</template>

<script>
import CIText from '@/components/atoms/CIText'
import $ from 'jquery'

export default {
	components: {
		'ci-text': CIText
	},
	data(){
		return{
			catMenu: []
		}	
	},
	mounted() {
		let categorias = this.$http.get("/wp-json/wp/v2/categories");
		categorias
		.then(res => res.json())
		.then(catMenu => this.catMenu = catMenu);
		//
	},
	methods: {
		contactPush: function (message) {
	      	this.$router.push({ name: 'home' })      		
	    }
	}
}
</script>

<style lang="scss">
.ci-navigation{
	display: flex;
	flex-flow: column;

	.ci-text a{
		font-weight: 200;
		color: #7f8189;
	}

	&__option{
		padding: 8px 16px;
		background: transparent;
		border: 0;
		text-align: left;
		outline: none;
		cursor: pointer;
		position: relative;
		&.is-active{
			color: white;
			&:after{
				width: 62px;
			}
		}
		&:after{
			content: "";
			position: absolute;
			width: 0px;
			bottom: 0;
			left: 16px;
			height: 2px;
			background: $t-accent;
			transition: .3s $easeInOutQuad;
		}
	}
	&__label{
		position: relative;
		display: inline-block;
	}
	
	&--lg{
		&:not(.is-inline){
			.ci-navigation{
				&__option{
					margin-bottom: 8px;
				}
			}
		}
	}

	&.is-inline{
		flex-flow: row;
		align-items: center;
		.ci-navigation{
			&__tooltip{
				top: initial;
				bottom: 100%;
				left: 50%;
				transform: translate(-50%, -4px);
				animation-name: nav-tooltip-inline;
				&:before{
					top: 100%;
					left: 50%;
					transform: translate(-50%, 0);
					border-left: 4px solid transparent;
					border-right: 4px solid transparent;
					border-top: 4px solid $t-accent;
				}
			}
			&__option{
				&:after{
					left: 50%;
					transform: translateX(-50%);
				}
			}
		}
	}

	@include grid-media($g-desktop-up){
		.ci-navigation{
			&__tooltip{
				opacity: .6;
				transition: opacity .2s $easeInOutQuad;
			}
			&__option{
				position: relative;
			}
			&__option:hover{
				&:after{
					width: 62px;
				}
				.ci-navigation{
					&__tooltip{
						opacity: 1;
					}
					&__submenu{
						top: 100%;
						opacity: 1;
						visibility: visible;
						z-index: 9999;
					}
				}
			}
			&__submenu{
				position: absolute;
				top: 70%;
				left: 0%;
				min-width: 200px;
				background: white;
				padding: 16px 0;
				border-radius: 8px;
				box-shadow: 0 4px 8px rgba(black, .06);
				transition: .3s $easeInOutQuad;
				opacity: 0;
				visibility: hidden;
			}
			&__child{
				padding: 8px 16px;
				line-height: 120%;
			}
		}
	}

	@keyframes nav-tooltip {
		0% {
			transform: translate(16px, -50%);
		}
		60% {
			transform: translate(20px, -50%);
		}
		100% {
			transform: translate(16px, -50%);
		}
	}

	@keyframes nav-tooltip-inline {
		0% {
			transform: translate(-50%, -4px);
		}
		60% {
			transform: translate(-50%, 0);
		}
		100% {
			transform: translate(-50%, -4px);
		}
	}

}
</style>
