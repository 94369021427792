<template id="spotlight">
	<div class="ci-spotlight"
	:class="[
			{
				'is-idle': !initialized && !animated
			}
		]">
		
		<div class="ci-spotlight__background">
			<img class="ci-spotlight__rear" :src="publicPath + 'images/shape-spotlight.png'" />
			<img class="ci-spotlight__front" :src="publicPath + 'images/shape-insti.png'" />
		</div>

		<div class="ci-spotlight__container">
			
			
			<div class="ci-spotlight__content ci-spotlight__animate">
				<ci-text class="ci-spotlight__title " v-html="title">
				</ci-text>

				<ci-text class="ci-spotlight__text" type="content" v-html="content"></ci-text>
				<a href="https://materiais.citrus.cx/agende-uma-demonstracao" target="_blank"><button class="ci-button ci-text ci-text--body is-dark ci-spotlight__button ci-button--accent ci-button--md" type="submit">
					{{$t('demonstracao.text')}}
				</button></a>
				<!-- <img class="ci-spotlight__screen ci-spotlight__animate" :src="publicPath + 'images/citrus-screen.png'" :alt="title"> -->
			</div>
			<div class="ci-spotlight__video ci-spotlight__animate">
				<ci-demo
					class="ci-about__player ci-about__animate--scale"
					theme="warn"
					:video="video"
					:thumbnail="thumbnail"
				>
				</ci-demo>
			</div>
		</div>
	</div>
</template>

<script>
import CIText from '@/components/atoms/CIText'
import CIDemo from '@/components/molecules/CIDemo'
import { TweenMax, Power2, Elastic } from 'gsap/TweenMax'

export default {
	components: {
		'ci-text': CIText,
		'ci-demo': CIDemo
	},
	props: {
		// screen: {
		// 	type: String,
		// 	default: 'images/citrus-screen.png'
		// },
		// title: {
		// 	type: String,
		// 	default: 'Aenean congue pellentesque tortor.'
		// },
		video: {
			type: String,
			default: undefined
		},
		thumbnail: {
			type: String,
			default: undefined
		},
		title: {
			type: String,
			default: 'Transforme a sua empresa oferecendo a melhor experiência aos seus clientes'
		},
		content: {
			type: String,
			default: 'Nossa metodologia exclusiva de mapeamento de jornadas identifica os principais limões do seu negócio e transforma todos em limonada. Você terá o diagnóstico do problema e em menos de 30 dias conseguirá visualizar um caminho a ser seguido usando a nossa plataforma. É a experiência do cliente como você nunca viu.'
		},
		theme: {
			type: String,
			default: 'accent'
		},
		initialized: {
			type: Boolean,
			default: true
		}
	},

	data: () => ({
		animated: true
	}),

	mounted() {
		if (this.initialized)
			this.init()
	},

	watch: {
		initialized(value) {
			if (value && !this.animated)
				this.init()
		}
	},

	methods: {
		init() {
			this.animated = true

			TweenMax.staggerFrom(this.$el.querySelectorAll('.ci-spotlight__animate'), 1.5, {
				x: "-=80",
				opacity: 0,
				ease: Power2.easeOut
			}, .4)
			

		},
	}
}
</script>

<style lang="scss">
.ci-spotlight{
	position: relative;
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	position: relative;
	transition: opacity .6s;
	z-index: 30;
	padding-bottom: 70px;
	@include grid-container;

	a{
		text-decoration: none!important;
	}
	&__container{
		width: 100%;
		@include outer-container;
	}
	&__background{
		position: absolute;
		top: -600px;
		left: 0;
		right: 0;
		bottom: 0;
		/*overflow: hidden;*/
		z-index: -2;
	}
	&__rear{
		position: absolute;
		top: 0;
		left: 39%;
		transform: translateX(-50%);
	}
	&__front{
	    position: absolute;
	    top: 0;
	    left: 100%;
	}
	&__content{
		@include grid-column(6);
	}
	&__title{
	    margin: 16px 0;
	    color: #fff;
	    /* font-family: 'montserrat'; */
	   	font-size: 33px;
	    line-height: 105%;
	    max-width: 440px;
	    font-weight: 600;
	}
	&__text{
		color: $c-darkgray;
	    line-height: 155%;
	    font-size: 16px;
	    max-width: 425px;
	    font-family: 'montserrat';
	}
	&__button{
	    padding: 0 25px;
	    width: auto;
	    margin: 32px 0;
	    font-size: 13.5px;
	    height: 40px;
	    line-height: 41px;
	    letter-spacing: 1px;
	    width: 100%;
	    max-width: 303px;
	    font-weight: 600;
	}
	// Themes
	&--primary{
		.ci-spotlight{
			&__shape{
				// background: $t-primary;
			}
		}
	}
	&--accent{
		.ci-spotlight{
			&__shape{
				// background: $t-accent;
			}
		}
	}
	&--warn{
		.ci-spotlight{
			&__shape{
				// background: $t-warn;
			}
		}
	}

	@include grid-media($g-tablet-up){
		.ci-spotlight{
		padding-bottom: 130px;
			
			&__container{
				margin-top: 95px;
			}
			&__title{
				margin: 0 0 24px;
				font-size: 30px;
			}
			&__screen{
				position: absolute;
				right: 48.9%;
				top: 20%;
				max-width: 800px;
				width: 55%;
				margin-bottom: 0;
			}
			&__shape{
				width: auto;
				right: 52%;
				top: -400px;
			}
			&__text{
				font-size: 18px;
			}
			&__content{
				@include grid-column(5);
				@include grid-push(1);
			}
			&__video{
				@include grid-column(6);
			}
		}
	}
	@include grid-media($g-tablet-up) {
		.ci-spotlight{
			&__title{
			}
			&__rear,
			&__front{
				animation-name: waves;
				animation-iteration-count: infinite;
			}
			&__rear{
				animation-duration: 10s;
				top: -22px;
			}
			&__front{
				animation-duration: 12s;
			}
			&__brand{
				animation-name: brand;
				animation-iteration-count: infinite;
				animation-duration: 8s;
			}
		}

		@keyframes waves {
			0% {
				transform: translate(-50%, 0px);
			}
			50% {
				transform: translate(calc(-50% - 20px), 20px);
			}
			100% {
				transform: translate(-50%, 0px);
			}
		}

		@keyframes brand {
			0% {
				transform: translateY(0px);
			}
			50% {
				transform: translateY(-10px);
			}
			100% {
				transform: translateY(0px);
			}
		}
	}

	@include grid-media($g-desktop-up) {
		.ci-spotlight{
			&__shape{
				width: auto;
				right: 51%;
				top: -340px;
			}
			&__title{
				font-size: 42px;
			}
		}
	}

	// Idle
	&.is-idle{
		opacity: 0;
	}
}
@media(max-width: 1025px) and (min-width: 769px){
	.ci-spotlight__title {
	    font-size: 34px!important;
	}
}
</style>
