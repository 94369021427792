<template>
	<div class="ci-info">
    <div class="ci-info__container">
      <div class="ci-info__body">
        <ci-text class="ci-info__title" type="title">
          {{ title }}
        </ci-text>

        <ci-text class="ci-info__content"
          v-html="content"
        />

        <ci-button class="ci-info__action" @click="$emit('callback')">
          {{ action }}
        </ci-button>
      </div>
    </div>
	</div>
</template>

<script>
  import CIButton from '@/components/atoms/CIButton'
  import CIText from '@/components/atoms/CIText'

  export default {
    components: {
      'ci-text': CIText,
      'ci-button': CIButton,
    },

    props: {
      title: {
        type: String,
        default: 'Obrigado!'
      },
      content: {
        type: String,
        default: 'Agradecemos o seu contato!'
      },
      action: {
        type: String,
        default: 'Voltar para o site'
      }
    },
  }
</script>

<style lang="scss">
.ci-info{
  @include grid-container;
  &__container{
    @include outer-container;
  }
  &__body{
    display: flex;
    flex-flow: column;
    align-items: center;
    margin: 80px 0;
    @include grid-column(6);
  }
  &__title{
    margin-bottom: 24px;
  }
  &__content{

  }
  &__action{
    margin-top: 24px;
  }

  @include grid-media($g-tablet-up) {
    .ci-info{
      &__body{
        margin: 160px 0;
        @include grid-column(4);
        @include grid-push(4);
      }
    }
  }
}
</style>