<template>
	<div class="ci-application"
		:class="{
			'is-inline': inline
		}"
	>

		<ci-text class="ci-application__tit" v-html="descriData">
		</ci-text>

		
		<div class="ci-application__brands">
		
			<img class="ci-application__brand"
				v-for="(brand, index) in brands"
				:key="index"
				:src="brandApplication(brand)"
			/>
		</div>

		<ci-text class="ci-application__content" type="small" v-html="contentData.replace(/\r?\n/g, '<br />')">
		</ci-text>	

	</div>


</template>

<script>
import CIText from '@/components/atoms/CIText'

export default {
	components: {
		'ci-text': CIText
	},

	props: {
		brands: {
			type: Array,
			default: () => ([
				process.env.BASE_URL + 'images/typo-lemon-desk.png',
				process.env.BASE_URL + 'images/typo-lemon-desk.png',
				process.env.BASE_URL + 'images/typo-lemon-desk.png'
			])
		},
		descri: {
			type: String,
			default: 'Inteligência Artificial, Machine Learning e CX Analytics'
		},
		content: {
			type: String,
			default: 'Curabitur porttitor eros vel mauris vulputate fringilla. Maecenas posuere libero ac purus mattis, non vulputate leo dictum. Phasellus id quam molestie, mattis magna vel, porttitor eros.'
		},
		description: {
			type: String,
			default: 'Some coming from CMS'
		},
		products: {
			type: Array,
			default: () => [
				{}, {}
			]
		},
		keyi18n: {
			type: String,
			default: ""
		},
		inline: {
			type: Boolean,
			default: false
		}
	},

	computed: {
		descriData(){
			return this.$t(`apps.${this.keyi18n}.descri`)
		},
		contentData(){
			return this.$t(`apps.${this.keyi18n}.content`)
		}
	},

	methods: {
		brandApplication(brand){
			const lang = localStorage.getItem('citrux.lang');
			if(brand === "https://citrus.cx/wp-content/uploads/2019/08/apis.png" && lang ==="en"){
				return "https://citrus.cx/wp-content/uploads/2019/08/apis-en-x2.png";
			}
			return brand;
		}
	}
}
</script>

<style lang="scss">
.ci-application{
	margin-left: 0px;
	display: flex;
	flex-flow: column;

	&__brands{
		display: flex;
		flex-flow: column;
		align-items: flex-start;
	}
	&__brand{
		margin-bottom: 15px;
		max-width: 100%;
	}
	&__tit{
		display: none;
	}
	&__content{
		   align-self: center;
	}
	&.inline{
	}
	&.is-inline{
		flex-flow: row wrap;
		padding-top: 55px!important;

		.ci-application{
			&__brand{
				max-width: 200px;
			}
			&__tit{
				display: block;
				-webkit-box-flex: 1;
				-ms-flex-positive: 1;
				flex-grow: 0;
				-ms-flex-preferred-size: 0;
				flex-basis: 1;
				width: 100%;
				color: $c-darkgray;
				font-size: 30px;
				font-weight: bold;
				font-size: 28px;
				font-weight: bold;
				margin-bottom: 40px;
			}
		}
	}

	@include grid-media($g-tablet-up) {
		&.is-inline{
		    flex-flow: row wrap;
			.ci-application{
				&__brand{
					/*margin-bottom: 64px;*/
					/*margin-bottom: 48px;*/
					margin: 15px 0;

					&:last-child{
						margin-bottom: 0;
					}
				}
				&__brands{
					margin-right: 35px;
				}
				&__content{
					flex: 1;
				}
			}
		}
	}
}
</style>
