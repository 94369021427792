<template>
	<ci-blog 
	:anuncios="anuncios"/>
</template>

<script>
import CIBlog from '@/components/templates/CIBlog'
import { mapGetters } from 'vuex'

export default {
	components: {
		'ci-blog': CIBlog
	},

	computed: {
		...mapGetters({
			anuncios: 'page/getAnuncios'
		})
	},

	
}
</script>
