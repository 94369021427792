import Vue from 'vue'

export default {
	namespaced: true,

	state: {
		pages: {
			'spotlight': {},
			'bookinfo': {},
			'benefits': {},
			'experience': {},
			'mapping': {},
			'home': {},
			'about': {},
			'solutions': {},
			'suite': {},
			'apps': {},
			'squad': {},
			'contact': {},
			'anuncios': {}
		}
	},

	getters: {
		getHome(state) {
			return state.pages['home']
		},
		getSpotlight(state) {
			return state.pages['spotlight']
		},
		getBookinfo(state) {
			return state.pages['bookinfo']
		},
		getBenefits(state) {
			return state.pages['benefits']
		},
		getExperience(state) {
			return state.pages['experience']
		},
		getMapping(state) {
			return state.pages['mapping']
		},
		getAbout(state) {
			return state.pages['about']
		},
		getSolutions(state) {
			return state.pages['solutions']
		},
		getSuite(state) {
			return state.pages['suite']
		},
		getApps(state) {
			return state.pages['apps']
		},
		getSquad(state) {
			return state.pages['squad']
		},
		getContact(state) {
			return state.pages['contact']
		},
		getSeed(state) {
			return state.pages['seed']
		},
		getLemonade(state) {
			return state.pages['lemonade']
		},
		getAnuncios(state) {
			return state.pages['anuncios']
		}
	},

	mutations: {
		set(state, payload) {
			// Organize generic data
			let page = {
				...payload,
				...payload.acf,
				title: payload.title.rendered,
				content: payload.content.rendered,
				excerpt: payload.excerpt.rendered
			}

			// Organize pages data
			switch (page.slug) {

				case 'spotlight':
					page.thumbnail = page.svideo_thumbnail ? page.svideo_thumbnail.url : undefined
					break

				case 'bookinfo':
					page.thumbnail = page.ebook_thumbnail ? page.ebook_thumbnail.url : undefined
					break

				case 'anuncios':
					page.banner_image = page.banner_image ? page.banner_image.url : undefined
					break

				case 'about':
					page.thumbnail = page.video_thumbnail ? page.video_thumbnail.url : undefined
					break 

				case 'solutions':
					if (page.items)
						page.items = page.items.map(
							item => ({
								...item,
								icon: item.icon ? item.icon.url : undefined
							})
						)
					break

				case 'benefits':
					if (page.items)
						page.items = page.items.map(
							item => ({
								...item,
								icon: item.icon ? item.icon.url : undefined
							})
						)
					break

				case 'experience':
					page.thumbnail = page.avatar_thumbnail ? page.avatar_thumbnail.url : undefined
					break

				case 'apps':
					if (page.items)
						page.items = page.items.map(
							item => ({
								...item,
								brands: [
									item.first_brand.url,
									item.second_brand.url,
									item.third_brand.url,
								]
							})
						)
					break
					
				case 'squad':
					let features = {}

					if (page.features)
						page.features.forEach(feature => {
							features[feature.category] = feature.items.map(
							item => ({
								...item,
								image: item.image ? item.image.url : undefined
							})
							)
						})

						page.features = features
					break

				case 'seed':
					page.transform_video_thumbnail = page.transform_video_thumbnail ? page.transform_video_thumbnail.url : undefined

					if (page.sdc_items)
            page.sdc_items.forEach(item => {
							item.brand = item.brand ? item.brand.url : undefined
            })

					if (page.swc_items)
            page.swc_items.forEach(item => {
							item.brand = item.brand ? item.brand.url : undefined
            })

					break

				case 'lemonade':
					page.day_background = page.day_background ? page.day_background.url : undefined
					break
			}

			Vue.set(state.pages, page.slug, page)
		}
	},

	actions: {
		fetch({ state, commit }) {
			return new Promise((resolve, reject) => {
				Vue.http.get('pages')
					.then(response => {
						// Set pages
						response.body.forEach(page =>
							commit('set', page)
						)
						// Return pages
						return resolve(state.pages)
					})
			})
		}
	}
}
