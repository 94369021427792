<template>
	<div class="ci-apps"
		:class="[
			{
				'is-idle': !initialized && !animated
			}
		]"
	>
		<img class="ci-apps__shape" :src="`${publicPath}images/shape-apps.svg`">

		<div class="ci-apps__container">
			<div class="ci-apps__content ci-apps__animate">
				<ci-text class="ci-apps__title" type="title">
					{{ title }}
				</ci-text>
				<ci-text class="ci-apps__text" type="body" :v-html="content"> {{$t('apps.content')}}
				</ci-text>
			</div>

			<div class="ci-apps__list">

				<ci-application
					class="ci-apps__application ci-apps__animate"
					v-for="(app, index) in apps"
					:key="index"
					:descri="app.descri"
					:brands="app.brands"
					:content="app.content"
					:inline="index > 2"
					:keyi18n="app.first_brand.name"
				/>
			
				<a href="https://materiais.citrus.cx/agende-uma-demonstracao" target="_blank">
					<button class="ci-button ci-text ci-text--body is-dark ci-spotlight__button ci-button--accent ci-button--md ci-apps__animate centerAligned" type="submit">
						{{$t('demonstracao.text')}}
					</button>
				</a>
			</div>			
		</div>
	</div>
</template>

<script>
import CIText from '@/components/atoms/CIText'
import CIApplication from '@/components/molecules/CIApplication'
import { TweenMax, Power2 } from 'gsap/TweenMax'

export default {
	components: {
		'ci-text': CIText,
		'ci-application': CIApplication
	},

	props: {
		title: {
			type: String,
			default: 'Citrus Apps'
		},
		content: {
			type: String,
			default: 'Curabitur libero augue, convallis ac elit non, faucibus mollis nisi. Maecenas venenatis aliquam ipsum, sed interdum tellus fringilla eu. Cras gravida tempor euismod. Integer et condimentum ipsum, vel maximus urna.'
		},
		apps: {
			type: Array,
			default: () => ([
				{}, {}, {}, {}
			])
		},
		initialized: {
			type: Boolean,
			default: true
		}
	},

	computed: {
		groups() {
			return this.chunk(this.apps, 2)
		}
	},
	
	data: () => ({
		animated: false,
	}),

	mounted() {
		if (this.initialized)
			this.init()
	},

	watch: {
		initialized(value) {
			if (value && !this.animated)
				this.init()
		}
	},

	methods: {
		init() {
			this.animated = true

			TweenMax.staggerFrom(this.$el.querySelectorAll('.ci-apps__animate'), 1.5, {
				x: "-=80",
				opacity: 0,
				ease: Power2.easeOut
			}, .2)
		},

		chunk(array, size) {
			const chunked_arr = []

			// ES6 destructuring
			let copied = [...array] 

			// Round up to the nearest integer
			const numOfChild = Math.ceil(copied.length / size) 

			for (let i = 0; i < numOfChild; i++)
				chunked_arr.push(copied.splice(0, size))

			return chunked_arr
		}
	}
}
</script>

<style lang="scss">
.ci-apps{
	position: relative;
	z-index: 15;
	@include grid-container;
	&__container{
		transition: opacity .6s;
		@include outer-container;
		margin-bottom: 15px;
	}
	&__content{
		margin-bottom: 24px;
		padding: 0 16px;
	}
	&__title{
		margin-bottom: 8px;
		color: $c-darkgray;
		width: 100%;
	}
	&__application{
		background: white;
		border-radius: 8px;
		box-shadow: 0 8px 16px rgba(black, .06);
		padding: 24px;
		box-sizing: border-box;
		margin-bottom: 24px;
		@include grid-column(6);
	}
	&__shape{
		position: absolute;
		top: -300px;
		left: 50%;
		transform: translateX(-55%);
		z-index: -1;
	}

	@include grid-media($g-tablet-up) {
		.ci-apps{
			&__list{
				position: relative;
				float: left;
				margin-top: 35px;
			}
			&__application{
				
			}
			&__shape{
				bottom: initial;
				top: -25%;
			}
			&__content{
				@include grid-column(12);
				display: -ms-flexbox;
				display: -webkit-flex;
				display: flex;
				-webkit-flex-direction: row;
				-ms-flex-direction: row;
				flex-direction: row;
				-webkit-flex-wrap: nowrap;
				-ms-flex-wrap: nowrap;
				flex-wrap: nowrap;
				-webkit-justify-content: flex-start;
				-ms-flex-pack: start;
				justify-content: flex-start;
				-webkit-align-content: center;
				-ms-flex-line-pack: center;
				align-content: center;
				-webkit-align-items: center;
				-ms-flex-align: center;
				align-items: center;
			}
		}
	}

	@include grid-media($g-desktop-up){
		.ci-apps{
			&__shape{
				top: -77%;
			}
			&__application{
				top: 0;
				transition: top .5s $easeInOutQuad;
				position: relative;
				padding: 62px 48px;
				@include grid-column(4);				
				min-height: 360px;

				&:first-child{
					position: absolute;
					width: 35%;					
					padding: 59px 75px 34px 50px;
				}
				&:nth-child(3){
					position: absolute;
					right: 16px;
					width: 35%;
					padding: 80px 66px 40px 78px;
				}
				&:nth-child(2){
					transform: translateY(20px)!important;
					margin: 0 auto;
					float: none;
					z-index: 1;
				}
				&.is-inline{
					@include grid-column(12);
					margin-top: 40px;
					height: auto!important;	
				}
				@include grid-column(4);
					&.is-inline{
						@include grid-column(12);
					}
					&:hover{
						top: -8px;
					}
			}
			&__content{
				@include grid-column(11);
				margin: 0 auto;
				float: none;
			}
			&__title{
				-webkit-order: 0;
				-ms-flex-order: 0;
				order: 0;
				-webkit-flex: 0 1 auto;
				-ms-flex: 0 1 auto;
				flex: 0 1 auto;
				-webkit-align-self: auto;
				-ms-flex-item-align: auto;
				align-self: auto;
				width: 31%;
				font-size: 50px;
			}
			&__text{
				-webkit-order: 0;
				-ms-flex-order: 0;
				order: 0;
				-webkit-flex: 0 1 auto;
				-ms-flex: 0 1 auto;
				flex: 0 1 auto;
				-webkit-align-self: auto;
				-ms-flex-item-align: auto;
				align-self: auto;
				width: 70%;
				padding-left: 30px;
				/*border-radius: 4px;*/
				border-left: solid 5px #c8df00;
				
				p{
					margin: 0px;
				}
			}
		}
	}

	&.is-idle{
		.ci-apps{
			&__container{
				opacity: 0;
			}
		}
	}
}
.centerAligned{
	margin: 0 auto!important;
	display: block;
}
</style>
