import Vue from 'vue'
import VueResource from 'vue-resource'
import VueGtm from 'vue-gtm'
import VueI18n from 'vue-i18n'
import App from './App.vue'
import router from './router'
import store from './store'
import messages from '@/lang'
import './stylesheets/defaults.scss'
import $ from 'jquery'
import VueFacebook from 'vue-facebook';
import VueMeta from 'vue-meta'

Vue.use(VueMeta);
var Promise = require('es6-promise').Promise;
require('es6-promise').polyfill();

Vue.use(VueFacebook)
Vue.use(VueI18n)

Vue.mixin({
	data: () => ({
		publicPath: process.env.BASE_URL
	}),
})

Vue.use(VueGtm, {
	id: 'GTM-56SMKNT',
	enabled: true,
	debug: true,
	vueRouter: router
})

Vue.use(VueResource)
Vue.config.productionTip = false
Vue.http.options.root = /* process.env.API_URL +  */ 'https://citrus.cx/wp-json/wp/v2'

//https://citrus.cx/

const i18n = new VueI18n({
	locale: store.getters.getLang,
	messages
})


new Vue({
	i18n,
	router,
	store,
	render: h => h(App)
}).$mount('#app')
