<template>
	<ci-landing
		:hero="home"
		:about="about"
		:solutions="solutions"
		:suite="suite"
		:apps="apps"
		:squad="squad"
		:try-out="contact"
		:scroll="scroll"
		:selected="selected"
		v-bind="$attrs"
		@contact="onContact"
	/>
</template>

<script>
import CILanding from '@/components/templates/CILanding'
import { mapGetters } from 'vuex'

export default {
	components: {
		'ci-landing': CILanding
	},

	props: {
		scroll: {
			type: Number,
			default: undefined
		},
		selected: {
			type: String|Number,
			default: '#home'
		},
	},

	computed: {
		...mapGetters({
			home: 'page/getHome',
			about: 'page/getAbout',
			solutions: 'page/getSolutions',
			suite: 'page/getSuite',
			apps: 'page/getApps',
			squad: 'page/getSquad',
			contact: 'page/getContact',
		})
	},

	methods: {
	  onContact(component) {
			component.state = 'sending'

			this.$store.dispatch('contact/send', component.form)
				.then(message => {
          this.$store.dispatch('alert/add', {
            message: message,
						type: 'success'
          })

					// Redirect to success page
					this.$router.push({ name: 'contato-sucesso' })
				})
				.catch(message => {
			this.$store.dispatch('alert/add', {
			message: message,
						type: 'error'
			})

			// Reset component state
					component.state = 'idle'
				})
		},

		contactCallback(form) {
			return this.$store.dispatch('contact/send', form)
		}
	}
}
</script>
