import Vue from 'vue'
import Vuex from 'vuex'
import menu from './storeModules/menu'
import page from './storeModules/page'
import contact from './storeModules/contact'
import alert from './storeModules/alert'

Vue.use(Vuex)

// Create Modules object
const modules = {
  menu,
	page,
  contact,
  alert
}

// Dynamically import and namespace Vuex modules
const moduleNames = []

moduleNames.forEach(name => {
  let module = require(`./storeModules/${name}`)

  modules[name] = {
    namespaced: true,
    ...module.default
  }
})

const store = new Vuex.Store({
	modules,

	getters: {
		getLang() {
			switch (window.location.origin) {
				case process.env.EN_URL:
					return 'en'
					break
				case process.env.PT_URL:
				default:
					return 'pt'
					break
			}
		}
	}
})

export default store
