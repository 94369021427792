<template>
	<div class="ci-seed-transform"
		:class="[
			{
				'is-idle': !initialized && !animated
			}
		]"
	>
		<img
			class="ci-seed-transform__shape"
			:src="publicPath + 'images/shape-transform.svg'"
		>

		<div class="ci-seed-transform__container">
			<ci-player
				class="ci-seed-transform__player ci-seed-transform__animate"
				theme="accent"
				aspec="wider"
				:video="video"
				:thumbnail="videoThumbnail"
			/>

			<div class="ci-seed-transform__wrapper">
				<ci-text class="ci-seed-transform__title ci-seed-transform__animate" type="title" :dark="true">
					<span :v-for="phrase in $t('seed.transform.title')">
						{{ phrase }}
					</span>
				</ci-text>

				<ci-text
					class="ci-seed-transform__text ci-seed-transform__animate"
					type="lead"
					v-html="$t('transformContent')"
					:dark="true"
				/>
			</div>
		</div>

		<ci-circle
			class="ci-seed-transform__circle ci-seed-transform__circle--accent"
			theme="accent"
		/>
	</div>
</template>

<script>
import CILeaf from '@/components/atoms/CILeaf'
import CICircle from '@/components/atoms/CICircle'
import CIPlayer from '@/components/molecules/CIPlayer'
import CIText from '@/components/atoms/CIText'
import { TweenMax, Power2, Elastic } from 'gsap/TweenMax'

export default {
	components: {
		'ci-leaf': CILeaf,
		'ci-circle': CICircle,
		'ci-player': CIPlayer,
		'ci-text': CIText
	},

	props: {
		content: {
			type: String,
			default: `
				<p>
					Adipisicing voluptatem hic tenetur aperiam qui. Ducimus quia unde iste nobis quam quaerat explicabo possimus Nihil aliquid accusamus architecto aliquid eligendi Beatae alias repudiandae quidem nulla nostrum Numquam similique quis!
				</p>
				<p>
					Sit earum harum laudantium magni esse Reiciendis culpa ipsa dicta expedita perspiciatis Vero temporibus aliquam reprehenderit assumenda nulla Vitae quisquam sit excepturi velit commodi Obcaecati natus porro ad praesentium aliquid?
				</p>
				<p>
					Lorem placeat praesentium repellendus sapiente neque eum. Iste hic natus reiciendis ad distinctio Ea ipsam nam ullam iste sed? Voluptatum esse in ullam vitae vitae perspiciatis. Unde ducimus dolor quod
				</p>
			`
		},
		video: String,
		videoThumbnail: String,
		initialized: {
			type: Boolean,
			default: true
		}
	},

	data: () => ({
		animated: false
	}),

	mounted() {
		if (this.initialized)
			this.init()
	},

	watch: {
		initialized(value) {
			if (value && !this.animated)
				this.init()
		}
	},

	methods: {
		init() {
			this.animated = true

			TweenMax.staggerFrom(this.$el.querySelectorAll('.ci-seed-transform__animate'), 1.5, {
				x: "-=80",
				opacity: 0,
				ease: Power2.easeOut
			}, .4)
		},
	}
}
</script>

<style lang="scss">
.ci-seed-transform{
	position: relative;
	padding: 60px 0 60px;
	overflow: hidden;
	position: relative;
	z-index: 10;
	background: #a4d356;
	@include grid-container;
	&:before{
		content: "";
		background: #a4d356;
		position: absolute;
		top: 50%;
		left: 0;
		bottom: 0;
		width: 100%;
		z-index: -1;
	}
	&__player{
		margin-bottom: 24px;
		@include grid-column(6);
	}
	&__container{
		transition: opacity .6s;
		@include outer-container;
	}
	&__title{
		text-align: center;
		display: flex;
		flex-flow: column;
		align-items: center;
		line-height: 120%;
		margin-bottom: 24px;
		@include grid-column(6);
		> span{
			&:nth-child(1){
				font-weight: 300;
			}
			&:nth-child(2){
				text-transform: lowercase;
				transform: scale(1.4);
			}
			&:nth-child(3){
				text-transform: lowercase;
				position: relative;
				transform: scale(1.6) translateY(20%);
				&:before{
					content: "";
					position: absolute;
					top: 0;
					right: -8px;
					bottom: 8px;
					left: -8px;
					background: $t-accent;
					z-index: -1;
				}
			}
		}
	}
	&__text{
		@include grid-column(6);
	}
	&__shape{
		display: none;
	}
	&__leaf{
		display: none;
	}
	&__circle{
		display: none;
	}

	@include grid-media($g-desktop-up){
		background: transparent;
		padding: 240px 0 160px 0;
		.ci-seed-transform{
			&__leaf{
				position: absolute;
				display: block;
				clip-path: url(#mask);
				top: -300px;
				right: -400px;
			}
			&__circle{
				position: absolute;
				display: block;
				z-index: -1;
				left: 0%;
				bottom: -50%;
				transform: translateX(-50%);
			}
			&__shape{
				display: block;
				position: absolute;
				min-width: 100%;
				top: 0;
				left: 50%;
				transform: translateX(-50%);
				z-index: -1;
			}
			&__player{
				@include grid-column(10);
				@include grid-push(1);
			}
			&__title{
				@include grid-column(4);
			}
			&__text{
				@include grid-column(8);
			}
			&__wrapper{
				display: flex;
				align-items: center;
				width: 100%;
			}
		}
	}

	// Idle
	&.is-idle{
		.ci-seed-transform{
			&__container{
				opacity: 0;
			}
		}
	}
}
</style>
