<template>
    <div>
        <div class="banner" v-for="banner in anuncios.items">
            <a :href="banner.link" target="_blank">
                <img :src="banner.banner_image.url">
            </a>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'

export default {
   
    props: {
        title: {
            type: String,
            default: ""
        },
        content: {
            type: String,
            default: ""
        },
        anuncios: {
        }
    },
    mounted() {
        setTimeout(function () {
            var random = Math.floor(Math.random() * $('.banner').length);
            $('.banner').eq(random).show();
        })
           
    }
}
</script>
<style lang="scss">
    .banner{
        width: 100%;
        box-shadow: 0px 0px 6px 0px #898b9245;
        display: none;
        
        img{
            width: 100%;
        }
    }
</style>